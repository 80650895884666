<fieldset class="section-legend-border col-md-12 col-lg-12" #OpeningBal >
  <legend class="section-legend-border">Opening Balance</legend>
<div class="row">
    <div class="col-sm-12">
      <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
      
       
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <ng-template #rt1 let-r="result" let-t="term">
              {{ r.AccountCode}} | {{ r.Year}}
            </ng-template>
            <label for="typeahead-basic">Search for a Opening Balance:</label>
            <input id="typeahead-basic" type="text" class="form-control" name="openingBalanceModel.OpeningBalanceAutoComplete" 
            [(ngModel)]="openingBalanceModel.OpeningBalanceAutoComplete" [ngbTypeahead]="searchOpBal" [inputFormatter]="formatterOpBal"
            [resultTemplate]="rt1" (selectItem)="selectedItemOpBal($event)"
            />
          </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
              <ng-template #rt let-r="result" let-t="term">
                {{ r.AccountName}}
              </ng-template>
              <label for="typeahead-basic">Search for a Account:</label>
              <input id="typeahead-basic" type="text" class="form-control" name="accountModel.AccountAutoComplete" 
              [(ngModel)]="accountModel.AccountAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter"
              [resultTemplate]="rt" (selectItem)="selectedItem($event)"
              />
            </div>
          </div>
        <div class="form-row"> 
          <div class="form-group col-sm-12 col-md-2 col-lg-2">
            <label for="disabledTextInput">Opening Balance Id</label>
            <input type="text" disabled [(ngModel)]="openingBalanceModel.OpeningBalanceID" name="openingBalanceModel.OpeningBalanceID" placeholder="id" class="form-control">
          </div>
          <div class="form-group col-sm-12 col-md-6 col-lg-6">
            <label for="disabledTextInput">Account Code</label>
            <input type="text" [(ngModel)]="openingBalanceModel.AccountCode" name="openingBalanceModel.AccountCode" placeholder="Opening Balance Account Code " class="form-control">
          </div>
          <div class="form-group col-sm-12 col-md-6 col-lg-6">
            <label for="disabledTextInput">AccountID</label>
            <input type="text" disabled [(ngModel)]="openingBalanceModel.AccountID" name="openingBalanceModel.AccountID" placeholder="Opening Balance Account ID " class="form-control">
          </div>
      
        </div>
        <div class="form-row"> 
            <div class="form-group col-sm-12 col-md-2 col-lg-2">
              <label for="disabledTextInput">Amount </label>
              <input type="text"  [(ngModel)]="openingBalanceModel.Amount" name="openingBalanceModel.Amount" placeholder="Amount" class="form-control">
            </div>
            <div class="form-group col-sm-12 col-md-2 col-lg-2">
                <label for="disabledTextInput">Year</label>
                <input type="text"  [(ngModel)]="openingBalanceModel.Year" name="openingBalanceModel.Year" placeholder="Year" class="form-control">
              </div>
            <div class="form-group col-sm-12 col-md-2 col-lg-2">
                <label for="disabledTextInput">Comments</label>
                <input type="text"  [(ngModel)]="openingBalanceModel.Comments" name="openingBalanceModel.Comments" placeholder="Comments" class="form-control">
              </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
          <div class="form-group col-sm-12 col-md-4 col-lg-4">
            <button type="submit" class="btn btn-primary btn-block">Submit</button>
          </div>
          <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
        </div>
      </form> 
    </div>
    </div>  
</fieldset>
<fieldset class="section-legend-border col-md-12 col-lg-12" #OpeningBalList >
  <legend class="section-legend-border">Opening Balance List</legend>
    <div class="row" id="topic-example">
      <div class="col-sm-12 example-section">
        <div class="">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">OpeningBalanceID</th>
                <th scope="col">AccountCode</th>
                <th scope="col">Year</th>
                <th scope="col">Amount</th>
                <th scope="col">Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of openingBalanceModel.OpeningBalancelist">
                <th scope="row">{{item.OpeningBalanceID}}</th>
                <td>{{item.AccountCode}}</td>
                <td>{{item.Year}}</td>
                <td>{{item.Amount|customInr}}</td>
                <td>{{item.Comments}}</td>
            </tbody>
          </table>
        </div>
      </div>
      </div>
</fieldset>
    