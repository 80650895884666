import { FixedDeposit } from "./fixed-deposit";

export class ProductModel {
    public ProductID:number;
    public ProductName:string;
    public VendorID:number;
    public VendorName:string;
    public Amount:number;
    public ProductAutoID:number;
    public ProductAutoComplete:any;
    public VendorAutoComplete:any;
    public BrandAutoComplete:any;
    public BrandId:number;
    public BrandName:string;
    public IsFood:boolean; 
    public IsEntertainment:boolean;
    public ProductUniqueName:string;
    public SummaryNotes:string;

    public IsFD:boolean;
    public FixedDepositModel:FixedDeposit;

    public IsForItemTracker:boolean;
    public IsActive:boolean;

    public PurchaseDate:Date;
    public PurchaseDateDisplay:any;
    public RenewDate:Date;
    public RenewDateDisplay:any;
    public IsRenewed:boolean;
}
