import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AExpenseMasterComponent } from './aexpense-master/aexpense-master.component';
import { VendorComponent } from './vendor/vendor.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TransactionComponent } from './transaction/transaction.component';
import { ProductComponent } from './product/product.component';
import { AccountComponent } from './account/account.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared-module/shared.module';
import { OpeningBalanceComponent } from './opening-balance/opening-balance.component';
import { FileComponent } from './file/file.component';
import { ClipboardModule } from 'ngx-clipboard';
@NgModule({
  declarations: [AExpenseMasterComponent,VendorComponent,ProductComponent, AccountComponent, TransactionComponent,ProductDetailsComponent, OpeningBalanceComponent, FileComponent

  ],
  imports: [
    CommonModule,AgGridModule,NgbModule,RouterModule,FormsModule,BrowserAnimationsModule,SharedModule,ClipboardModule],
  exports: [AExpenseMasterComponent]
})
export class ExpenseManagerModule { }
