<div class="row">
  <div class="col-sm-12">
    <fieldset class="section-legend-border  col-md-6 col-lg-6" #SearchBy>
      <legend class="section-legend-border">Search By</legend>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="disabledTextInput">Year (As per FY)</label>
          <input type="text" [(ngModel)]="year" name="year" placeholder="Year (Fy)" class="form-control"
            (change)="onYearChange()">
        </div>
        <div class="form-group col-md-3">
          <label for="disabledTextInput">Month (As per FY)</label>
          <input type="text" [(ngModel)]="monthFilter" name="monthFilter" placeholder="month (Fy)" class="form-control"
            (change)="onYearChange()">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <ng-template #rt let-r="result" let-t="term">
            {{ r.ProductName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Product:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="productDetails.ProductIDAutoComplete"
            [(ngModel)]="productDetails.ProductIDAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter"
            [resultTemplate]="rt" (selectItem)="selectedItemProduct($event)" />
        </div>
      </div>
      <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Accounts>
        <legend class="section-legend-border">Accounts</legend>
        <div class="form-row">
          <div class="form-group  col-md-3" style="margin-left: 29px;">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isOpeningBalExist"
              [(ngModel)]="isOpeningBalExist">
            <label class="form-check-label" for="exampleCheck1">Is Opening Bal Exist </label>
            <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
          </div>
          <div class="form-group  col-md-6" style="margin-left: 29px;">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isMonthWise"
              [(ngModel)]="isMonthWise">
            <label class="form-check-label" for="exampleCheck1">Is Month Wise (Opening Balance will not be considered in
              this.)</label>
            <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <ng-template #rtAccCode let-r="result" let-t="term">
              {{ r.AccountCode}}
            </ng-template>
            <label for="typeahead-basic">Search for a Account:</label>
            <input id="typeahead-basic" type="text" class="form-control"
              name="accountBalanceModel.AccountBalanceAutoComplete"
              [(ngModel)]="accountBalanceModel.AccountBalanceAutoComplete" [ngbTypeahead]="searchAccCode"
              [inputFormatter]="formatterAccCode" [resultTemplate]="rtAccCode"
              (selectItem)="selectedItemAccCode($event)" />
          </div>
        </div>
      </fieldset>
      <div class="form-row">
        <div class="form-group col-sm-12">
          {{resultResponse }}
          <div *ngIf="accountResultResponse.length>0">
            {{accountResultResponse}} {{accountBalance |customInr}}
          </div>
        </div>
      </div>
    </fieldset>

    <!--Show hide filter section -->
    <fieldset class="section-legend-border  col-md-6 col-lg-6" #ShowSheets>
      <legend class="section-legend-border">Show sheets</legend>
      <div class="form-row">
        <div class="form-group  col-md-3" style="margin-left: 29px;">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isShowIncomeExpenditure"
            [(ngModel)]="isShowIncomeExpenditure" (change)="showHideFilter('')">
          <label class="form-check-label" for="exampleCheck1">Show Income Expenditure</label>
          <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
        </div>
        <div class="form-group  col-md-3" style="margin-left: 29px;">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isShowAssetsLiabilities"
            [(ngModel)]="isShowAssetsLiabilities" (change)="showHideFilter('AssetsLiabilities')">
          <label class="form-check-label" for="exampleCheck1">Show Assets Liabilities</label>
          <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
        </div>
        <div class="form-group  col-md-3" style="margin-left: 29px;">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isShowExpenditureIncomeAc"
            [(ngModel)]="isShowExpenditureIncomeAc" (change)="showHideFilter('ExpenditureIncomeAc')">
          <label class="form-check-label" for="exampleCheck1">Show Expenditure Income A/c's (Miss Match)</label>
          <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
        </div>
        <div class="form-group  col-md-3" style="margin-left: 29px;">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isShowIncomeExpenditureBalSheet"
            [(ngModel)]="isShowIncomeExpenditureBalSheet" (change)="showHideFilter('')">
          <label class="form-check-label" for="exampleCheck1">Show Income Expenditure BalSheet</label>
          <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
        </div>
      </div>
    </fieldset>
    <!--Show hide filter data section -->
    <fieldset class="section-legend-border  col-md-6 col-lg-6" #Filters>
      <legend class="section-legend-border">Filters</legend>
      <div class="form-row">
        <div class="form-group  col-md-3" style="margin-left: 29px;">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isRetainFilter"
            [(ngModel)]="isRetainFilter">
          <label class="form-check-label" for="exampleCheck1">Is RetainFilter</label>
          <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
        </div>
        <div class="form-group  col-md-3" style="margin-left: 29px;">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isMomOnly" [(ngModel)]="isMomOnly"
            (change)="filterAssetsLiabilities('mom')">
          <label class="form-check-label" for="exampleCheck1">Mom</label>
          <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
        </div>
        <div class="form-group  col-md-3" style="margin-left: 29px;">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isPrashantOnly"
            [(ngModel)]="isPrashantOnly" (change)="filterAssetsLiabilities('me')">
          <label class="form-check-label" for="exampleCheck1">Me</label>
          <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
        </div>
        <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #AssetsFilters *ngIf="isShowAssetsLiabilities">
          <legend class="section-legend-border">Assets Filters</legend>
          <div class="form-row">
            <div class="form-group  col-md-3" style="margin-left: 29px;" *ngIf="isShowAssetsLiabilities">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isFdOnly" [(ngModel)]="isFdOnly"
                (change)="filterAssetsLiabilities('fd')">
              <label class="form-check-label" for="exampleCheck1">FdOnly</label>
              <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
            </div>
            <div class="form-group  col-md-3" style="margin-left: 29px;" *ngIf="isShowAssetsLiabilities">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isRdOnly" [(ngModel)]="isRdOnly"
                (change)="filterAssetsLiabilities('rd')">
              <label class="form-check-label" for="exampleCheck1"> RdOnly</label>
              <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
            </div>
          </div>
        </fieldset>
      </div>
    </fieldset>

  </div>
  <div class="row">
    <div class="col-sm-12">
      <fieldset class="section-legend-border  col-md-12 col-lg-12" #ShowIncomeExpenditureDetailed *ngIf="isShowIncomeExpenditure">
        <legend class="section-legend-border">Income Expenditure Detailed</legend>
      <div class="row" id="IncomeExpenditure" *ngIf="isShowIncomeExpenditure">
        <div class="col-sm-12 example-section">
          <h4>Expenditure/Income </h4>
          <div class="IncomeExpenditure-content">
            <div class="main-{{accCode.AccountCode}} month-wise-section"
              *ngFor="let accCode of expenditureAccountFromDbModels"
              (click)="ele.class = ele.class == 'showChildren' ? '' :  'showChildren'"
              [ngClass]="{ hideChildren : ele.class !== 'showChildren' }" #ele>
              <div class='section-heading-div' id="{{accCode.AccountCode}}">
                <p class="section-heading-text">{{accCode.AccountCode}}(<span
                    class="balExpAmounttext">{{accCode.BalAmount|customInr}}</span>) - {{accCode.AccountName}} Month
                  wise statement List</p>
              </div>
              <table class="table summaryTable">
                <thead>
                  <tr>
                    <th scope="col">AccountCode</th>
                    <th scope="col">AccountName</th>
                    <th scope="col">BalAmount</th>
                    <th scope="col">IncomeExpenditure</th>
                    <th scope="col">VendorId</th>
                    <th scope="col">VendorName</th>
                    <th scope="col">Year</th>
                    <th scope="col">Month</th>
                    <th scope="col">MonthName</th>
    
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of exependitureModelList"
                    (click)="GetLedgerByAccountCode(item.Year,item.Month,item.AccountCode)" class="{{item.ClassStyled}}">
                    <ng-container *ngIf="item.AccountCode == accCode.AccountCode">
                      <td>{{item.AccountCode}}({{item.MonthName}})</td>
                      <th scope="row">{{item.AccountName}}</th>
                      <td>{{item.BalAmount|customInr}}</td>
                      <td>{{item.IncomeExpenditure}}</td>
                      <td>{{item.VendorId}}</td>
                      <td>{{item.VendorName}}</td>
                      <td>{{item.Year}}</td>
                      <td>{{item.Month}}</td>
                      <td>{{item.MonthName}}</td>
    
                    </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--Income starts here-->
        <div class="col-sm-12 example-section">
          <div class="IncomeExpenditure-content">
            <div class="main-IR1  month-wise-section" *ngFor="let accCode of incomeAccountFromDbModels"
              (click)="elein.class = elein.class == 'showChildren' ? '' :  'showChildren'"
              [ngClass]="{ hideChildren : elein.class !== 'showChildren' }" #elein>
              <div class='section-income-heading-div' id="{{accCode.AccountCode}}">
    
                <p class="section-heading-text">{{accCode.AccountCode}}(<span
                    class="balIncomeAmounttext">{{accCode.BalAmount|customInr}}</span>) - {{accCode.AccountName}} Month
                  wise statement List</p>
              </div>
              <table class="table summaryTable">
                <thead>
                  <tr>
                    <th scope="col">AccountCode</th>
                    <th scope="col">AccountName</th>
                    <th scope="col">BalAmount</th>
                    <th scope="col">IncomeExpenditure</th>
                    <th scope="col">VendorId</th>
                    <th scope="col">VendorName</th>
                    <th scope="col">Year</th>
                    <th scope="col">Month</th>
                    <th scope="col">MonthName</th>
    
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of incomeModelList"
                    (click)="GetLedgerByAccountCode(item.Year,item.Month,item.AccountCode)">
                    <ng-container *ngIf="item.AccountCode == accCode.AccountCode">
                      <td>{{item.AccountCode}}({{item.MonthName}})</td>
                      <th scope="row">{{item.AccountName}}</th>
                      <td>{{item.BalAmount|customInr}}</td>
                      <td>{{item.IncomeExpenditure}}</td>
                      <td>{{item.VendorId}}</td>
                      <td>{{item.VendorName}}</td>
                      <td>{{item.Year}}</td>
                      <td>{{item.Month}}</td>
                      <td>{{item.MonthName}}</td>
    
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
    
          </div>
        </div>
      </div>
      </fieldset>
      <fieldset class="section-legend-border  col-md-12 col-lg-12" #ShowIncomeExpenditureDetailed *ngIf="isShowAssetsLiabilities||isShowExpenditureIncomeAc">
        <legend class="section-legend-border" *ngIf="isShowAssetsLiabilities">Assets/Liabilities</legend>
        <legend class="section-legend-border" *ngIf="isShowExpenditureIncomeAc">Expenditure/Income</legend>
      <!-- Assets and liabilities-->
      <div id="assets-liabilities-balsheet-Section" class="row" *ngIf="isShowAssetsLiabilities||isShowExpenditureIncomeAc">
        <div class="col-sm-12">
          <!-- <h4 *ngIf="isShowAssetsLiabilities">Assets/Liabilities </h4>
          <h4 *ngIf="isShowExpenditureIncomeAc">Expenditure/Income </h4> -->
        </div>
        <div class="col-sm-12 col-md-6">
    
          <!-- Assets Balsheet-->
          <div class="row" id="Assets-balsheet">
            <div class="col-sm-12 example-section">
              <div class="">
                <p class="section-heading-text" *ngIf="isShowAssetsLiabilities">Assets</p>
                <p class="section-heading-text" *ngIf="isShowExpenditureIncomeAc">Expenditure</p>
                <table class="table ledgerTable">
                  <thead>
                    <tr>
    
                      <th scope="col"></th>
                      <th scope="col" *ngIf="isShowAssetsLiabilities">AccountName ({{year}} -
                        {{totalAssetsSummary|customInr}})</th>
                      <th scope="col" *ngIf="isShowExpenditureIncomeAc">AccountName ({{year}} -
                        {{totalExpenditureAcSummary|customInr}})</th>
                      <!-- <th scope="col">AccountCode</th> -->
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Assets Common-->
                    <tr *ngFor="let item of assetsLiabilitesModelList">
                      <ng-container *ngIf="item.AccountMasterName == 'Assets' && isShowAssetsLiabilities">
                        <td><input type="checkbox" [(ngModel)]="item.IsCheckedForTotal"
                            (ngModelChange)="checkedTotal('assetscommon')" name="item.IsCheckedForTotal{{i}}"></td>
                        <td scope="row" class="assets-liabilities-min-width">
                          {{item.AccountName}}<br />({{item.AccountCode}})
                        </td>
                        <td>
                          {{item.BalAmount|customInr}}
                        </td>
                      </ng-container>
                      <!-- Expenditure Common-->
                      <ng-container *ngIf="item.AccountMasterName == 'Expenditure' && isShowExpenditureIncomeAc">
                        <td><input type="checkbox" [(ngModel)]="item.IsCheckedForTotal"
                            (ngModelChange)="checkedTotal('expenditurecommon')" name="item.IsCheckedForTotal{{i}}"></td>
                        <td scope="row" class="assets-liabilities-min-width">
                          <span (click)="scroll(item.AccountCode)" class="custom-click">
                            {{item.AccountName}}<br />({{item.AccountCode}}) </span>
                        </td>
                        <td>
                          {{item.BalAmount|customInr}}
                        </td>
                      </ng-container>
                    </tr>
                    <!-- -->
                  </tbody>
                </table>
              </div>
    
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <!-- Liabilites Balsheet-->
          <div class="row" id="Liabilites-balsheet">
            <div class="col-sm-12 example-section">
              <div class="">
                <p class="section-heading-text" *ngIf="isShowAssetsLiabilities">Liabilites (Should be negative(-))</p>
                <p class="section-heading-text" *ngIf="isShowExpenditureIncomeAc">Income (-ve because of entry but showing
                  +ve For viewing)</p>
                <table class="table ledgerTable">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col" *ngIf="isShowAssetsLiabilities">AccountName ({{year}} -
                        {{totalLiabilitesSummary|customInr}})</th>
                      <th scope="col" *ngIf="isShowExpenditureIncomeAc">AccountName ({{year}} -
                        {{totalIncomeAcSummary|customInr}})</th>
                      <!-- <th scope="col">AccountCode</th> -->
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of assetsLiabilitesModelList">
                      <!-- Liabilities Common-->
                      <ng-container *ngIf="item.AccountMasterName == 'Liabilities' && isShowAssetsLiabilities">
                        <td><input type="checkbox" [(ngModel)]="item.IsCheckedForTotal"
                            (ngModelChange)="checkedTotal('liabilitiescommon')" name="item.IsCheckedForTotal{{i}}"></td>
                        <td scope="row" class="assets-liabilities-min-width">
                          {{item.AccountName}}<br />({{item.AccountCode}})
                        </td>
                        <td>
                          {{item.BalAmount|customInr}}
                        </td>
                      </ng-container>
                      <!-- Income Common-->
                      <ng-container *ngIf="item.AccountMasterName == 'Income' && isShowExpenditureIncomeAc">
                        <td><input type="checkbox" [(ngModel)]="item.IsCheckedForTotal"
                            (ngModelChange)="checkedTotal('incomecommon')" name="item.IsCheckedForTotal{{i}}"></td>
                        <td scope="row" class="assets-liabilities-min-width">
                          <span (click)="scroll(item.AccountCode)" class="custom-click">
                            {{item.AccountName}}<br />({{item.AccountCode}}) </span>
                        </td>
                        <td>
                          {{item.BalAmount|customInr}}
                        </td>
                      </ng-container>
                    </tr>
                    <!-- -->
                  </tbody>
                </table>
              </div>
    
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <!-- Assetes Total Balsheet-->
          <div class="row" id="assets-balsheet-total">
            <div class="col-sm-12 example-section">
              <div class="">
                <!-- <p class="section-heading-text">Assets </p> -->
                <table class="table ledgerTable">
                  <!-- <thead>
              <tr>
                <th scope="col">Total Assets</th>
                <th scope="col">Amount</th>
              </tr>
            </thead> -->
                  <tbody>
                    <tr>
                      <ng-container *ngIf="isShowAssetsLiabilities">
                        <td scope="row" class="assets-liabilities-min-width">
                          Assets Summary Total for Yr {{year}}
                        </td>
                        <td>
                          {{totalAssetsSummary|customInr}}
                          <!-- {{totalLiabilitesSummary|customInr}} -->
                        </td>
                      </ng-container>
                      <ng-container *ngIf="isShowExpenditureIncomeAc">
                        <td scope="row" class="assets-liabilities-min-width">
                          Expenditure Summary Total for Yr {{year}}
                        </td>
                        <td>
                          {{totalExpenditureAcSummary|customInr}}
                          <!-- {{totalLiabilitesSummary|customInr}} -->
                        </td>
                      </ng-container>
                    </tr>
                    <!-- -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <!-- Liabilites Total Balsheet-->
          <div class="row" id="liabilities-balsheet-total">
            <div class="col-sm-12 example-section">
              <div class="">
                <!-- <p class="section-heading-text">Liabilities </p> -->
                <table class="table ledgerTable">
                  <!-- <thead>
              <tr>
                <th scope="col">Total Assets</th>
                <th scope="col">Amount</th>
              </tr>
            </thead> -->
                  <tbody>
                    <tr>
                      <ng-container *ngIf="isShowAssetsLiabilities">
                        <td scope="row" class="assets-liabilities-min-width">
                          Liabilities Summary Total for Yr {{year}}
                        </td>
                        <td>
                          {{totalLiabilitesSummary|customInr}}
                          <!-- {{totalLiabilitesSummary|customInr}} -->
                        </td>
                      </ng-container>
                      <ng-container *ngIf="isShowExpenditureIncomeAc">
                        <td scope="row" class="assets-liabilities-min-width">
                          Income Summary Total for Yr {{year}}
                        </td>
                        <td>
                          {{totalIncomeAcSummary|customInr}}
                          <!-- {{totalLiabilitesSummary|customInr}} -->
                        </td>
                      </ng-container>
                    </tr>
                    <!-- -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </fieldset>
      <fieldset class="section-legend-border  col-md-12 col-lg-12" #ShowIncomeExpenditureDetailed *ngIf="isShowIncomeExpenditureBalSheet">
        <legend class="section-legend-border">Expenditure/Income BalanceSheet</legend>
      <div id="expenditure-income-balsheet-Section" class="row" *ngIf="isShowIncomeExpenditureBalSheet">
        <div class="col-sm-12">
          <!-- <h4>Expenditure/Income BalanceSheet</h4> -->
        </div>
        <!-- Expenditure sheet section -->
        <div class="col-sm-12 col-md-6">
          <div class="row" id="expenditure-balsheet">
            <div class="col-sm-12 example-section">
              <div class="">
                <p class="section-heading-text">Expenditure sheet</p>
                <table class="table ledgerTable">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">AccountName ({{year}} - {{totalExpenditureSummary|customInr}})</th>
                      <!-- <th scope="col">AccountCode</th> -->
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of expenditureAccountFromDbModels;let i=index; let last = last;">
                      <ng-container *ngIf="!last">
                        <td scope="row"><input type="checkbox" [(ngModel)]="item.IsCheckedForTotal"
                            (ngModelChange)="checkedTotal('expenditure')" name="item.IsCheckedForTotal{{i}}"></td>
                        <td scope="row" class="assets-liabilities-min-width">
                          <span (click)="scroll(item.AccountCode)" class="custom-click">
                            {{item.AccountName}}<br />({{item.AccountCode}}) </span>
                        </td>
                        <!-- <td>{{item.AccountCode}}</td> -->
                        <td>{{item.BalAmount|customInr}}</td>
                      </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- income sheet section -->
        <div class="col-sm-12 col-md-6">
          <div class="row" id="income-balsheet">
            <div class="col-sm-12 example-section">
              <div class="">
                <p class="section-heading-text">Income sheet</p>
                <table class="table ledgerTable">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">AccountName ({{year}} - {{totalIncomeSummary|customInr}})</th>
                      <!-- <th scope="col">AccountCode</th> -->
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of incomeAccountFromDbModels;let last = last;">
                      <ng-container *ngIf="!last">
                        <td scope="row"><input type="checkbox" [(ngModel)]="item.IsCheckedForTotal"
                            (ngModelChange)="checkedTotal('income')" name="item.IsCheckedForTotal{{i}}"></td>
                        <td scope="row" class="assets-liabilities-min-width"> <span (click)="scroll(item.AccountCode)"
                            class="custom-click"> {{item.AccountName}}
                            <br />({{item.AccountCode}}) </span>
                        </td>
                        <!-- <td>{{item.AccountCode}}</td> -->
                        <td>{{item.BalAmount|customInr}}</td>
                      </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Expenditure Total Balsheet-->
        <div class="col-sm-12 col-md-6">
          <div class="row" id="Expenditure-balsheet-total">
            <div class="col-sm-12 example-section">
              <div class="">
                <!-- <p class="section-heading-text">Assets </p> -->
                <table class="table ledgerTable">
                  <!-- <thead>
                    <tr>
                      <th scope="col">Total Assets</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead> -->
                  <tbody>
                    <tr>
                      <td scope="row" class="assets-liabilities-min-width">
                        Expenditure Summary Total for Yr {{year}}
                      </td>
                      <td>
                        {{totalExpenditureSummary|customInr}}
                        <!-- {{totalLiabilitesSummary|customInr}} -->
                      </td>
                    </tr>
                    <!-- -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- income Total Balsheet-->
        <div class="col-sm-12 col-md-6">
          <div class="row" id="income-balsheet-total">
            <div class="col-sm-12 example-section">
              <div class="">
                <!-- <p class="section-heading-text">Liabilities </p> -->
                <table class="table ledgerTable">
                  <!-- <thead>
                    <tr>
                      <th scope="col">Total Assets</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead> -->
                  <tbody>
                    <tr>
                      <td scope="row" class="assets-liabilities-min-width">
                        Income Summary Total for Yr {{year}}
                      </td>
                      <td>
                        {{totalIncomeSummary|customInr}}
                        <!-- {{totalLiabilitesSummary|customInr}} -->
                      </td>
                    </tr>
                    <!-- -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </fieldset>

    </div>
  </div>
 

  <!-- ON click below will show transactions-->
  <div class="row" id="ledger-account" *ngIf="accountBalanceModelList.length>0">
    <div class="col-sm-12 example-section">
      <div class="">
        <p class="section-heading-text">Account Month wise statement List</p>
        <table class="table ledgerTable">
          <thead>
            <tr>
              <th scope="col">VId</th>
              <th scope="col">AccountName</th>
              <th scope="col">AccountCode</th>
              <th scope="col">Year</th>
              <th scope="col">Month</th>
              <th scope="col">MonthName</th>
              <th scope="col">Day</th>
              <th scope="col">VendorName</th>
              <th scope="col">Credit</th>
              <th scope="col">Debit</th>
              <th scope="col">Products</th>
              <th scope="col">SummaryNotes</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of accountBalanceModelList">
              <th scope="row">{{item.VoucherID}}</th>
              <td>{{item.AccountName}}</td>
              <td>{{item.AccountCode}}</td>
              <td>{{item.Year}}</td>
              <td>{{item.Month}}</td>
              <td>{{item.MonthName}}</td>
              <td>{{item.Day}}</td>
              <td>{{item.VendorName}}</td>
              <td>{{item.Credit|customInr}}</td>
              <td>{{item.Debit|customInr}}</td>
              <td>{{item.Products}}</td>
              <td>{{item.SummaryNotes}}</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <span class="totalplaceholder" *ngIf="checkedSelectionTotal!=0">{{checkedSelectionTotal|customInr}}</span>