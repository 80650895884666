import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
//import { User } from '../models/user';
import { ExpenseApiModel } from '../models/ExpenseManager/expense-api-model';
import { VendorResponse } from '../models/ExpenseManager/Vendor/vendor-response';
import { VendorApiModel } from '../models/ExpenseManager/Vendor/vendor-api-model';
import { ProductApiModel } from '../models/ExpenseManager/Product/product-api-model';
import { AccountApiModel } from '../models/ExpenseManager/Accounts/account-api-model';
import { TransactionApiModel } from '../models/ExpenseManager/Transaction/transaction-api-model';
import { ConfigLoaderService } from './config-loader.service';
import { TransactionMasterModel } from '../models/ExpenseManager/Transaction/transaction-master-model';
import { TransactionModel } from '../models/ExpenseManager/Transaction/transaction-model';
import { IncomeExpenditureModel } from '../models/ExpenseManager/Accounts/income-expenditure-model';
import { LedgerAccountModel } from '../models/ExpenseManager/Accounts/ledger-account-model';
import { AccountMasterModel } from '../models/ExpenseManager/Accounts/account-master-model';
import { AccountModel } from '../models/ExpenseManager/Accounts/account-model';
import { AccountBalanceModel } from '../models/ExpenseManager/Accounts/account-balance-model';
import { OpeningBalanceModel } from '../models/ExpenseManager/Accounts/opening-balance-model';
import { FileModel } from '../models/ExpenseManager/File/file-model';

const WIKI_URL = 'https://en.wikipedia.org/w/api.php';
const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*'
  }
});

@Injectable({
  providedIn: 'root'
})
export class ExpenseManagerService {
  //private baseUrl ="http://localhost:2718";
  //private baseUrl ="http://localhost:8045";
  private baseUrl ="http://localhost:2718";
  private SaveExpenserUrl = 'http://localhost:8531/api/expense/saveexpense';

  private SaveOrUpdateTransactionUrl = '/api/expense/saveorudpatetransaction';
  private GetVendorAllOrByIDUrl = '/api/expense/getvendorallorbyid?vendorAutoID=';
  private GetVendorByNameUrl = '/api/expense/getvendorsbyname?vendername=';
  private SaveOrUpdateVendorUrl = '/api/expense/updatevendor';

  private GetProductByNameUrl = '/api/expense/getproductsbyname?productname=';
  private GetProductAllOrByIDUrl = '/api/expense/getproductallorbyid?productId=';
  private SaveOrUpdateProductUrl ='/api/expense/updateproduct';
  private GetFoodProductsByNameUrl = '/api/expense/getfoodproductsbyname?productname=';
  private GetAllFdsUrl = '/api/expense/getallfds';
  private SaveOrUpdateAccountUrl = '/api/expense/updateaccount';
  private GetAccountByNameUrl = '/api/expense/getaccountsbyname?accountName=';
  private GetAccountCurrentBalanceByYearUrl = '';
  private GetAllAccountCurrentBalanceByYearUrl ='';
  private GetAccountsMasterByNameUrl = '/api/expense/getaccountsmasterbyname?accountMasterName=';
  private GetRecentAccountsUrl = '/api/expense/getrecentaccounts';

  private GetVoucherIdsByNameUrl = '/api/expense/getvoucheridsbyname?serchText=';
  private GetVoucherDetailsByIdUrl = '/api/expense/getvoucherdetailsbyid?voucherID='; 
  private GetProductCurrentValueUrl = '/api/expense/getproductcurrentvalue?productId=';
  private GetRenewProductsUrl ='';
  private GetRecentTransactionsUrl = '/api/expense/getrecenttransactions?year={0}&noOfRecords={1}&creditOrDebit={2}';
  private GetIncomeExpenditureUrl = '/api/expense/getincomeexpenditure';
  private GetAllAccountCurrentBalByYearWithAccMasterUrl = '/api/expense/getallaccountcurrentbalbyyearwithaccmaster?year={0}';
  private GetLedgerByAccountCodeUrl = '/api/expense/getledgerbyaccountcode';
  private GetOpeningBalanceAllOrByIDUrl = '';
  private InsertOrUpdateOpeningBalanceUrl = '';
  private GetOpeningBalanceByAccountCodeUrl = '';

  private GetFileAllOrByIDUrl = '';
  private InsertOrUpdateFileUrl = '';
  private GetFilesByNameUrl = '';

  private TestJwtAuthUrl = '/api/expense/getmyauth?productname=sdf';
  private TestPostMyAuthUrl = '/api/expense/postmyauth';

  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService) { 
    this.baseUrl =this.configLoaderService.ApiBasePath;
    this.SaveExpenserUrl = 'http://localhost:8531/api/expense/saveexpense';
    this.SaveOrUpdateTransactionUrl = this.baseUrl+'/api/expense/saveorudpatetransaction';
    this.GetVendorAllOrByIDUrl = this.baseUrl+'/api/expense/getvendorallorbyid?vendorAutoID=';
    this.GetVendorByNameUrl = this.baseUrl+'/api/expense/getvendorsbyname?vendername=';
    this.SaveOrUpdateVendorUrl = this.baseUrl+'/api/expense/updatevendor';
    this.GetProductByNameUrl = this.baseUrl+'/api/expense/getproductsbyname?productname=';
    this.GetAllFdsUrl = this.baseUrl+'/api/expense/getallfds';
    this.GetFoodProductsByNameUrl = this.baseUrl+'/api/expense/getfoodproductsbyname?productname=';
    this.GetProductAllOrByIDUrl = this.baseUrl+'/api/expense/getproductallorbyid?productId=';
    this.SaveOrUpdateProductUrl =this.baseUrl+'/api/expense/updateproduct';
    this.GetRenewProductsUrl =this.baseUrl+'/api/expense/getrenewproducts?notifyBeforeDays=';


    this.SaveOrUpdateAccountUrl = this.baseUrl+'/api/expense/updateaccount';
    this.GetAccountCurrentBalanceByYearUrl = this.baseUrl+'/api/expense/getaccountcurrentbalancebyyear?year={0}&accCode={1}&isOpeningBalExist={2}&isMonthWise={3}';
    this.GetAllAccountCurrentBalanceByYearUrl = this.baseUrl+'/api/expense/getallaccountcurrentbalancebyyear?year={0}&isOpeningBalExist={1}';

    this.GetAccountByNameUrl = this.baseUrl+'/api/expense/getaccountsbyname?accountName=';
    this.GetVoucherIdsByNameUrl = this.baseUrl+'/api/expense/getvoucheridsbyname?serchText=';
    this.GetVoucherDetailsByIdUrl = this.baseUrl+'/api/expense/getvoucherdetailsbyid?voucherID='; 
    this.GetProductCurrentValueUrl = this.baseUrl+'/api/expense/getproductcurrentvalue?productId=';
    this.GetRecentTransactionsUrl = this.baseUrl+'/api/expense/getrecenttransactions?year={0}&noOfRecords={1}&creditOrDebit={2}';
    this.GetIncomeExpenditureUrl = this.baseUrl+'/api/expense/getincomeexpenditure';
    this.GetAllAccountCurrentBalByYearWithAccMasterUrl = this.baseUrl+'/api/expense/GetAllAccountCurrentBalByYearWithAccMaster?year={0}';
    this.GetLedgerByAccountCodeUrl = this.baseUrl+'/api/expense/getledgerbyaccountcode?year={0}&month={1}&acccode={2}';
    this.GetAccountsMasterByNameUrl = this.baseUrl+'/api/expense/getaccountsmasterbyname?accountMasterName=';
    this.GetRecentAccountsUrl =this.baseUrl+ '/api/expense/getrecentaccounts';
    this.GetOpeningBalanceAllOrByIDUrl =this.baseUrl+ '/api/expense/getopeningbalanceallorbyid?openingBalanceId=';
    this.InsertOrUpdateOpeningBalanceUrl =this.baseUrl+ '/api/expense/insertorupdateopeningbalance';
    this.GetOpeningBalanceByAccountCodeUrl = this.baseUrl+ '/api/expense/getopeningbalancebyaccountcode?accountCode=';

    this.GetFileAllOrByIDUrl =this.baseUrl+ '/api/expense/getfileallorbyid?fileId=';
    this.InsertOrUpdateFileUrl =this.baseUrl+ '/api/expense/insertorupdatefile';
    this.GetFilesByNameUrl =this.baseUrl+ '/api/expense/getfilesbyname?fileName=';


    this.TestJwtAuthUrl = this.baseUrl+'/api/expense/getmyauth?productname=sdf';
    this.TestPostMyAuthUrl = this.baseUrl+'/api/expense/postmyauth';

  }
    TestJwtAuth(): Observable<any> {
      var response = this.http.get<any>(this.TestJwtAuthUrl,{headers:this.getHeader()}).pipe(
        tap(_ => console.log(response))
      );
      return response;
    } 
    TestPostMyAuth(model:TransactionApiModel): Observable<any> {
      var response = this.http.post<any>(this.TestPostMyAuthUrl, model,{headers:this.getHeader()}).pipe(
        tap(_ => console.log(response))
  
      );
      return response;
    } 

  GetProductCurrentValue(productID: number): Observable<any> {
    var response = this.http.get<any>(this.GetProductCurrentValueUrl+productID,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  } 

  saveExpense(expenseManager: ExpenseApiModel[]): Observable<ExpenseApiModel[]> {
    var response = this.http.post<any>(this.SaveExpenserUrl, expenseManager,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(expenseManager))

    );
    return response;
  }

  //saveTransaction(expenseManager: TransactionApiModel[]): Observable<TransactionApiModel[]> {
    saveTransaction(expenseManager: TransactionMasterModel): Observable<TransactionMasterModel>{
    var response = this.http.post<any>(this.SaveOrUpdateTransactionUrl, expenseManager,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(expenseManager))
    );
    return response;
  }
  GetVoucherIdsByName(searchText: string): Observable<TransactionApiModel[]> {
    var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }  
  //GetVoucherDetailsById(voucherID: number): Observable<TransactionApiModel[]> {
    GetVoucherDetailsById(voucherID: number): Observable<TransactionMasterModel> {
    var response = this.http.get<any>(this.GetVoucherDetailsByIdUrl+voucherID,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherDetailsByIdUrl+voucherID).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }  

  GetVendorAllOrByID(VendorAutoID: number): Observable<VendorResponse> {
    var response = this.http.get<any>(this.GetVendorAllOrByIDUrl+VendorAutoID,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }  
  GetVendorByName(VendorName: string) {
    return this.http.get(this.GetVendorByNameUrl+VendorName,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(_[1])),
      map(response => response)
    );
  }
  GetVendorByName1(VendorName: string): Observable<VendorApiModel[]> {
  
    // var response = this.http.get<VendorApiModel[]>(this.GetVendorByNameUrl+VendorName,{headers:this.getHeader()}).pipe(
    //   tap(_ => console.log(response))
    // );
    var response = this.http.get<VendorApiModel[]>(this.GetVendorByNameUrl+VendorName).pipe(
      tap(_ => console.log(response))
    );
    return response;
    
  }
 
  GetVendorByNameWithout(VendorName: string) {
    var response = this.http.get<any>(this.GetVendorByNameUrl+VendorName,{headers:this.getHeader()}).pipe(
      map(_ => console.log(response))
    );
    return response.response;
  }
  SaveOrUpdateVendor(vendorApiModel: VendorApiModel): Observable<any> {
    var response = this.http.post<any>(this.SaveOrUpdateVendorUrl, vendorApiModel,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }


  //Product 
  GetProductByName1(productName: string): Observable<ProductApiModel[]> {
    var response = this.http.get<ProductApiModel[]>(this.GetProductByNameUrl+productName,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
    
  }
  //Product 
  GetAllFds(): Observable<ProductApiModel[]> {
    var response = this.http.get<ProductApiModel[]>(this.GetAllFdsUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  //Product 
  GetFoodProductsByName(productName: string): Observable<ProductApiModel[]> {
    var response = this.http.get<ProductApiModel[]>(this.GetFoodProductsByNameUrl+productName,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetProductAllOrByID(productId: number): Observable<ProductApiModel[]> {
    var response = this.http.get<ProductApiModel[]>(this.GetProductAllOrByIDUrl+productId,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
    
  }
  SaveOrUpdateProduct(productApiModel: ProductApiModel): Observable<any> {
    var response = this.http.post<any>(this.SaveOrUpdateProductUrl, productApiModel,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }
  GetRenewProducts(notifyBeforeDays: number): Observable<ProductApiModel[]> {
    var response = this.http.get<ProductApiModel[]>(this.GetRenewProductsUrl+notifyBeforeDays,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

   //Account 
   GetAccountByName(accountName: string): Observable<AccountApiModel[]> {
    var response = this.http.get<AccountApiModel[]>(this.GetAccountByNameUrl+accountName,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
    
  }
  GetAccountsMasterByName(accountMasterName: string): Observable<AccountMasterModel[]> {
    var response = this.http.get<AccountApiModel[]>(this.GetAccountsMasterByNameUrl+accountMasterName,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  SaveOrUpdateAccount(accountApiModel: AccountApiModel): Observable<any> {
    var response = this.http.post<any>(this.SaveOrUpdateAccountUrl, accountApiModel,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }
  GetAccountCurrentBalanceByYear(year: number,accCode:string,isOpeningBalExist:boolean,month:boolean): Observable<any> {
    var newUrl = this.GetAccountCurrentBalanceByYearUrl;
    newUrl = newUrl.replace('{0}', year.toString());
    newUrl = newUrl.replace('{1}', accCode.toString());
     newUrl = newUrl.replace('{2}', isOpeningBalExist.toString());
     newUrl = newUrl.replace('{3}', month.toString());
    var response = this.http.get<any>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  } 
  GetAllAccountCurrentBalanceByYear(year: number,isOpeningBalExist:boolean): Observable<any> {
    var newUrl = this.GetAllAccountCurrentBalanceByYearUrl;
    newUrl = newUrl.replace('{0}', year.toString());
     newUrl = newUrl.replace('{1}', isOpeningBalExist.toString());
    var response = this.http.get<any>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  } 
  GetRecentTransactions(creditOrDebit='C'): Observable<any> {
    var newUrl = this.GetRecentTransactionsUrl;
    newUrl = newUrl.replace('{0}','2000');
    newUrl = newUrl.replace('{1}', '100');
    newUrl = newUrl.replace('{2}', creditOrDebit);

    var response = this.http.get<TransactionModel[]>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  } 
  GetIncomeExpenditure(): Observable<any> {
    var response = this.http.get<IncomeExpenditureModel[]>(this.GetIncomeExpenditureUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  } 
  GetAllAccountCurrentBalByYearWithAccMaster(year:number): Observable<any> {
    var newUrl = this.GetAllAccountCurrentBalByYearWithAccMasterUrl;
    newUrl = newUrl.replace('{0}',String(year));
    var response = this.http.get<AccountBalanceModel[]>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  } 
  GetLedgerByAccountCode(year:number,month:number,acccode:string): Observable<any> {
    var newUrl = this.GetLedgerByAccountCodeUrl;
    newUrl = newUrl.replace('{0}',String(year));
    newUrl = newUrl.replace('{1}', String(month));
    newUrl = newUrl.replace('{2}', acccode);
    var response = this.http.get<LedgerAccountModel[]>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  // Also totalRecords can be passed if needed 
  GetRecentAccounts(): Observable<AccountModel[]> {
    var response = this.http.get<AccountModel[]>(this.GetRecentAccountsUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  } 
 
  GetOpeningBalanceAllOrByID(openingBalanceId: number): Observable<OpeningBalanceModel[]> {
    var response = this.http.get<OpeningBalanceModel[]>(this.GetOpeningBalanceAllOrByIDUrl+openingBalanceId,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  InsertOrUpdateOpeningBalance(openingBalanceModel: OpeningBalanceModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateOpeningBalanceUrl, openingBalanceModel,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }
  
  GetOpeningBalanceByAccountCode(accountCode: string): Observable<OpeningBalanceModel[]> {
    var response = this.http.get<OpeningBalanceModel[]>(this.GetOpeningBalanceByAccountCodeUrl+accountCode,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  GetFileAllOrByID(fileId: number): Observable<FileModel[]> {
    var response = this.http.get<FileModel[]>(this.GetFileAllOrByIDUrl+fileId,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  InsertOrUpdateFile(fileModel: FileModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateFileUrl, fileModel,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetFilesByName(fileName: string): Observable<FileModel[]> {
    var response = this.http.get<FileModel[]>(this.GetFilesByNameUrl+fileName,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  getHeader(){
    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set("jwt","Bearer "+ token);
    return headers;
  }  
 
}
