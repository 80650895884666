import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { BrandModel } from 'src/app/models/FoodLogger/Brand/brand-model';
import { FoodLoggerService } from 'src/app/services/food-logger.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.css']
})
export class BrandComponent implements OnInit {
  public result = '';
  brandModel: BrandModel;
  model: any;
  searching = false;
  searchFailed = false;
  public brandList:BrandModel [];
  constructor(private foodLoggerservice: FoodLoggerService,private titleService:Title) { this.titleService.setTitle("Manage Brand");}

  ngOnInit() {
    this.brandModel = new BrandModel();
    this.brandModel.Brandlist = [];
    this.foodLoggerservice.GetAllBrand().subscribe(response =>{
      this.brandList = response;
  });
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.foodLoggerservice.GetBrandsByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { Name: string }) => x.Name;

  selectedItem(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.brandModel.BrandId = item.item.BrandId;
    this.brandModel.Name = item.item.Name;
    this.brandModel.IsFood = item.item.IsFood;
    // var accountApiModel = new BrandModel();
    // this.accountModel.AccountID = item.item.AccountID;
    // this.accountModel.AccountCode = item.item.AccountCode;
    // this.accountModel.AccountName = item.item.AccountName;

    // this.accountModel.AccountMasterID = item.item.AccountMasterID;
    // this.accountModel.AccountMasterName = item.item.AccountMasterName;
    // this.accountModel.CashBankOther = item.item.CashBankOther;
    // this.accountModel.AccountStatus = item.item.AccountStatus;
    // this.accountModel.Receipt = item.item.Receipt;
    // this.accountModel.Active = item.item.Active;
    // this.accountModel.AccountAutoID = item.item.AccountAutoID;
  }
  onSubmit() {
    var me = this.brandModel;
    this.foodLoggerservice.InsertOrUpdateBrand(this.brandModel).subscribe(response => {
      this.result = response;
      this.foodLoggerservice.GetAllBrand().subscribe(response =>{
        this.brandList = response;
    });
    },
      err => {
        this.result = err.message;
      }
    );
    // var vendorApimodel = new VendorApiModel();
    // vendorApimodel.vendorAutoID = me.VendorAutoID;
    // vendorApimodel.vendorID = me.VendorID;
    // vendorApimodel.vendorName = me.VendorName;
    // this.expenseManagerService.SaveOrUpdateVendor(vendorApimodel).subscribe(
    //   response => {
    //     console.log(response);
    //     this.vendorModel = new VendorModel();
    //   }
    // );
  }

}
