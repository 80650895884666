import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { ConfigLoaderService } from './config-loader.service';
import { BrandModel } from '../models/FoodLogger/Brand/brand-model';
import { FoodModel } from '../models/FoodLogger/Food/food-model';
import { FoodImageModel } from '../models/FoodLogger/FoodImage/food-image-model';
import { FoodLogModel } from '../models/FoodLogger/FoodLog/food-log-model';
import * as moment from 'moment';
import { MealTypeModel } from '../models/FoodLogger/MealType/meal-type-model';
import { RecipeModel } from '../models/FoodLogger/Recipe/recipe-model';
import { FoodLogMasterModel } from '../models/FoodLogger/FoodLog/food-log-master-model';
import { ProductApiModel } from '../models/ExpenseManager/Product/product-api-model';
import { GetAllFoodByNutritionNameAndLimitModel } from '../models/FoodLogger/NutritionFact/get-all-food-by-nutrition-name-and-limit-model';
import { FoodLoggerNutritionMasterModel } from '../models/FoodLogger/NutritionFact/food-logger-nutrition-master-model';
@Injectable({
  providedIn: 'root'
})
export class FoodLoggerService {
  private baseUrl = '';
  //Brand 
  private InsertOrUpdateBrandUrl = '';
  private GetBrandByIdUrl = '';
  private GetAllBrandUrl = '';
  private GetBrandsByNameUrl = '';

  //Food
  private GetFoodWithNutritionFactByIdUrl = '';
  private GetallfoodUrl = '';
  private SaveFoodWithNutritionFactUrl = '';
  private GetFoodWithNutritionFactbyFoodIdUrl = '';
  private SaveFoodFilesUrl = '';
  private GetFoodsByNameUrl = '';

  //FoodImage
  private InsertOrUpdateFoodImageUrl = '';
  private GetFoodImageByIdUrl = '';
  private GetAllFoodImageUrl = '';
  private DownloadallfoodfilesbyidzipFormatUrl = '';
  private GetallfoodfilesUrl = '';
  private DeleteFoodImageUrl = '';

  //Foodlog
  private InsertOrUpdateFoodLogUrl = '';
  private GetFoodLogByIdUrl = '';
  private GetAllFoodLogUrl = '';
  private DeleteFoodLogUrl = '';
  private GetFoodLogDaySummaryUrl = '';
  private GetFoodLogWithNutritionFactByDateUrl = '';
  private GetFoodPriceByServingSizeUrl = '';
  private GetFoodNutritionLimitByUserUrl = '';
  private GetRecentProductByMealUrl = '';
  private GetRecentRecpieByMealUrl = '';
  

  //MealType
  private GetMealTypeByIdUrl = '';
  private GetAllMealTypeUrl = '';
  private InsertOrUpdateMealTypeUrl = '';
  private GetAllMealTypeByNameUrl = '';

  //Measurement
  private GetMeasurementByIdUrl = '';
  private GetAllMeasurementUrl = '';
  private InsertOrUpdateMeasurementUrl = '';

  //Nutritionfact
  private GetAllNutritionFactOrNutritionFactByIdUrl = '';
  private GetAllNutritionFactUrl = '';
  private InsertOrUpdateNutritionFactUrl = '';
  private GetProductWithoutNutritionFactsUrl = '';
  private GetAllFoodByNutritionNameAndLimitUrl = '';
  //Nutritionmaster
  private InsertOrUpdateNutritionMasterUrl = '';
  private GetNutritionMasterByIdUrl = '';
  private GetAllNutritionUrl = '';
  private GetNutritionsByNameUrl = '';
  //tag
  private GetTagByIdUrl = '';
  private GetAllTagUrl = '';
  private InsertOrUpdateTagUrl = '';

    //Recipe 
    private InsertOrUpdateRecipeUrl = '';
    private GetRecipeByIdUrl = '';
    private GetAllRecipeUrl = '';
    private GetRecipeByNameUrl = '';
    private DeleteRecipeDetailsByIdUrl = '';
    
    // //Recipe 
    // private InsertOrUpdateRecipeDetailsUrl = '';
    // private GetRecipeDetailsByIdUrl = '';
    // private GetAllRecipeDetailsUrl = '';

  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService) {
    this.baseUrl = this.configLoaderService.ApiBasePath;

    //brand
    this.InsertOrUpdateBrandUrl = this.baseUrl + '/api/foodlogger/brand/insertorupdatebrand';
    this.GetBrandByIdUrl = this.baseUrl + '/api/foodlogger/brand/getbrandbyid?id=';
    this.GetAllBrandUrl = this.baseUrl + '/api/foodlogger/brand/getallbrand';
    this.GetBrandsByNameUrl = this.baseUrl + '/api/foodlogger/brand/getbrandsbyname?brandName=';

//Food
    this.GetFoodWithNutritionFactByIdUrl = this.baseUrl + '/api/foodlogger/food/getfoodwithnutritionfactbyid?id=';
    this.GetallfoodUrl = this.baseUrl + '/api/foodlogger/food/getallfood';
    this.SaveFoodWithNutritionFactUrl = this.baseUrl + '/api/foodlogger/food/savefoodwithnutritionfact';
    this.GetFoodWithNutritionFactbyFoodIdUrl = this.baseUrl + '/api/foodlogger/food/getfoodwithnutritionfactbyfoodid';
    this.SaveFoodFilesUrl = this.baseUrl + '/api/foodlogger/food/savefoodfiles?foodId=';
    this.GetFoodsByNameUrl = this.baseUrl + '/api/foodlogger/food/getfoodsbyname?name=';

    //FoodImage
    this.InsertOrUpdateFoodImageUrl = this.baseUrl + '/api/foodlogger/foodimage/insertorupdatefoodimage';
    this.GetFoodImageByIdUrl = this.baseUrl + '/api/foodlogger/foodimage/getfoodimagebyid?id=';
    this.GetAllFoodImageUrl = this.baseUrl + '/api/foodlogger/foodimage/getallfoodimage';
    this.DownloadallfoodfilesbyidzipFormatUrl = this.baseUrl + '/api/foodlogger/foodimage/downloadallfoodfilesbyidzipFormat?foodId=';
    this.GetallfoodfilesUrl = this.baseUrl + '/api/foodlogger/foodimage/getallfoodfiles?foodId=';
    this.DeleteFoodImageUrl = this.baseUrl+'/api/foodlogger/foodimage/deletefoodimage?foodImageId={0}&foodId={1}';

    //FoodLog
    this.InsertOrUpdateFoodLogUrl = this.baseUrl + '/api/foodlogger/foodlog/insertorupdatefoodlog';
    this.GetFoodLogByIdUrl = this.baseUrl + '/api/foodlogger/foodlog/getfoodlogbyid?id=';
    this.GetAllFoodLogUrl = this.baseUrl + '/api/foodlogger/foodlog/getallfoodlog';
    this.DeleteFoodLogUrl = this.baseUrl + '/api/foodlogger/foodlog/deletefoodlog?id=';
    this.GetFoodLogDaySummaryUrl = this.baseUrl + '/api/foodlogger/foodlog/getfoodlogdaysummary?dt=';
    this.GetFoodLogWithNutritionFactByDateUrl = this.baseUrl + '/api/foodlogger/foodlog/getfoodlogwithnutritionfactbydate'; 
    this.GetFoodPriceByServingSizeUrl = this.baseUrl + '/api/foodlogger/food/getfoodpricebyservingsize?servingSize={0}&productId={1}';
    this.GetFoodNutritionLimitByUserUrl = this.baseUrl + '/api/foodlogger/nutrition/getfoodnutritionlimitbyuser';
    this.GetRecentProductByMealUrl = this.baseUrl + '/api/foodlogger/foodlog/getrecentproductbymeal?mealTypeId={0}&totalRecords={1}';
    this.GetRecentRecpieByMealUrl = this.baseUrl + '/api/foodlogger/foodlog/getrecentrecpiebymeal?mealTypeId={0}&totalRecords={1}';

    //MealType
    this.GetMealTypeByIdUrl = this.baseUrl + '/api/foodlogger/mealtype/getmealtypebyid?id=';
    this.GetAllMealTypeUrl = this.baseUrl + '/api/foodlogger/mealtype/getallmealtype';
    this.InsertOrUpdateMealTypeUrl = this.baseUrl + '/api/foodlogger/mealtype/insertorupdatemealtype';
    this.GetAllMealTypeByNameUrl = this.baseUrl + '/api/foodlogger/mealtype/getfoodpricebyservingsize?name=';

    //Measurement
    this.GetMeasurementByIdUrl = this.baseUrl + '/api/foodlogger/measurement/getmeasurementbyid';
    this.GetAllMeasurementUrl = this.baseUrl + '/api/foodlogger/measurement/getallmeasurement';
    this.InsertOrUpdateMeasurementUrl = this.baseUrl + '/api/foodlogger/measurement/insertorupdatemeasurement';

    //Nutritionfact
    this.GetAllNutritionFactOrNutritionFactByIdUrl = this.baseUrl + '/api/foodloggernutritionfact/getnutritionfactbyid?id=';
    this.GetAllNutritionFactUrl = this.baseUrl + '/api/foodlogger/nutritionfact/getallnutritionfact';
    this.InsertOrUpdateNutritionFactUrl = this.baseUrl + '/api/foodlogger/nutritionfact/insertorupdatenutritionfact';
    this.GetProductWithoutNutritionFactsUrl = this.baseUrl + '/api/foodlogger/nutritionfact/getproductwithoutnutritionfacts';
    this.GetAllFoodByNutritionNameAndLimitUrl = this.baseUrl + '/api/foodlogger/nutritionfact/getallfoodbynutritionnameandlimit?nutritionName={0}&value={1}&operation={2}';

    //Nutritionmaster
  this.InsertOrUpdateNutritionMasterUrl = this.baseUrl + '/api/foodlogger/nutritionfact/insertorupdatenutritionmaster';
  this.GetNutritionMasterByIdUrl = this.baseUrl + '/api/foodlogger/nutritionfact/getnutritionmasterbyid?id=';
  this.GetAllNutritionUrl = this.baseUrl + '/api/foodlogger/nutritionfact/getallnutrition';
  this.GetNutritionsByNameUrl = this.baseUrl + '/api/foodlogger/nutritionfact/getnutritionsbyname?nutritionName=';

    //tag
    this.GetTagByIdUrl = this.baseUrl + '/api/foodlogger/tag/gettagbyid?id=';
    this.GetAllTagUrl = this.baseUrl + '/api/foodlogger/tag/getalltag';
    this.InsertOrUpdateTagUrl = this.baseUrl + '/api/foodlogger/tag/insertorupdatetag';

        //Recipe 
        
        this.InsertOrUpdateRecipeUrl = this.baseUrl + '/api/foodlogger/recipe/insertorupdaterecipe';
        this.GetRecipeByIdUrl = this.baseUrl + '/api/foodlogger/recipe/getrecipebyid?id=';
        this.GetAllRecipeUrl = this.baseUrl + '/api/foodlogger/recipe/getallrecipe';
        this.GetRecipeByNameUrl = this.baseUrl + '/api/foodlogger/recipe/getrecipesbyname?recipeName=';
        this.DeleteRecipeDetailsByIdUrl = this.baseUrl + '/api/foodlogger/recipe/deleterecipedetailsbyid?id=';
  }
  // Brand Starts
  InsertOrUpdateBrand(model: BrandModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateBrandUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }

  GetBrandById(id: number): Observable<any> {
    var response = this.http.get<any>(this.GetBrandByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllBrand(): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetAllBrandUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetBrandsByName(name:string): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetBrandsByNameUrl+name, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  // Food
  GetFoodWithNutritionFactById(id: number): Observable<any> {
    var response = this.http.get<any>(this.GetFoodWithNutritionFactByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  Getallfood(): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetallfoodUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetFoodsByName(name:string): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetFoodsByNameUrl+name, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  SaveFoodWithNutritionFact(model: FoodModel): Observable<any> {
    var response = this.http.post<any>(this.SaveFoodWithNutritionFactUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  GetFoodWithNutritionFactbyFoodId(id: number, quantitySize: number): Observable<any> {
    var response = this.http.get<any>(this.GetFoodWithNutritionFactbyFoodIdUrl + "?foodId=" + id + "&quantitySize=" + quantitySize, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetFoodPriceByServingSize(servingSize:number, productId:number): Observable<any> {
    var newUrl = this.GetFoodPriceByServingSizeUrl;
    newUrl = newUrl.replace('{0}', servingSize.toString());
    newUrl = newUrl.replace('{1}', productId.toString());
    var response = this.http.get<any>(newUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }


  SaveFoodFiles(model: any,foodId:number): Observable<any> {
    var response = this.http.post<any>(this.SaveFoodFilesUrl+foodId, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  SaveFoodFilesCore(model: any,foodId:number): Observable<any> {
    var response = this.http.post<any>("http://localhost:1653/home/upload?"+foodId, model).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  //   //netapi
  //   public downloadImageApi_bak(imageName: string): Observable <Blob> {  
  //     return this.http.get("http://localhost:5182/api/foodlogger/food/" + 'getallfoodfilesdownload?fileName=' + imageName, {  
  //       headers: this.getHeader(), responseType: 'blob'
  //     });  
  // }  
  //     //netapi
  //     public downloadImageApi(imageName: string): Observable <any> {  
  //       return this.http.get("http://localhost:5182/api/foodlogger/food/" + 'getallfoodfilesdownload?fileName=' + imageName, {  
  //         headers: this.getHeader()
  //         , responseType: 'blob'
  //       });  
  //   }  
     //netapi
     public downloadallfoodfilesbyidzipFormat(foodId: number): Observable <any> {  
      //return this.http.get("http://localhost:5182/api/foodlogger/food/" + 'downloadallfoodfilesbyidzipFormat?foodId=' + foodId, {  
       return this.http.get(this.DownloadallfoodfilesbyidzipFormatUrl + foodId, {   
         headers: this.getHeader()
        , responseType: 'blob'
      });  
  }
  // getting image
public GetAllFoodFiles(foodId: number): Observable <any> {  
  //return this.http.get("http://localhost:5182/api/foodlogger/food/getallfoodfiles?id="+foodId, { headers: this.getHeader() });  
  return this.http.get(this.GetallfoodfilesUrl+foodId, { headers: this.getHeader() });
} 
//deleting Image
public DeleteFoodImage(foodImageId:number,foodId: number): Observable <any> {  
  var newUrl = this.DeleteFoodImageUrl;
  newUrl = newUrl.replace('{0}', foodImageId.toString());
  newUrl = newUrl.replace('{1}', foodId.toString());
  //return this.http.get("http://localhost:5182/api/foodlogger/food/getallfoodfiles?id="+foodId, { headers: this.getHeader() });  
  return this.http.delete(newUrl, { headers: this.getHeader() });
} 
//   //Coreendpoint
//   public downloadImage(imageName: string): Observable <Blob> {  
//     return this.http.get("http://localhost:1653/home/" + 'DownloadFile?fileName=' + imageName, {  
//         responseType: 'blob'
//     });  
// }
// // getting image
// public GetAllImageFromCore(imageName: string): Observable <any> {  
//   return this.http.get("http://localhost:1653/home/GetAllImageCore?id=1");  
// }

// // getting image
// public GetImage(imageName: string): Observable <any> {  
//   return this.http.get("http://localhost:5182/api/foodlogger/food/getfoodfiles?id=1", { headers: this.getHeader() });  
// } 


  //Food Image
  InsertOrUpdateFoodImage(model: FoodImageModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateFoodImageUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }

  GetFoodImageById(id: number): Observable<any> {
    var response = this.http.get<any>(this.GetFoodImageByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllFoodImage(): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetAllFoodImageUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  //FoodLog
 // InsertOrUpdateFoodLog(model: FoodLogModel): Observable<any> {
  InsertOrUpdateFoodLog(model: FoodLogMasterModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateFoodLogUrl, model, { headers: this.getHeader() }).pipe(
      delay(2000),
      tap(_ => console.log(response))

    );
    return response;
  }

  GetFoodLogById(id: number): Observable<any> {
    var response = this.http.get<any>(this.GetFoodLogByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllFoodLog(): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetAllFoodLogUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  // GetFoodLogWithNutritionFactByDate(logDate: Date): Observable<any[]> {
  //   var logDateNew = moment(logDate).format("YYYY-MM-DD");
  //   var response = this.http.get<any[]>(this.GetFoodLogWithNutritionFactByDateUrl + logDateNew, { headers: this.getHeader() }).pipe(
  //     tap(_ => console.log(response))
  //   );
  //   return response;
  // }
  //GetFoodLogWithNutritionFactByDate(logDate: Date): Observable<any[]> {
    GetFoodLogWithNutritionFactByDate(logDate: Date): Observable<FoodLogMasterModel> {
    var model = {
      Created:logDate.toDateString()
    }

    var logDateNew = moment(logDate).format("YYYY-MM-DD");
    var response = this.http.post<any[]>(this.GetFoodLogWithNutritionFactByDateUrl,model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  DeleteFoodLog(id: number): Observable<any> {
    var response = this.http.post<any>(this.DeleteFoodLogUrl + id, null, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }
  GetFoodLogDaySummary(logDate: Date): Observable<any> {
    var response = this.http.get<any>(this.GetFoodLogDaySummaryUrl + logDate, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  GetFoodNutritionLimitByUser(): Observable<any> {
    var response = this.http.get<any>(this.GetFoodNutritionLimitByUserUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetRecentProductByMeal(mealTypeId:number, totalRecords:number): Observable<ProductApiModel[]> {
    var newUrl = this.GetRecentProductByMealUrl;
    newUrl = newUrl.replace('{0}', mealTypeId.toString());
    newUrl = newUrl.replace('{1}', totalRecords.toString());
    var response = this.http.get<ProductApiModel[]>(newUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetRecentRecpieByMeal(mealTypeId:number, totalRecords:number): Observable<RecipeModel[]> {
    var newUrl = this.GetRecentRecpieByMealUrl;
    newUrl = newUrl.replace('{0}', mealTypeId.toString());
    newUrl = newUrl.replace('{1}', totalRecords.toString());
    var response = this.http.get<RecipeModel[]>(newUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  //MealType


  GetMealTypeById(id: number): Observable<any> {
    var response = this.http.get<any>(this.GetMealTypeByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllMealType(): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetAllMealTypeUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllMealTypeByName(name:string): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetAllMealTypeByNameUrl+name, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  InsertOrUpdateMealType(model: MealTypeModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateMealTypeUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }
  //Measurement
  GetMeasurementById(id: number): Observable<any> {
    var response = this.http.get<any>(this.GetMeasurementByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllMeasurement(): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetAllMeasurementUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  InsertOrUpdateMeasurement(model: FoodImageModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateMeasurementUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }
  //NutrionFacts
  GetAllNutritionFactOrNutritionFactById(id: number): Observable<any> {
    var response = this.http.get<any>(this.GetAllNutritionFactOrNutritionFactByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllNutritionFact(): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetAllNutritionFactUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  InsertOrUpdateNutritionFact(model: FoodImageModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateNutritionFactUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }
  GetProductWithoutNutritionFacts(): Observable<any[]> {
    var response = this.http.get<ProductApiModel[]>(this.GetProductWithoutNutritionFactsUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllFoodByNutritionNameAndLimit(nutritionName:string, value:number,operation:string): Observable<GetAllFoodByNutritionNameAndLimitModel[]> {
    var newUrl = this.GetAllFoodByNutritionNameAndLimitUrl;
    newUrl = newUrl.replace('{0}', nutritionName.toString());
    newUrl = newUrl.replace('{1}', value.toString());
    newUrl = newUrl.replace('{2}', operation.toString());
    var response = this.http.get<GetAllFoodByNutritionNameAndLimitModel[]>(newUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  //NutritionMaster
  InsertOrUpdateNutritionMaster(model: FoodLoggerNutritionMasterModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateNutritionMasterUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }
  GetNutritionMasterById(id: number): Observable<FoodLoggerNutritionMasterModel> {
    var response = this.http.get<any>(this.GetNutritionMasterByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllNutrition(): Observable<FoodLoggerNutritionMasterModel[]> {
    var response = this.http.get<FoodLoggerNutritionMasterModel[]>(this.GetAllNutritionUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetNutritionsByName(name:string): Observable<FoodLoggerNutritionMasterModel[]> {
    var response = this.http.get<FoodLoggerNutritionMasterModel[]>(this.GetNutritionsByNameUrl+name, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  //Tags
  GetTagById(id: number): Observable<any> {
    var response = this.http.get<any>(this.GetTagByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllTag(): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetAllTagUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  InsertOrUpdateTag(model: FoodImageModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateTagUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }

  // Recipe Starts
  InsertOrUpdateRecipe(model: RecipeModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateRecipeUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }

  GetRecipeById(id: number): Observable<any> {
    var response = this.http.get<any>(this.GetRecipeByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllRecipe(): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetAllRecipeUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetRecipesByName(name:string): Observable<any[]> {
    var response = this.http.get<any[]>(this.GetRecipeByNameUrl+name, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  DeleteRecipeDetailsById(id:number): Observable<any> {
    var response = this.http.post<any>(this.DeleteRecipeDetailsByIdUrl+id,null, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  getHeader(){
    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set("jwt","Bearer "+ token);
    return headers;
  }  
}
