import { MeasurementModel } from '../../FoodLogger/Measurement/measurement-model';
import { DropdownListItem } from '../dropdown-list-item';
import { TransactionGridModel } from './transaction-grid-model';

export class TransactionModel {
    public TTransID:number;
    public  VoucherDate:Date;
   public VoucherType:string;
   public VoucherID:number;
   public AccountCode :string;
   public AccountName:string;
   public Amount :number
   public PayMode :string;
   public TranType: string;
   public ProductID:number;
   public ProductName:string;
   public VoucherDateDisplay:any;
   public VoucherTimeDisplay:any;
   public VoucherIDAutoComplete:any;
   public ProductIDAutoComplete:any;
   public AccountCodeAutoComplete:any;
   public VoucherTypeList:DropdownListItem[];
   public  PaymentTypeList:DropdownListItem[];
   public  TranTypeList:DropdownListItem[];
   public  TransactionGridModel:TransactionGridModel[];
   public  isEditMode:boolean;
   public  Id:number;

   public Quantity:number;
   public Measurement:Number;
   public MeasurementId:number;
   public Narration:string;
   public MeasurementModel:MeasurementModel[];

   

}
