import { FixedDeposit } from "./fixed-deposit";

export class ProductApiModel {
    ProductID:number;
    ProductName:string;
    VendorID:number;
    VendorName:string;
    Amount:number;
    ProductAutoID:number;
    public BrandId:number;
    public BrandName:string;
    public IsFood:boolean;
    public ProductUniqueName:string;
    public SummaryNotes:string;

    public IsFixedDeposit:boolean;
    public ExpenseFixedDepositModel:FixedDeposit;
    public IsEntertainment:boolean;

    public IsForItemTracker:boolean;
    public IsActive:boolean;

    public isMatured:boolean;

    public PurchaseDate:Date;
    public PurchaseDateDisplay:any;
    public RenewDate:Date;
    public RenewDateDisplay:any;

    public IsRenewed:boolean;

    /*For sum */
    public IsCheckedForTotal:boolean;
}
