import { Component, OnInit } from '@angular/core';
import { ConfigLoaderService } from './services/config-loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { RouterLinkInfoService } from './services/router-link-info.service';
import { TestTableService } from './services/test-table.service';
import { TestModel } from './models/test-model';
import { NotificationService } from './services/notification.service';
import { Notification } from './models/Notification/notification';
import { NotificationPostModel } from './models/Notification/notification-post-model';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public title = 'MyzingonlineAngularUI';
  public result = '';
  public testTable: TestModel;
  public notificationModels: Notification[];
  message: string;
  spinnerStyle = Spinkit;
  constructor(private configLoaderService: ConfigLoaderService, private router: Router
    , private routerinfoservice: RouterLinkInfoService, private testTableService: TestTableService
    , private notificationService: NotificationService) {
  }
  ngOnInit() {
    this.title = this.configLoaderService.ApiUrl;
    this.notificationModels = [];
    var model = new NotificationPostModel();
    model.ProjectID = 0; model.IsReaded = false;
    this.notificationService.GetAllNotificationOrByApplicationID(model).subscribe(response => {
      //console.log(response);
      this.notificationModels = response;
      console.log(this.notificationModels);
    },
      err => {
        console.log(err.Message);

      }

    );

    this.notificationService.sharedMessage.subscribe(message => this.message = message)
    this.notificationService._notificationData.subscribe(
      notficationdata => {
        this.notificationModels = notficationdata;

      }
    );



    $(document).ready(function () {
      //alert('hello I am here');
      // Toggle the side navigation
      $("#sidebarToggle").on('click', function (e) {
        e.preventDefault();
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
      });
      $(".scroll-to-top-hidemenu").on('click', function (e) {
        e.preventDefault();
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
      });

      // Scroll to top button appear
      $(document).on('scroll', function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
          $('.scroll-to-top').fadeIn();
          // $('.scroll-to-top-hidemenu').fadeIn();
        } else {
          $('.scroll-to-top').fadeOut();
          // $('.scroll-to-top-hidemenu').fadeOut();
        }
      });

    });
    var userName = localStorage.getItem('userName');
    $('#login-name').text(userName);

  }
  logout(event) {
    this.router.navigate(['/login/logout']);
  }
  navigation(event) {
    var currentHref = event.currentTarget.href;
    //window.location.href =me;
    localStorage.setItem('lasturl', currentHref);

  }
  onActivate(componentRef) {
    var currentUrl = window.location.pathname;
    $('#breadItem').text(this.routerinfoservice.getPageName(currentUrl));

  }
  public onEdit() {

    let element = document.getElementById('scroll-top');
    element.scrollIntoView({ behavior: "smooth" });
  }
  public redirectToUrl(id) {
    let me = id;
    var newModels = this.notificationModels.filter(function (tag) {
      return tag.NotificationID === id;
    })[0];
    // this.router.navigate([newModels.
    //   LinkText,{habitName: newModels.NotificationText.substring(0,3)}]);
    window.location.href = newModels.LinkText + '?habitName=' + newModels.NotificationText.substring(0, 3);
  }
}
