import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { FoodLogModel } from 'src/app/models/FoodLogger/FoodLog/food-log-model';
import { FoodModel } from 'src/app/models/FoodLogger/Food/food-model';
import { FoodLoggerService } from 'src/app/services/food-logger.service';
import { NutritionFactModel } from 'src/app/models/FoodLogger/NutritionFact/nutrition-fact-model';
import { MealTypeModel } from 'src/app/models/FoodLogger/MealType/meal-type-model';
import * as moment from 'moment';
import { FoodLogProgress } from 'src/app/models/FoodLogger/FoodLog/food-log-progress';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { FoodLogPriceModel } from 'src/app/models/FoodLogger/FoodLog/food-log-price-model';
import { FoodLogNutritionLimitModel } from 'src/app/models/FoodLogger/FoodLog/food-log-nutrition-limit-model';
import { RecipeModel } from 'src/app/models/FoodLogger/Recipe/recipe-model';
import { RecipeDetailsModel } from 'src/app/models/FoodLogger/Recipe/recipe-details-model';
import { FitBitService } from 'src/app/services/fit-bit.service';
import { SleepModel } from 'src/app/models/FitBit/sleep-model';
import { HeartModel } from 'src/app/models/FitBit/heart-model';
import { ExerciseModel } from 'src/app/models/FitBit/exercise-model';
import { FitbitBodyModel } from 'src/app/models/FitBit/fitbit-body-model';
import { FitbitFoodModel } from 'src/app/models/FitBit/fitbit-food-model';
import { FoodLogMasterModel } from 'src/app/models/FoodLogger/FoodLog/food-log-master-model';
import { ProductApiModel } from 'src/app/models/ExpenseManager/Product/product-api-model';
import { JsonpClientBackend } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import {DomSanitizer, Title} from "@angular/platform-browser";
import { FoodImageModel } from 'src/app/models/FoodLogger/FoodImage/food-image-model';
@Component({
  selector: 'app-food-log',
  templateUrl: './food-log.component.html',
  styleUrls: ['./food-log.component.css']
})
export class FoodLogComponent implements OnInit, OnChanges {
  public FatLimit = 30;
  public CalciumLimit = 1000;
  public ProtienLimit = 70;
  public CarbohydrateLimit = 70;
  public foodLogProgress: FoodLogProgress[];
  public currentDate;
  public result = '';
  public foodLogModel: FoodLogModel;
  public foodLogMasterModel: FoodLogMasterModel;
  public foodLogsModel: FoodLogModel[];
  public nutritionFactModel: NutritionFactModel;
  public mealTypeModel: MealTypeModel[];
  searching = false;
  searchFailed = false;
  public priceModel: FoodLogPriceModel;
  public foodLogNutritionLimitModel: FoodLogNutritionLimitModel;
  public recipeModel: RecipeModel;
  public recipeQuantiyChangedModel: RecipeModel;
  public nutritionFactListModel: NutritionFactModel[];
  public priceListModel: FoodLogPriceModel[];
  public recipeQuantity: number;
  public isReadMore = true;
  public isRequiredError = 'This field is required';
  public isExceedsMaxLengthError = 'This field  excceds max length';
  sleepModel: SleepModel;
  heartModel: HeartModel;
  exerciseModel: ExerciseModel;
  fitbitBodyModel: FitbitBodyModel;
  fitbitFoodModel: FitbitFoodModel;
  public isServingSize = true;
  public foodSugarGrade = "D+";
  public foodSodiumGrade = "D+";
  public foodOxalateGrade = "D+";
  public foodProtienGrade = "D+";
  public RecipieAddded = [];
  public recipeCounter = 0;
  public recentLogFoods: ProductApiModel[];
  public recentLogReciepe: RecipeModel[];
  public nutrifactSumModel: NutritionFactModel;
  public DateFromOutside:string;
  public foodImageModel: FoodImageModel[];
  thumbnail: any;
  constructor(private foodLoggerservice: FoodLoggerService, private expenseService: ExpenseManagerService, private fitbitService: FitBitService,public datepipe: DatePipe
    ,private ref: ChangeDetectorRef,private titleService:Title,private sanitizer: DomSanitizer
  ) {  this.titleService.setTitle("Log Food");}
  @ViewChild('logFoodForm') loginForm: HTMLFormElement;
  ngOnInit() {
    var d = new Date();
    // let latest_date =this.datepipe.transform(d, 'yyyy-MM-dd');
    var fitbitResp;
    // this.fitbitService.GetFitbitFoodData('2021-07-01').subscribe(
    //   response => {
    //     fitbitResp = response;
    //     this.fitbitFoodModel = fitbitResp;
    //     console.log(this.exerciseModel);
    //   },
    //   err => { this.result = err.message; }

    // );
    // this.fitbitService.GetFitbitBodyData('2021-07-01').subscribe(
    //   response => {
    //     fitbitResp = response;
    //     this.fitbitBodyModel = fitbitResp;
    //     console.log(this.exerciseModel);
    //   },
    //     err => { this.result = err.message; }

    // );
    // this.fitbitService.GetFitbitExerciseData('2021-07-01').subscribe(
    //   response => {
    //     fitbitResp = response;
    //     this.exerciseModel = fitbitResp;
    //     console.log(this.exerciseModel);
    //   },
    //     err => { this.result = err.message; }

    // );
    // this.fitbitService.GetFitbitHeartData('2021-07-01' ).subscribe(
    //   response => {
    //     fitbitResp = response;
    //     this.heartModel = fitbitResp;
    //   },
    //     err => { this.result = err.message; }

    // );
    // this.fitbitService.GetFitbitSleepData('2021-07-01' ).subscribe(
    //   response => {
    //     fitbitResp = response;
    //     this.sleepModel = fitbitResp;
    //   },
    //     err => { this.result = err.message; }

    // );

    this.foodLogModel = new FoodLogModel();
    this.foodLogMasterModel = new FoodLogMasterModel()
    this.currentDate = new Date();
    this.foodLogModel.DateDisplay = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
    this.foodLogModel.TimeDisplay = { hour: this.currentDate.getHours(), minute: this.currentDate.getMinutes() };

    this.DateFromOutside=this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.foodLogModel.FoodModel = new FoodModel();
    this.foodLogModel.MealTypeModel = new MealTypeModel();
    this.nutritionFactModel = new NutritionFactModel();
    this.foodLogsModel = [];
    this.mealTypeModel = [];
    this.foodLogProgress = [];
    this.priceModel = new FoodLogPriceModel();

    this.recipeModel = new RecipeModel();
    this.recipeModel.RecipeDetails = [];
    this.recipeModel.RecipeList = [];
    this.nutritionFactListModel = [];
    this.priceListModel = [];
    this.recipeQuantity = 0;

    this.recipeQuantiyChangedModel = new RecipeModel();
    this.recipeQuantiyChangedModel.RecipeDetails = [];
    // this.SettingFoodLogProcessData();



    this.foodLoggerservice.GetAllMealType().subscribe(response => {
      this.mealTypeModel = response;
    },
      err => { this.result = err.message; }

    );
    this.foodLoggerservice.GetFoodNutritionLimitByUser().subscribe(response => {
      this.foodLogNutritionLimitModel = response;
      this.foodLogProgress = this.GetInitialFoodLogProcessData();
    },
      err => { this.result = err.message; }

    );
    this.getFoodLogByDate(this.currentDate);

    this.recentLogFoods = [];
    this.recentLogReciepe = [];
    this.nutrifactSumModel = new NutritionFactModel();
    this.foodImageModel = [];
  }
  resetLog() {
    this.foodLogModel = new FoodLogModel();
    this.foodLogMasterModel = new FoodLogMasterModel()
    // this.currentDate = new Date();
    //this.foodLogModel.DateDisplay = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
    // this.foodLogModel.TimeDisplay = { hour: this.currentDate.getHours(), minute: this.currentDate.getMinutes() };

    this.foodLogModel.FoodModel = new FoodModel();
    this.foodLogModel.MealTypeModel = new MealTypeModel();
    this.nutritionFactModel = new NutritionFactModel();
    this.foodLogsModel = [];
    this.mealTypeModel = [];
    this.foodLogProgress = [];
    this.priceModel = new FoodLogPriceModel();

    this.recipeModel = new RecipeModel();
    this.recipeModel.RecipeDetails = [];
    this.recipeModel.RecipeList = [];
    this.nutritionFactListModel = [];
    this.priceListModel = [];
    this.recipeQuantity = 0;

    this.recipeQuantiyChangedModel = new RecipeModel();
    this.recipeQuantiyChangedModel.RecipeDetails = [];
    // this.SettingFoodLogProcessData();


    this.foodLoggerservice.GetAllMealType().subscribe(response => {
      this.mealTypeModel = response;
    },
      err => { this.result = err.message; }

    );
    this.foodLoggerservice.GetFoodNutritionLimitByUser().subscribe(response => {
      this.foodLogNutritionLimitModel = response;
      this.foodLogProgress = this.GetInitialFoodLogProcessData();
    },
      err => { this.result = err.message; }

    );

  }
  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    for (let i in changes) {
      var me = i;
    }
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        //(this.foodLoggerservice.GetFoodsByName(term).pipe(
        (this.expenseService.GetFoodProductsByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { Name: string }) => x.Name;
  selectedItem(item) {
    //     //this.clickedItem=item.item;
    console.log(item);
    this.foodLogModel.FoodModel.FoodId = item.item.ProductID;
    this.foodLogModel.FoodModel.Name = item.item.ProductName;
    //this.foodLogModel.FoodModel.FoodId = item.item.FoodId;
    //this.foodLogModel.FoodModel.Name = item.item.Name;
    this.showNutritionfactByFoodId(this.foodLogModel.FoodModel.FoodId, 100);
    this.showFoodImage(this.foodLogModel.FoodModel.FoodId);
  }

  //Recipe auto complete
  searchRecipe = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        //(this.foodLoggerservice.GetFoodsByName(term).pipe(
        (this.foodLoggerservice.GetRecipesByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatterRecipe = (x: { Name: string }) => x.Name;
  selectedItemRecipe(item) {
    //     //this.clickedItem=item.item;
    console.log(item);
    this.recipeModel = item.item;
    this.recipeQuantiyChangedModel = JSON.parse(JSON.stringify(this.recipeModel));;
    //var nutrifactSumModel = new NutritionFactModel();//recipeModel.RecipeDetails
    //this.priceModel.CalculatedAmount
    // looping through all item and caculate sum for all nutrution fact
    var quantity = 100;
    let observableBatch = [];
    let priceListModel = [];
    this.nutrifactSumModel = new NutritionFactModel();
    item.item.RecipeDetails.forEach(element => {

      priceListModel.push({ "ProductID": element.ProductID, "Price": element.CalculatedPrice });
      observableBatch.push(this.foodLoggerservice.GetFoodWithNutritionFactbyFoodId(element.ProductID, element.ServingSizeValue));
    });
    let totalMealPrice = 0;
    this.updateNutritionFactforRecipe(observableBatch,priceListModel);
    // forkJoin(observableBatch).subscribe(responseList => {
    //   responseList.forEach(nutrimodel => {
    //     var response = nutrimodel as any;
    //     priceListModel.forEach(model => {
    //       if (response.FoodId == model.ProductID) {
    //         totalMealPrice += model.Price;
    //       }
    //     });

    //     this.nutrifactSumModel.Calories += response.NutritionFactModel.Calories;
    //     this.nutrifactSumModel.TotalCabohydrate += response.NutritionFactModel.TotalCabohydrate;
    //     this.nutrifactSumModel.Sugar += response.NutritionFactModel.Sugar;
    //     this.nutrifactSumModel.SaturatedFat += response.NutritionFactModel.SaturatedFat;
    //     this.nutrifactSumModel.Cholesterol += response.NutritionFactModel.Cholesterol;
    //     this.nutrifactSumModel.Sodium += response.NutritionFactModel.Sodium;
    //     this.nutrifactSumModel.TotalCabohydrate += response.NutritionFactModel.TotalCabohydrate;
    //     this.nutrifactSumModel.DietryFiber += response.NutritionFactModel.DietryFiber;
    //     this.nutrifactSumModel.Protien += response.NutritionFactModel.Protien;

    //     this.nutrifactSumModel.VitaminA += response.NutritionFactModel.VitaminA;
    //     this.nutrifactSumModel.VitaminB1_Thiamin += response.NutritionFactModel.VitaminB1_Thiamin;
    //     this.nutrifactSumModel.VitaminB2_Riboflavin += response.NutritionFactModel.VitaminB2_Riboflavin;
    //     this.nutrifactSumModel.VitaminB3_Niacin += response.NutritionFactModel.VitaminB3_Niacin;
    //     this.nutrifactSumModel.VitaminB5_PantothenicAcid += response.NutritionFactModel.VitaminB5_PantothenicAcid;

    //     this.nutrifactSumModel.VitaminB6 += response.NutritionFactModel.VitaminB6;
    //     this.nutrifactSumModel.VitaminB12 += response.NutritionFactModel.VitaminB12;
    //     this.nutrifactSumModel.VitaminC += response.NutritionFactModel.VitaminC;
    //     this.nutrifactSumModel.VitaminD += response.NutritionFactModel.VitaminD;
    //     this.nutrifactSumModel.VitaminE += response.NutritionFactModel.VitaminE;
    //     this.nutrifactSumModel.VitaminK += response.NutritionFactModel.VitaminK;

    //     this.nutrifactSumModel.Folate += response.NutritionFactModel.Folate;
    //     this.nutrifactSumModel.Iron += response.NutritionFactModel.Iron;
    //     this.nutrifactSumModel.Calcium += response.NutritionFactModel.Calcium;
    //     this.nutrifactSumModel.Phosphorus += response.NutritionFactModel.Phosphorus;
    //     this.nutrifactSumModel.Mangnese += response.NutritionFactModel.Mangnese;
    //     this.nutrifactSumModel.Iodin += response.NutritionFactModel.Iodin;

    //     this.nutrifactSumModel.Biotin += response.NutritionFactModel.Biotin;
    //     this.nutrifactSumModel.Zinc += response.NutritionFactModel.Zinc;
    //     this.nutrifactSumModel.Copper += response.NutritionFactModel.Copper;
    //     this.nutrifactSumModel.Selenium += response.NutritionFactModel.Selenium;
    //     this.nutrifactSumModel.Omega3 += response.NutritionFactModel.Omega3;
    //     this.nutrifactSumModel.Cobalamin += response.NutritionFactModel.Cobalamin;
    //     this.nutrifactSumModel.Oxalate += response.NutritionFactModel.Oxalate;

    //   });
    //   this.nutritionFactModel = JSON.parse(JSON.stringify(this.nutrifactSumModel));
    //   this.priceModel.CalculatedAmount = totalMealPrice;
    // });

  }
  updateNutritionFactforRecipe(observableBatch, priceListModel) {
    let totalMealPrice = 0;
    this.nutrifactSumModel = new NutritionFactModel();
    forkJoin(observableBatch).subscribe(responseList => {
      responseList.forEach(nutrimodel => {
        var response = nutrimodel as any;
        priceListModel.forEach(model => {
          if (response.FoodId == model.ProductID) {
            totalMealPrice += model.Price;
          }
        });

        this.nutrifactSumModel.Calories += response.NutritionFactModel.Calories;
        this.nutrifactSumModel.TotalCabohydrate += response.NutritionFactModel.TotalCabohydrate;
        this.nutrifactSumModel.Sugar += response.NutritionFactModel.Sugar;
        this.nutrifactSumModel.SaturatedFat += response.NutritionFactModel.SaturatedFat;
        this.nutrifactSumModel.Cholesterol += response.NutritionFactModel.Cholesterol;
        this.nutrifactSumModel.Sodium += response.NutritionFactModel.Sodium;
        this.nutrifactSumModel.TotalCabohydrate += response.NutritionFactModel.TotalCabohydrate;
        this.nutrifactSumModel.DietryFiber += response.NutritionFactModel.DietryFiber;
        this.nutrifactSumModel.Protien += response.NutritionFactModel.Protien;

        this.nutrifactSumModel.VitaminA += response.NutritionFactModel.VitaminA;
        this.nutrifactSumModel.VitaminB1_Thiamin += response.NutritionFactModel.VitaminB1_Thiamin;
        this.nutrifactSumModel.VitaminB2_Riboflavin += response.NutritionFactModel.VitaminB2_Riboflavin;
        this.nutrifactSumModel.VitaminB3_Niacin += response.NutritionFactModel.VitaminB3_Niacin;
        this.nutrifactSumModel.VitaminB5_PantothenicAcid += response.NutritionFactModel.VitaminB5_PantothenicAcid;

        this.nutrifactSumModel.VitaminB6 += response.NutritionFactModel.VitaminB6;
        this.nutrifactSumModel.VitaminB12 += response.NutritionFactModel.VitaminB12;
        this.nutrifactSumModel.VitaminC += response.NutritionFactModel.VitaminC;
        this.nutrifactSumModel.VitaminD += response.NutritionFactModel.VitaminD;
        this.nutrifactSumModel.VitaminE += response.NutritionFactModel.VitaminE;
        this.nutrifactSumModel.VitaminK += response.NutritionFactModel.VitaminK;

        this.nutrifactSumModel.Folate += response.NutritionFactModel.Folate;
        this.nutrifactSumModel.Iron += response.NutritionFactModel.Iron;
        this.nutrifactSumModel.Calcium += response.NutritionFactModel.Calcium;
        this.nutrifactSumModel.Phosphorus += response.NutritionFactModel.Phosphorus;
        this.nutrifactSumModel.Mangnese += response.NutritionFactModel.Mangnese;
        this.nutrifactSumModel.Iodin += response.NutritionFactModel.Iodin;

        this.nutrifactSumModel.Biotin += response.NutritionFactModel.Biotin;
        this.nutrifactSumModel.Zinc += response.NutritionFactModel.Zinc;
        this.nutrifactSumModel.Copper += response.NutritionFactModel.Copper;
        this.nutrifactSumModel.Selenium += response.NutritionFactModel.Selenium;
        this.nutrifactSumModel.Omega3 += response.NutritionFactModel.Omega3;
        this.nutrifactSumModel.Cobalamin += response.NutritionFactModel.Cobalamin;
        this.nutrifactSumModel.Oxalate += response.NutritionFactModel.Oxalate;
        this.nutrifactSumModel.Water += response.NutritionFactModel.Water;

      });
      this.nutritionFactModel = JSON.parse(JSON.stringify(this.nutrifactSumModel));
      this.priceModel.CalculatedAmount = totalMealPrice;
    });
  }

  showNutritionfactByFoodId(foodId, quantity) {
    this.foodLoggerservice.GetFoodWithNutritionFactbyFoodId(foodId, quantity).subscribe(
      response => {
        console.log(response);
        this.nutritionFactModel = response.NutritionFactModel;
        this.foodLoggerservice.GetFoodPriceByServingSize(quantity, foodId).subscribe(
          response => {
            console.log(response);
            this.priceModel = response;
            this.calculateFoodGrade();
          },
          err => {
            this.result = err.message;
          }
        );
      },
      err => {
        this.result = err.message;
      }
    );
  }
  SevingQuanityChange(event) {
    this.foodLogModel.ServingQuantity;
    var me = event.value;
    if (this.isServingSize) {
      this.foodLogModel.Quantity = this.foodLogModel.ServingQuantity * this.nutritionFactModel.ServingSize;
    }
    else {
      this.foodLogModel.Quantity = this.foodLogModel.ServingQuantity;
    }

    this.showNutritionfactByFoodId(this.foodLogModel.FoodModel.FoodId, this.foodLogModel.Quantity);


  }
  onSubmit() {
    var isValid = true;
    if (!this.foodLogModel.MealTypeModel.MealTypeId || this.foodLogModel.MealTypeModel.MealTypeId <= 0) {
      //this.foodLogModel.ServingQuantity =0;
      this.loginForm.form.controls['foodLogModel.MealTypeModel.MealTypeId'].setErrors({ 'Required': this.isRequiredError });
      isValid = false;
    }

    var newDate = new Date(this.foodLogModel.DateDisplay.year, this.foodLogModel.DateDisplay.month - 1
      , this.foodLogModel.DateDisplay.day,
      this.foodLogModel.TimeDisplay.hour, this.foodLogModel.TimeDisplay.minute, 0, 0);
    // var newDate = moment({ y: 2017, M: 0, d: 4, h: 15, m: 10, s: 0, ms: 0 });
    this.foodLogModel.Created = newDate;
    this.foodLogModel.CalculatedPrice = this.priceModel.CalculatedAmount;

    if (this.recipeModel && this.recipeModel.RecipeDetails.length > 0) {
      this.foodLogModel.RecipeID = this.recipeModel.RecipeID;
      if (!isValid) {
        return 0;
      }

      this.foodLogMasterModel.FoodLoggerFoodLogModels = [];
      this.foodLogMasterModel.Created = newDate;
      this.foodLogMasterModel.FoodLoggerFoodLogModel = new FoodLogModel();
      this.recipeModel.RecipeDetails.forEach(element => {
        var foodLogModel = new FoodLogModel();
        foodLogModel.Quantity = element.ServingSizeValue;
        foodLogModel.FoodModel = new FoodModel();
        foodLogModel.FoodModel.FoodId = element.ProductID;
        foodLogModel.CalculatedPrice = element.CalculatedPrice;
        foodLogModel.Created = newDate;
        foodLogModel.MealTypeModel = new MealTypeModel();
        foodLogModel.MealTypeModel.MealTypeId = this.foodLogModel.MealTypeModel.MealTypeId;
        foodLogModel.RecipeID = this.foodLogModel.RecipeID;
        this.foodLogMasterModel.FoodLoggerFoodLogModels.push(foodLogModel);
        // //this.foodLoggerservice.InsertOrUpdateFoodLog(foodLogModel).subscribe(
        //   this.foodLoggerservice.InsertOrUpdateFoodLog(this.foodLogMasterModel).subscribe(
        //   response => {
        //     console.log(response);
        //     this.result = response;
        //     this.getFoodLogByDate(this.foodLogModel.Created);
        //   },
        //   err => { this.result = err.message; }
        // );
      });
      //this.foodLoggerservice.InsertOrUpdateFoodLog(foodLogModel).subscribe(
      this.foodLoggerservice.InsertOrUpdateFoodLog(this.foodLogMasterModel).subscribe(
        response => {
          console.log(response);
          this.result = response;
          this.getFoodLogByDate(this.foodLogModel.Created);
        },
        err => { this.result = err.message; }
      );
      this.getFoodLogByDate(this.foodLogModel.Created);
    }
    else {
      //this.foodLogMasterModel = new FoodLogMasterModel();
      // this.foodLogMasterModel.SummaryNotes="yahoo";
      this.foodLogMasterModel.Created = newDate;
      this.foodLogMasterModel.FoodLoggerFoodLogModel = new FoodLogModel();
      this.foodLogMasterModel.FoodLoggerFoodLogModel = this.foodLogModel;
      if (!this.foodLogModel.FoodModel.FoodId || this.foodLogModel.FoodModel.FoodId <= 0) {
        //this.foodLogModel.ServingQuantity =0;
        this.loginForm.form.controls['foodLogModel.FoodModel.FoodId'].setErrors({ 'Required': this.isRequiredError });
        isValid = false;
      }

      if (!this.foodLogModel.ServingQuantity || this.foodLogModel.ServingQuantity <= 0) {
        //this.foodLogModel.ServingQuantity =0;
        this.loginForm.form.controls['foodLogModel.ServingQuantity'].setErrors({ 'Required': this.isRequiredError });
        isValid = false;
      }
      if (!isValid) {
        return 0;

      }
      // this.foodLoggerservice.InsertOrUpdateFoodLog(this.foodLogModel).subscribe(
      this.foodLoggerservice.InsertOrUpdateFoodLog(this.foodLogMasterModel).subscribe(
        response => {
          console.log(response);
          this.result = response;
          this.getFoodLogByDate(this.foodLogModel.Created);
        },
        err => { this.result = err.message; }
      );

    }

    // this.foodLoggerservice.InsertOrUpdateFoodLog(this.foodLogModel).subscribe(
    //   response => {
    //     console.log(response);
    //     this.result = response;
    //     this.getFoodLogByDate(this.foodLogModel.Created);
    //   },
    //   err => { this.result = err.message; }
    // );

    /*Resetting meal model */
    this.recipeModel = new RecipeModel();
    this.recipeModel.RecipeDetails = [];
    this.nutritionFactListModel = [];
    this.priceListModel = [];
  }
  getFoodLogByDate(dt) {
    this.foodLoggerservice.GetFoodLogWithNutritionFactByDate(dt).subscribe(responseResult => {
      if (responseResult !== null) {

        var response = responseResult.FoodLoggerFoodLogModels;
        this.foodLogMasterModel = responseResult;
        var count = 0; var recipeId = 0;
        // logic for displaying one recepie name for all item. Added serial number for all item belongs to 
        //same recipe with in meal type like breakfast. Then use ==1 for iteeration to display recipe name. 
        var uniqueRecipeCode = responseResult.FoodLoggerFoodLogModels.map(item => item.RecipeID)
          .filter((value, index, self) => self.indexOf(value) === index)
        for (var m = 0; m < uniqueRecipeCode.length; m++) {
          if (uniqueRecipeCode[m]) {
            for (var k = 0; k < this.mealTypeModel.length; k++) {
              count = 0;
              for (var l = 0; l < responseResult.FoodLoggerFoodLogModels.length; l++) {
                if (responseResult.FoodLoggerFoodLogModels[l].MealTypeModel.MealTypeId == this.mealTypeModel[k].MealTypeId) {
                  if (responseResult.FoodLoggerFoodLogModels[l].RecipeID == uniqueRecipeCode[m]) {
                    responseResult.FoodLoggerFoodLogModels[l].RecipeSerialNo = ++count;
                  }
                }
              }
            }
          }
        }

        this.foodLogsModel = responseResult.FoodLoggerFoodLogModels;


      }
      else {
        this.foodLogsModel = [];
        //this.currentDate = new Date(dt);
        //this.foodLogModel.DateDisplay = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
        //this.foodLogModel.TimeDisplay = { hour: this.currentDate.getHours(), minute: this.currentDate.getMinutes() };
      }
      var CalciumSum = 0, FatSum = 0;
      // //Getting Total
      // for (var k = 0; k < this.foodLogProgress.length; k++) {
      //   for (var i = 0; i < response.length; i++) {
      //     if (this.foodLogProgress[k].NutritionName === "Fat") {
      //       this.foodLogProgress[k].NutritionDoneSoFar += Number(response[i].FoodModel.NutritionFactModel.TotalFat)
      //     }
      //     else if (this.foodLogProgress[k].NutritionName === "Carbohydrate") {
      //       this.foodLogProgress[k].NutritionDoneSoFar += Number(response[i].FoodModel.NutritionFactModel.TotalCabohydrate)
      //     }
      //     else if (this.foodLogProgress[k].NutritionName === "Calcium") {
      //       this.foodLogProgress[k].NutritionDoneSoFar += Number(response[i].FoodModel.NutritionFactModel.Calcium)
      //     }
      //     else if (this.foodLogProgress[k].NutritionName === "Protien") {
      //       this.foodLogProgress[k].NutritionDoneSoFar += Number(response[i].FoodModel.NutritionFactModel.Protien)
      //     }
      //     else if (this.foodLogProgress[k].NutritionName === "Calories") {
      //       this.foodLogProgress[k].NutritionDoneSoFar += Number(response[i].FoodModel.NutritionFactModel.Calories)
      //     }
      //     else if (this.foodLogProgress[k].NutritionName === "Sugar") {
      //       this.foodLogProgress[k].NutritionDoneSoFar += Number(response[i].FoodModel.NutritionFactModel.Sugar)
      //     }
      //     else if (this.foodLogProgress[k].NutritionName === "Price") {
      //       this.foodLogProgress[k].NutritionDoneSoFar += Number(response[i].FoodModel.NutritionFactModel.CalculatedPrice)
      //     }
      //   }
      //   // this.foodLogProgress[k].NutritionActualPercentage =
      //   //   ((Number(this.foodLogProgress[k].NutritionDoneSoFar)
      //   //     / Number(this.foodLogProgress[k].NutritionTotalLimit)) * 100)

      //   // this.foodLogProgress[k].NutritionCompletePercentage = this.foodLogProgress[k].NutritionActualPercentage.toFixed();
      //   // if (this.foodLogProgress[k].NutritionActualPercentage > 100) {
      //   //   this.foodLogProgress[k].NutritionCompletePercentage = "100";
      //   // }
      // }
      // Getting MealTypeTotal
      for (var i = 0; i < this.mealTypeModel.length; i++) {
        this.mealTypeModel[i].FoodLogProgress = [];
        this.mealTypeModel[i].FoodLogProgress = this.GetInitialFoodLogProcessData();
        if (response) {
          for (var j = 0; j < response.length; j++) {
            if (this.mealTypeModel[i].Name == response[j].MealTypeModel.Name) {
              // var me = new FoodLogProgress();
              // me.MealTotal=0;
              for (var k = 0; k < this.mealTypeModel[i].FoodLogProgress.length; k++) {
                if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Fat") {
                  console.log(i);
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.TotalFat)
                }
                else if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Carbohydrate") {
                  console.log(i);
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.TotalCabohydrate)
                }
                else if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Calcium") {
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.Calcium)
                }
                else if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Protien") {
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.Protien)
                }
                else if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Calories") {
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.Calories)
                }
                else if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Sugar") {
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.Sugar)
                }
                else if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Price") {
                  //this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.CalculatedPrice)
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].CalculatedPrice)
                }
                else if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Sodium") {
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.Sodium)
                }
                else if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Phosphorus") {
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.Phosphorus)
                }
                else if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Oxalate") {
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.Oxalate)
                }
                else if (this.mealTypeModel[i].FoodLogProgress[k].NutritionName === "Water") {
                  this.mealTypeModel[i].FoodLogProgress[k].MealTotal += Number(response[j].FoodModel.NutritionFactModel.Water)
                }

              }
              //console.log(response[j]);
              //console.log(this.foodLogProgress[0].MealTotal);
            }
          }
        }
      }

      for (var i = 0; i < this.foodLogProgress.length; i++) {
        this.foodLogProgress[i].NutritionDoneSoFar = 0;
        var total = this.foodLogProgress[i].NutritionDoneSoFar;
        this.mealTypeModel.forEach(mealType => {
          mealType.FoodLogProgress.forEach(foodLogProgress => {
            if (this.foodLogProgress[i].NutritionName === foodLogProgress.NutritionName) {
              this.foodLogProgress[i].NutritionDoneSoFar += foodLogProgress.MealTotal;
            }
          });

        });
        this.foodLogProgress[i].NutritionActualPercentage =
          ((Number(this.foodLogProgress[i].NutritionDoneSoFar)
            / Number(this.foodLogProgress[i].NutritionTotalLimit)) * 100)

        this.foodLogProgress[i].NutritionCompletePercentage = this.foodLogProgress[i].NutritionActualPercentage.toFixed();
        if (this.foodLogProgress[i].NutritionActualPercentage > 100) {
          this.foodLogProgress[i].NutritionCompletePercentage = "100";
        }
      }
      console.log(this.foodLogsModel);

      //console.log(this.foodLogProgress);
    },
      err => {
        this.result = err.message;
        this.resetLog();
        this.currentDate = new Date(dt);
        this.foodLogModel.DateDisplay = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
        this.foodLogModel.TimeDisplay = { hour: this.currentDate.getHours(), minute: this.currentDate.getMinutes() };

      }
    )
  }
  removeFoodLog(foodLogId) {
    var me = foodLogId;
    this.foodLoggerservice.DeleteFoodLog(foodLogId).subscribe(response => {
      this.result = response;
      this.getFoodLogByDate(this.currentDate);

    }
      , err => { this.result = err.message; }
    )

  }
  onModelChange(event) {
    //this.ref.detach();
    console.log(event);
    var newDate = new Date(this.foodLogModel.DateDisplay.year, this.foodLogModel.DateDisplay.month - 1
      , this.foodLogModel.DateDisplay.day,
      this.foodLogModel.TimeDisplay.hour, this.foodLogModel.TimeDisplay.minute, 0, 0);
    // var newDate = moment({ y: 2017, M: 0, d: 4, h: 15, m: 10, s: 0, ms: 0 });
    this.foodLogModel.Created = newDate;
    this.DateFromOutside=this.datepipe.transform(this.foodLogModel.Created, 'yyyy-MM-dd');
    this.getFoodLogByDate(this.foodLogModel.Created);
    //this.ref.detectChanges();
  }
  // SettingFoodLogProcessData() {
  //   var nutritionProgress = new FoodLogProgress();
  //   nutritionProgress.NutritiononId = 1;
  //   nutritionProgress.NutritionName = "Fat";
  //   nutritionProgress.NutritionTotalLimit = this.FatLimit;
  //   nutritionProgress.NutritionDoneSoFar = 0;
  //   nutritionProgress.MealTotal = 0;
  //   this.foodLogProgress.push(nutritionProgress);

  //   nutritionProgress = new FoodLogProgress();
  //   nutritionProgress.NutritiononId = 2;
  //   nutritionProgress.NutritionName = "Carbohydrate";
  //   nutritionProgress.NutritionTotalLimit = this.CarbohydrateLimit;
  //   nutritionProgress.NutritionDoneSoFar = 0;
  //   this.foodLogProgress.push(nutritionProgress);

  //   nutritionProgress = new FoodLogProgress();
  //   nutritionProgress.NutritiononId = 3;
  //   nutritionProgress.NutritionName = "Calcium";
  //   nutritionProgress.NutritionTotalLimit = this.CalciumLimit;
  //   nutritionProgress.NutritionDoneSoFar = 0;
  //   this.foodLogProgress.push(nutritionProgress);

  //   nutritionProgress = new FoodLogProgress();
  //   nutritionProgress.NutritiononId = 4;
  //   nutritionProgress.NutritionName = "Protien";
  //   nutritionProgress.NutritionTotalLimit = this.ProtienLimit;
  //   nutritionProgress.NutritionDoneSoFar = 0;
  //   this.foodLogProgress.push(nutritionProgress);

  // }
  GetInitialFoodLogProcessData() {
    var foodLogprocessingInit: FoodLogProgress[];
    foodLogprocessingInit = [];
    var nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 1;
    nutritionProgress.NutritionName = "Fat";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.FatLimit;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 2;
    nutritionProgress.NutritionName = "Carbohydrate";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.CarbohydrateLimit;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 3;
    nutritionProgress.NutritionName = "Calcium";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.CalciumLimit;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 4;
    nutritionProgress.NutritionName = "Protien";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.ProtienLimit;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 5;
    nutritionProgress.NutritionName = "Calories";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.CaloriesLimit;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 6;
    nutritionProgress.NutritionName = "Sugar";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.SugarLimit;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 7;
    nutritionProgress.NutritionName = "Price";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.ProtienLimit;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 8;
    nutritionProgress.NutritionName = "Sodium";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.SodiumLimit;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 9;
    nutritionProgress.NutritionName = "Phosphorus";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.PhosphorusLimit;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 10;
    nutritionProgress.NutritionName = "Oxalate";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.OxalateLimit;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    nutritionProgress = new FoodLogProgress();
    nutritionProgress.NutritiononId = 11;
    nutritionProgress.NutritionName = "Water";
    nutritionProgress.NutritionTotalLimit = this.foodLogNutritionLimitModel.WaterLimit;;
    nutritionProgress.NutritionDoneSoFar = 0;
    nutritionProgress.MealTotal = 0;
    foodLogprocessingInit.push(nutritionProgress);

    return foodLogprocessingInit;

  }
  // addToRecipe(mealTypeId) {
  //   var me = mealTypeId;
  //   this.foodLogsModel[0].FoodModel.FoodId;
  //   this.foodLogsModel[0].MealTypeModel.MealTypeId;
  //   this.foodLogsModel[0].Quantity;
  // }
  addToRecipe() {
    var isValid = true;

    if (!this.foodLogModel.FoodModel.FoodId || this.foodLogModel.FoodModel.FoodId <= 0) {
      //this.foodLogModel.ServingQuantity =0;
      this.loginForm.form.controls['foodLogModel.FoodModel.FoodId'].setErrors({ 'Required': this.isRequiredError });
      isValid = false;
    }

    if (!this.foodLogModel.ServingQuantity || this.foodLogModel.ServingQuantity <= 0) {
      //this.foodLogModel.ServingQuantity =0;
      this.loginForm.form.controls['foodLogModel.ServingQuantity'].setErrors({ 'Required': this.isRequiredError });
      isValid = false;
    }


    if (!isValid) {
      return 0;
    }
    else {
      var selectedFood = this.foodLogModel;
      //this.recipeModel = new RecipeModel();

      //this.recipeModel.RecipeDetails =[];
      //this.recipeModel.RecipeServingSize = this.recipeQuantity;
      var recipeDetails = new RecipeDetailsModel();
      recipeDetails.RecipeID = this.recipeModel.RecipeID;
      recipeDetails.ProductID = selectedFood.FoodModel.FoodId;
      recipeDetails.ServingSizeValue = selectedFood.ServingQuantity;
      recipeDetails.ProductName = selectedFood.FoodModel.Name;
      recipeDetails.CalculatedPrice = this.priceModel.CalculatedAmount;

      this.recipeModel.RecipeDetails.push(recipeDetails);
      this.nutritionFactListModel.push(this.nutritionFactModel);
      this.priceListModel.push(this.priceModel);
      this.addAllNutritionFacts();
      //this.showNutritionfactByFoodId(this.foodLogModel.FoodModel.FoodId, this.foodLogModel.ServingQuantity);
      //this.nutritionFactModel;
      // var recipeDetails  = new RecipeDetailsModel();
      // recipeDetails.RecipeID = 2;
      // recipeDetails.ProductID = 2;
      // recipeDetails.ServingSizeValue =34;
      // this.recipeModel.RecipeDetails.push(recipeDetails);
    }




  }
  RecipeQuanityChange(event) {
    var quanityRecepie = Number(event);
    //if(quanityRecepie >1)
    {
      this.nutritionFactListModel.forEach(element => {
        //this.nutritionFactModel.Calories = (me /this.recipeModel.RecipeServingSize) *this.nutritionFactModel.Calories;
      });
      for (var i = 0; i < this.recipeModel.RecipeDetails.length; i++) {
        this.recipeModel.RecipeDetails[i].ServingSizeValue = this.recipeQuantiyChangedModel.RecipeDetails[i].ServingSizeValue * quanityRecepie;
        this.recipeModel.RecipeDetails[i].CalculatedPrice = this.recipeQuantiyChangedModel.RecipeDetails[i].CalculatedPrice * quanityRecepie;
      }
      // this.recipeModel.RecipeDetails.forEach(element => {
      //   element.ServingSizeValue = element.ServingSizeValue * quanityRecepie;
      // });
      var nurtriFactUnchangedSumModel = JSON.parse(JSON.stringify(this.nutrifactSumModel)) as any;

      //Changing values according to quantity
      this.nutritionFactModel.Calories = nurtriFactUnchangedSumModel.Calories * quanityRecepie;
      this.nutritionFactModel.TotalCabohydrate = nurtriFactUnchangedSumModel.TotalCabohydrate * quanityRecepie;
      this.nutritionFactModel.Sugar = nurtriFactUnchangedSumModel.Sugar * quanityRecepie;
      this.nutritionFactModel.SaturatedFat = nurtriFactUnchangedSumModel.SaturatedFat * quanityRecepie;
      this.nutritionFactModel.Cholesterol = nurtriFactUnchangedSumModel.Cholesterol * quanityRecepie;
      this.nutritionFactModel.Sodium = nurtriFactUnchangedSumModel.Sodium * quanityRecepie;
      this.nutritionFactModel.TotalCabohydrate = nurtriFactUnchangedSumModel.TotalCabohydrate * quanityRecepie;
      this.nutritionFactModel.DietryFiber = nurtriFactUnchangedSumModel.DietryFiber * quanityRecepie;
      this.nutritionFactModel.Protien = nurtriFactUnchangedSumModel.Protien * quanityRecepie;

      this.nutritionFactModel.VitaminA = nurtriFactUnchangedSumModel.VitaminA * quanityRecepie;
      this.nutritionFactModel.VitaminB1_Thiamin = nurtriFactUnchangedSumModel.VitaminB1_Thiamin * quanityRecepie;
      this.nutritionFactModel.VitaminB2_Riboflavin = nurtriFactUnchangedSumModel.VitaminB2_Riboflavin * quanityRecepie;
      this.nutritionFactModel.VitaminB3_Niacin = nurtriFactUnchangedSumModel.VitaminB3_Niacin * quanityRecepie;
      this.nutritionFactModel.VitaminB5_PantothenicAcid = nurtriFactUnchangedSumModel.VitaminB5_PantothenicAcid * quanityRecepie;

      this.nutritionFactModel.VitaminB6 = nurtriFactUnchangedSumModel.VitaminB6 * quanityRecepie;
      this.nutritionFactModel.VitaminB12 = nurtriFactUnchangedSumModel.VitaminB12 * quanityRecepie;
      this.nutritionFactModel.VitaminC = nurtriFactUnchangedSumModel.VitaminC * quanityRecepie;
      this.nutritionFactModel.VitaminD = nurtriFactUnchangedSumModel.VitaminD * quanityRecepie;
      this.nutritionFactModel.VitaminE = nurtriFactUnchangedSumModel.VitaminE * quanityRecepie;
      this.nutritionFactModel.VitaminK = nurtriFactUnchangedSumModel.VitaminK * quanityRecepie;

      this.nutritionFactModel.Folate = nurtriFactUnchangedSumModel.Folate * quanityRecepie;
      this.nutritionFactModel.Iron = nurtriFactUnchangedSumModel.Iron * quanityRecepie;
      this.nutritionFactModel.Calcium = nurtriFactUnchangedSumModel.Calcium * quanityRecepie;
      this.nutritionFactModel.Phosphorus = nurtriFactUnchangedSumModel.Phosphorus * quanityRecepie;
      this.nutritionFactModel.Mangnese = nurtriFactUnchangedSumModel.Mangnese * quanityRecepie;
      this.nutritionFactModel.Iodin = nurtriFactUnchangedSumModel.Iodin * quanityRecepie;

      this.nutritionFactModel.Biotin = nurtriFactUnchangedSumModel.Biotin * quanityRecepie;
      this.nutritionFactModel.Zinc = nurtriFactUnchangedSumModel.Zinc * quanityRecepie;
      this.nutritionFactModel.Copper = nurtriFactUnchangedSumModel.Copper * quanityRecepie;
      this.nutritionFactModel.Selenium = nurtriFactUnchangedSumModel.Selenium * quanityRecepie;
      this.nutritionFactModel.Omega3 = nurtriFactUnchangedSumModel.Omega3 * quanityRecepie;
      this.nutritionFactModel.Cobalamin = nurtriFactUnchangedSumModel.Cobalamin * quanityRecepie;
      this.nutritionFactModel.Oxalate = nurtriFactUnchangedSumModel.Oxalate * quanityRecepie;
      this.nutritionFactModel.Water = nurtriFactUnchangedSumModel.Water * quanityRecepie;
    }
  }
  addAllNutritionFacts() {
    this.nutritionFactModel = new NutritionFactModel();
    this.nutritionFactListModel.forEach(element => {


      this.nutritionFactModel.Calories += element.Calories;
      this.nutritionFactModel.TotalFat += element.TotalFat;

      this.nutritionFactModel.SaturatedFat += element.SaturatedFat;
      this.nutritionFactModel.Cholesterol += element.Cholesterol;
      this.nutritionFactModel.Sodium += element.Sodium;
      this.nutritionFactModel.TotalCabohydrate += element.TotalCabohydrate;
      this.nutritionFactModel.DietryFiber += element.DietryFiber;
      this.nutritionFactModel.Sugar += element.Sugar;


      this.nutritionFactModel.Protien += element.Protien;
      this.nutritionFactModel.VitaminA += element.VitaminA;
      this.nutritionFactModel.VitaminB1_Thiamin += element.VitaminB1_Thiamin;
      this.nutritionFactModel.VitaminB2_Riboflavin += element.VitaminB2_Riboflavin;
      this.nutritionFactModel.VitaminB3_Niacin += element.VitaminB3_Niacin;
      this.nutritionFactModel.VitaminB5_PantothenicAcid += element.VitaminB5_PantothenicAcid;

      this.nutritionFactModel.VitaminB6 += element.VitaminB6;
      this.nutritionFactModel.VitaminB12 += element.VitaminB12;
      this.nutritionFactModel.VitaminC += element.VitaminC;
      this.nutritionFactModel.VitaminD += element.VitaminD;
      this.nutritionFactModel.VitaminE += element.VitaminE;
      this.nutritionFactModel.VitaminK += element.VitaminK;

      this.nutritionFactModel.Folate += element.Folate;
      this.nutritionFactModel.Iron += element.Iron;
      this.nutritionFactModel.Calcium += element.Calcium;
      this.nutritionFactModel.Phosphorus += element.Phosphorus;
      this.nutritionFactModel.Mangnese += element.Mangnese;
      this.nutritionFactModel.Iodin += element.Iodin;

      this.nutritionFactModel.Biotin += element.Biotin;
      this.nutritionFactModel.Zinc += element.Zinc;
      this.nutritionFactModel.Copper += element.Copper;
      this.nutritionFactModel.Selenium += element.Selenium;
      this.nutritionFactModel.Omega3 += element.Omega3;
      this.nutritionFactModel.Cobalamin += element.Cobalamin;
      this.nutritionFactModel.Oxalate += element.Oxalate;
      this.nutritionFactModel.Water += element.Water;

    });
    this.priceModel = new FoodLogPriceModel();
    this.priceListModel.forEach(element => {
      this.priceModel.CalculatedAmount += element.CalculatedAmount;
    });


  }
  saveRecipe() {
    var isValid = true;

    if (!this.recipeModel.RecipeName || this.recipeModel.RecipeName.length <= 0) {
      //this.foodLogModel.ServingQuantity =0;
      this.loginForm.form.controls['recipeModel.RecipeName'].setErrors({ 'Required': this.isRequiredError });
      isValid = false;
    }
    if (!this.recipeModel.RecipeServingSize || this.recipeModel.RecipeServingSize <= 0) {
      //this.foodLogModel.ServingQuantity =0;
      this.loginForm.form.controls['recipeModel.RecipeServingSize'].setErrors({ 'Required': this.isRequiredError });
      isValid = false;
    }
    if (!this.recipeModel.Narration || this.recipeModel.Narration.length > 4500) {
      //this.foodLogModel.ServingQuantity =0;
      this.loginForm.form.controls['recipeModel.Narration'].setErrors({ 'MaxLength': this.isExceedsMaxLengthError+':4500' });
      isValid = false;
    }
    if (!isValid) {
      return 0;
    }
    this.recipeModel.RecipeList = [];
    this.foodLoggerservice.InsertOrUpdateRecipe(this.recipeModel).subscribe(response => {
      this.result = response;
    }
      , err => { this.result = err.message; }
    );
  }
  removeRecipeItem(doc) {
    this.recipeModel.RecipeDetails.forEach((item, index) => {
      if (item.RecipeDetailsID === doc) this.recipeModel.RecipeDetails.splice(index, 1);
    });
    this.foodLoggerservice.DeleteRecipeDetailsById(doc).subscribe(response => {
      this.result = response;
    }
      , err => { this.result = err.message; }
    );
  }

  showText() {
    this.isReadMore = !this.isReadMore

    this.foodLoggerservice.GetRecipeById(12).subscribe(response => {
      var result1 = response;
      this.recipeModel = response;
    }
      , err => { this.result = err.message; }
    );
  }
  calculateFoodGrade() {
    if (this.nutritionFactModel.Sugar > 25) {
      this.foodSugarGrade = "D+";
    }
    else if (this.nutritionFactModel.Sugar > 15 && this.nutritionFactModel.Sugar <= 25) {
      this.foodSugarGrade = "C+";
    }
    else if (this.nutritionFactModel.Sugar > 7 && this.nutritionFactModel.Sugar <= 15) {
      this.foodSugarGrade = "B";
    }
    else {
      this.foodSugarGrade = "A";
    }
  }
  isRecipeAllowedToShow(mealTypeId, recipeId) {
    this.recipeCounter++;
    if (this.recipeCounter > 4) { return 0; }
    var flag = true;
    for (let i = 0; i < this.RecipieAddded.length; i++) {
      if (this.RecipieAddded[i].mealTypeId === mealTypeId && this.RecipieAddded[i].recipeId === recipeId) {
        return false;
      }
    }
    this.RecipieAddded.push({ "mealTypeId": mealTypeId, "recipeId": recipeId });
    return true;
  }
  onOptionsSelected(mealTypeId: string) {
    console.log("the selected value is " + mealTypeId);
    this.foodLoggerservice.GetRecentProductByMeal(Number(mealTypeId), 10).subscribe(response => {
      this.recentLogFoods = response;
    });
    this.foodLoggerservice.GetRecentRecpieByMeal(Number(mealTypeId), 10).subscribe(response => {
      this.recentLogReciepe = response;
    });
  }
  selectedRecentLogFoods(ProductID, ProductName) {
    //this.transactionModel.AccountCode=accCode;
    //this.transactionModel.AccountName=accName;
    this.foodLogModel.FoodModel.FoodId = ProductID;
    this.foodLogModel.FoodModel.Name = ProductName;
    //this.foodLogModel.FoodModel.FoodId = item.item.FoodId;
    //this.foodLogModel.FoodModel.Name = item.item.Name;
    this.showNutritionfactByFoodId(this.foodLogModel.FoodModel.FoodId, 100);
  }
  selectedRecentLogReciepe(RecipeID) {
    //this.transactionModel.AccountCode=accCode;
    ///this.transactionModel.AccountName=accName;RecipeDetails
    this.recentLogReciepe.forEach(element => {
      if (element.RecipeID == RecipeID) {
        this.recipeModel = JSON.parse(JSON.stringify(element));
      }
    });
    let observableBatch = [];
      let priceListModel = [];
      this.nutrifactSumModel = new NutritionFactModel();
      this.recipeModel.RecipeDetails.forEach(element => {

        priceListModel.push({ "ProductID": element.ProductID, "Price": element.CalculatedPrice });
        observableBatch.push(this.foodLoggerservice.GetFoodWithNutritionFactbyFoodId(element.ProductID, element.ServingSizeValue));
      });
    this.updateNutritionFactforRecipe(observableBatch,priceListModel);
  }
  showFoodImage(foodId){
    this.foodLoggerservice.GetAllFoodFiles(foodId)
    .subscribe((baseImage: any) => {

      //alert(JSON.stringify(data.image));
      let objectURL = 'data:image/jpeg;base64,' + baseImage.Image;
      // this.foodImageModel = baseImage.Image as FoodImageModel[];
      //this.foodImageModel =[];
      let imagesFood = [];
      baseImage.Image.forEach(element => {
        let foodImgModel = new FoodImageModel();
        foodImgModel.FoodImageId = element.FoodImageId;
        foodImgModel.Name = element.Name;
        foodImgModel.Path = element.Path;
        foodImgModel.ImageByteData = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + element.ImageByteData);
        this.foodImageModel.push(foodImgModel);
        imagesFood.push(this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + element));
      });
      //this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(imagesFood);
      this.thumbnail = imagesFood;
    });

  }

}
