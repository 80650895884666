import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { AccountModel } from 'src/app/models/ExpenseManager/Accounts/account-model';
import { AccountApiModel } from 'src/app/models/ExpenseManager/Accounts/account-api-model';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { AccountMasterModel } from 'src/app/models/ExpenseManager/Accounts/account-master-model';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  public result = '';
  accountModel: AccountModel;
  model: any;
  searching = false;
  searchFailed = false;
  public accountMasterModels: AccountMasterModel[];
  public cbOther=[];
  public accountStatus=[];
  constructor(private expenseManagerService: ExpenseManagerService,private titleService:Title) {
    this.titleService.setTitle("Account Manager");
  }

  ngOnInit() {
    this.accountMasterModels = [];
    this.cbOther=[]
    this.cbOther.push({"Text":'Cash',"Value":"C"});
    this.cbOther.push({"Text":'Bank',"Value":"B"});
    this.cbOther.push({"Text":'Other',"Value":"O"});

    this.accountStatus=[]
    this.accountStatus.push({"Text":'Inoperative',"Value":"Inoperative"});
    this.accountStatus.push({"Text":'Operative',"Value":"Operative"});
    this.accountStatus.push({"Text":'Other',"Value":"Other"});
    
    this.accountModel = new AccountModel();
    this.expenseManagerService.GetAccountsMasterByName('').subscribe(response => {
      var me = response;
      this.accountMasterModels = response;
    });
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetAccountByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { AccountName: string }) => x.AccountName;

  selectedItem(item) {
    //this.clickedItem=item.item;
    console.log(item);
    var accountApiModel = new AccountApiModel();
    this.accountModel.AccountID = item.item.AccountID;
    this.accountModel.AccountCode = item.item.AccountCode;
    this.accountModel.AccountName = item.item.AccountName;

    this.accountModel.AccountMasterID = item.item.AccountMasterID;
    this.accountModel.AccountMasterName = item.item.AccountMasterName;
    this.accountModel.CashBankOther = item.item.CashBankOther;
    this.accountModel.AccountStatus = item.item.AccountStatus;
    this.accountModel.Receipt = item.item.Receipt;
    this.accountModel.Active = item.item.Active;
    this.accountModel.AccountAutoID = item.item.AccountAutoID;
  }

  /*Account Master Auto complete */
  searchAccountMaster = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetAccountsMasterByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatterAccountMaster = (x: { AccountMasterName: string }) => x.AccountMasterName;

  selectedItemAccountMaster(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.accountModel.AccountMasterID = item.item.AccountMasterID;
    this.accountModel.AccountMasterName = item.item.AccountMasterName;
  }

  onSubmit() {
    var me = this.accountModel;
    var accountApiModel = new AccountApiModel();
    accountApiModel.AccountID = me.AccountID;
    accountApiModel.AccountCode = me.AccountCode;
    accountApiModel.AccountName = me.AccountName;

    accountApiModel.AccountMasterID = me.AccountMasterID;
    accountApiModel.AccountMasterName = me.AccountMasterName;
    accountApiModel.CashBankOther = me.CashBankOther;
    accountApiModel.AccountStatus = me.AccountStatus;
    accountApiModel.Receipt = me.Receipt;
    accountApiModel.Active = me.Active;
    accountApiModel.AccountAutoID = me.AccountAutoID;
    this.expenseManagerService.SaveOrUpdateAccount(accountApiModel).subscribe(
      response => {
        this.result = response.response;
        console.log(response);
        this.accountModel = new AccountModel();
      }
    );
  }
  public onOptionsSelected(event) {
    const value = event.target.value;
   this.accountModel.AccountMasterName = event.target.options[event.target.options.selectedIndex].text;
    //this. = value;
    console.log(value);
 }
 public onCorBptionsSelected(event) {
  this.accountModel.CashBankOther = event.target.value;
 var me = event.target.options[event.target.options.selectedIndex].text;
  //this. = value;
  //console.log(value);
}
public onAccountStatusSelected(event) {
  this.accountModel.AccountStatus = event.target.value;
 //var me = event.target.options[event.target.options.selectedIndex].text;
  //this. = value;
  //console.log(value);
}

}
