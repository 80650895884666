import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { AccountBalanceModel } from 'src/app/models/ExpenseManager/Accounts/account-balance-model';
import { AccountModel } from 'src/app/models/ExpenseManager/Accounts/account-model';
import { IncomeExpenditureModel } from 'src/app/models/ExpenseManager/Accounts/income-expenditure-model';
//import { ExpenseManagerService } from 'src/app/service/expense-manager.service';
import { ProductDetails } from 'src/app/models/ExpenseManager/Product/product-details';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
  productDetails: ProductDetails;
  searching = false;
  searchFailed = false;
  resultResponse = '';
  accountResultResponse = '';
  accountBalance = 0;
  accountBalanceModel: AccountBalanceModel;
  incomeAccountFromDbModels: IncomeExpenditureModel[];
  expenditureAccountFromDbModels: IncomeExpenditureModel[];

  assetsAccountFromDbModels: IncomeExpenditureModel[];
  liabilitiesAccountFromDbModels: IncomeExpenditureModel[];

  isOpeningBalExist = true;
  isMonthWise = false;
  isShowIncomeExpenditure = false;
  isShowAssetsLiabilities = false;
  isShowExpenditureIncomeAc = false;
  isShowIncomeExpenditureBalSheet = false;
  isRetainFilter = false;
  isMomOnly = false;
  isPrashantOnly = false;
  isFdOnly = false;
  isRdOnly = false;
  year = (((new Date()).getMonth() + 1) > 0 && ((new Date()).getMonth() + 1) < 4) ? (new Date()).getFullYear() - 1 : (new Date()).getFullYear();
  month = (new Date()).getMonth() + 1;
  monthFilter=0;
  accountBalanceModelList: AccountBalanceModel[];
  incomeExpenditureModelList: IncomeExpenditureModel[];
  exependitureModelList: IncomeExpenditureModel[];
  exependitureModelListWithSummary: IncomeExpenditureModel[];
  incomeModelList: IncomeExpenditureModel[];
  summaryAmount: any[];
  public totalIncomeSummary = 0;
  public totalExpenditureSummary = 0;
  //assets and liabilities
  public totalAssetsSummary = 0;
  public totalLiabilitesSummary = 0;
  //expendition and income a/c
  public totalExpenditureAcSummary = 0;
  public totalIncomeAcSummary = 0;
  assetsLiabilitesModelList: AccountBalanceModel[];
  assetsLiabilitesUntouchedModelList: AccountBalanceModel[];
  liabilitesModelList: IncomeExpenditureModel[];
  liabilitesModelListWithSummary: IncomeExpenditureModel[];
  assetsModelList: IncomeExpenditureModel[];
  public checkedSelectionTotal = 0;
  constructor(private expenseManagerService: ExpenseManagerService, private route: ActivatedRoute, private titleService: Title) { this.titleService.setTitle("Product/Account Details"); }

  ngOnInit() {
    this.monthFilter=0;
    //this.productDetails.ProductIDAutoComplete=any;
    var searchTerm = this.route.snapshot.queryParamMap.get('year');
    if (searchTerm != null && searchTerm.length > 0) {
      this.year = Number(searchTerm);
    }
    this.GetIncomeExpenditure(this.year);
    this.GetAssetsLiabilities(this.year);

  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetProductByName1(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { ProductName: string }) => x.ProductName;

  selectedItemProduct(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.expenseManagerService.GetProductCurrentValue(item.item.ProductID).subscribe(model => {
      this.resultResponse = model.response;
      console.log(model);
    });
    //this.transactionModel.ProductID = item.item.ProductID;
    //this.transactionModel.ProductName = item.item.ProductName;
  }

  searchAccCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetAccountCurrentBalanceByYear(this.year, term, this.isOpeningBalExist, this.isMonthWise).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatterAccCode = (x: { AccountCode: string }) => x.AccountCode;

  selectedItemAccCode(item) {
    //this.clickedItem=item.item;
    console.log(item);
    //this.expenseManagerService.getacc(item.item.ProductID).subscribe(model => {

    this.accountBalance = item.item.BalAmount;
    this.accountResultResponse = 'Current balance in ' + item.item.AccountCode + ' account is ';
    //console.log(model);
    //});
    //this.transactionModel.ProductID = item.item.ProductID;
    //this.transactionModel.ProductName = item.item.ProductName;

  }
  getAccountStatementMonthWise() {
    this.expenseManagerService.GetAccountCurrentBalanceByYear(this.year, '', this.isOpeningBalExist, true).subscribe(
      models => {
        this.accountBalanceModelList = models;
      }
    );
  }
  GetIncomeExpenditure(year) {
    this.accountBalanceModelList = [];
    this.productDetails = new ProductDetails();
    this.accountBalanceModel = new AccountBalanceModel();
    // this.getAccountStatementMonthWise();
    this.incomeExpenditureModelList = [];
    this.exependitureModelList = [];
    this.incomeModelList = [];
    this.summaryAmount = [];
    this.incomeAccountFromDbModels = [];
    this.expenditureAccountFromDbModels = [];
    // Getting item according to fy
    var fyStartMonth = 4;
    if (this.month >= 1 && this.month <= 3) {

    }
    this.expenseManagerService.GetIncomeExpenditure().subscribe(
      models => {
        this.incomeExpenditureModelList = models;


        //Filtering only given year transaction
        this.incomeExpenditureModelList = this.incomeExpenditureModelList.filter(
          function (e) {
            return (e.Year === year && (e.Month > 3 && e.Month <= 12))
              || (e.Year === (year + 1) && (e.Month >= 1 && e.Month <= 3));
          }
        );
        //Month filter section
        if(this.monthFilter>0){
          var month=this.monthFilter;
          this.incomeExpenditureModelList = this.incomeExpenditureModelList.filter(
            function (e) {
              return (e.Month === Number(month));
            }
          );
        }
          

        /* Expenditure Filtering accordig to yr */
        // this.exependitureModelList = this.incomeExpenditureModelList.filter(
        //   function (e) {
        //     return e.IncomeExpenditure === "Expenditure" && e.Year === year;
        //   }
        // );
        // this.incomeModelList = this.incomeExpenditureModelList.filter(
        //   function (e) {
        //     return e.IncomeExpenditure === "Income" && e.Year === year;
        //   }
        // );
        // if Jan feb march
        if (this.month >= 1 || this.month <= 3) {
          this.exependitureModelList = this.incomeExpenditureModelList.filter(
            function (e) {
              return (e.IncomeExpenditure === "Expenditure"
                // && ((e.Year === year &&(e.Month>=1 || e.Month<=3))
                // || (e.Year === year-1 &&(e.Month>=4 || e.Month<=12))
                // )
              );
            }
          );
          this.incomeModelList = this.incomeExpenditureModelList.filter(
            function (e) {
              return (e.IncomeExpenditure === "Income"
                // && ((e.Year === year &&(e.Month>=1 || e.Month<=3))
                // || (e.Year === year-1 &&(e.Month>=4 || e.Month<=12)))

              );
            }
          );
        }
        else {
          this.exependitureModelList = this.incomeExpenditureModelList.filter(
            function (e) {
              return (e.IncomeExpenditure === "Expenditure"
                // && ((e.Year === year+1 &&(this.month>=1 || this.month<=3))
                // || (e.Year === year &&(this.month>=4 || this.month<=12)))
              );
            }
          );
          this.incomeModelList = this.incomeExpenditureModelList.filter(
            function (e) {
              return (e.IncomeExpenditure === "Income"
                // && ((e.Year === year+1 &&(this.month>=1 || this.month<=3))
                // || (e.Year === year &&(this.month>=4 || this.month<=12)))
              );
            }
          );
        }
        //unique code

        var localExpenditureAccountFromDbModels = [...new Map(this.exependitureModelList.map(item =>
          [item['AccountCode'], item])).values()];
        var localIncomeAccountFromDbModels = [...new Map(this.incomeModelList.map(item =>
          [item['AccountCode'], item])).values()];

        for (var expCodeObj of localExpenditureAccountFromDbModels) {
          var objNew = new IncomeExpenditureModel();
          objNew.AccountCode = expCodeObj.AccountCode;
          objNew.AccountName = expCodeObj.AccountName;
          objNew.BalAmount = 0;
          this.expenditureAccountFromDbModels.push(objNew);
        }
        for (var incCodeObj of localIncomeAccountFromDbModels) {
          var objNewIncome = new IncomeExpenditureModel();
          objNewIncome.AccountCode = incCodeObj.AccountCode;
          objNewIncome.AccountName = incCodeObj.AccountName;
          objNewIncome.BalAmount = 0;
          this.incomeAccountFromDbModels.push(objNewIncome);
        }

        var uniqueExpCode = this.exependitureModelList.map(item => item.AccountCode)
          .filter((value, index, self) => self.indexOf(value) === index)
        var uniqueYearExpCode = this.exependitureModelList.map(item => item.Year)
          .filter((value, index, self) => self.indexOf(value) === index)

        var uniqueMonthExpCode = this.exependitureModelList.map(item => item.Month)
          .filter((value, index, self) => self.indexOf(value) === index)

        /*Income section */
        var uniqueIncomeCode = this.incomeModelList.map(item => item.AccountCode)
          .filter((value, index, self) => self.indexOf(value) === index)
        var uniqueYearIncomeCode = this.incomeModelList.map(item => item.Year)
          .filter((value, index, self) => self.indexOf(value) === index)

        var uniqueMonthIncomeCode = this.incomeModelList.map(item => item.Month)
          .filter((value, index, self) => self.indexOf(value) === index)
        //Creating summary total for expenses by month in different collection

        for (var expCode of uniqueExpCode) {
          /*Debugging */
       
          for (var yearItem of uniqueYearExpCode) {
            for (var monthItem of uniqueMonthExpCode) {
              var sumAmount = 0; var monthName = '';
              for (var expItem of this.exependitureModelList) {
                if (expCode === expItem.AccountCode && monthItem === expItem.Month && yearItem == expItem.Year) {
                  sumAmount += expItem.BalAmount;
                  monthName = expItem.MonthName;
                }
              }
              if(expCode =='EXP1' && monthName == 'June')
              {
            console.log(expCode);
              }
              
              if(sumAmount<=0)
              {
                console.log(expCode);
              }
              /*Debugging */
              //if (sumAmount > 0) 
              {
                this.summaryAmount.push({ 'acccode': expCode, 'year': yearItem, 'month': monthItem, 'total': sumAmount, 'monthName': monthName });
              }
            }
          }
        }
        // Adding summary collection after each month record
        for (var summaryItem of this.summaryAmount) {
          var flag = 0; var count = 0; var index = 0;
          for (var expItem of this.exependitureModelList) {
            count++;
            if (expItem.AccountCode == summaryItem.acccode
              && expItem.Year == summaryItem.year && expItem.Month == summaryItem.month) {
              flag = 1;
              index = count;
            }
          }
          if (flag == 1) {
            if (summaryItem.acccode == 'FE1' && summaryItem.month == 10) {
              console.log(summaryItem);
            }
            var obj1 = new IncomeExpenditureModel();
            obj1.AccountCode = summaryItem.acccode;
            obj1.AccountName = "Summary";
            obj1.BalAmount = summaryItem.total;
            obj1.Year = summaryItem.year;
            obj1.ClassStyled = "summaryrow";
            obj1.MonthName = summaryItem.monthName;
            //obj1.VendorId=1000;
            this.exependitureModelList.splice(index, 0, obj1);
            flag = 0;

          }
        }
        //Calculating total for year
        var totalSummary = 0, index = 0;
        for (var expCode of uniqueExpCode) {
          totalSummary = 0; index = 0;
          for (var item of this.exependitureModelList) {
            index++;
            if (item.AccountName == 'Summary' && expCode == item.AccountCode) {
              totalSummary += item.BalAmount;
            }
          }
          var obj1 = new IncomeExpenditureModel();
          obj1.AccountCode = expCode;
          obj1.AccountName = "Total Yearly Summary";
          obj1.BalAmount = totalSummary;
          obj1.ClassStyled = "yearly-summaryrow";
          this.exependitureModelList.splice(index + 1, 0, obj1);
          //setting summary amount on main
          var objIndex = this.expenditureAccountFromDbModels.findIndex((obj => obj.AccountCode == expCode));
          this.expenditureAccountFromDbModels[objIndex].BalAmount = totalSummary;
        }

        //Calculating total for year including all account head
        var totalYearlySummary = 0, index = 0;
        for (var item of this.exependitureModelList) {
          index++;
          if (item.AccountName == 'Total Yearly Summary') {
            totalYearlySummary += item.BalAmount;
          }
        }
        var obj1 = new IncomeExpenditureModel();
        obj1.AccountCode = 'TTLSum';
        obj1.AccountName = "Total All Expense Account Yearly Summary";
        obj1.BalAmount = totalYearlySummary;
        this.exependitureModelList.splice(index + 1, 0, obj1);
        // For checkbox sum
        this.expenditureAccountFromDbModels.forEach(
          list => {
            list.IsCheckedForTotal = false;
          }
        );

        var expModel = new IncomeExpenditureModel();
        expModel.AccountCode = 'TTLSum';
        expModel.AccountName = 'Total All Expense Account Yearly Summary';
        expModel.BalAmount = totalYearlySummary;
        this.expenditureAccountFromDbModels.push(expModel);

        /* Income summary section starts*/
        //Creating summary total by month in different collection
        this.summaryAmount = [];
        for (var expCode of uniqueIncomeCode) {
          for (var yearItem of uniqueYearIncomeCode) {
            for (var monthItem of uniqueMonthIncomeCode) {
              var sumAmount = 0;
              for (var expItem of this.incomeModelList) {
                if (expItem.BalAmount < 0) {
                  expItem.BalAmount = (-1) * expItem.BalAmount;
                }
                if (expCode === expItem.AccountCode && monthItem === expItem.Month && yearItem == expItem.Year) {
                  // sumAmount += (-1) * expItem.BalAmount;
                  sumAmount += expItem.BalAmount;
                }
              }
              /*Debugging */
             // if (sumAmount > 0) 
              {
                this.summaryAmount.push({ 'acccode': expCode, 'year': yearItem, 'month': monthItem, 'total': sumAmount, 'monthName': monthName });
              }
            }
          }
        }
        // Adding summary collection income after each month record
        for (var summaryItem of this.summaryAmount) {
          var flag = 0; var count = 0; var index = 0;
          for (var expItem of this.incomeModelList) {
            count++;
            if (expItem.AccountCode === summaryItem.acccode
              && expItem.Year === summaryItem.year && expItem.Month === summaryItem.month) {
              flag = 1;
              index = count;
            }
          }
          if (flag == 1) {
            var obj1 = new IncomeExpenditureModel();
            obj1.AccountCode = summaryItem.acccode;
            obj1.AccountName = "Summary";
            obj1.Month=summaryItem.month;
            obj1.MonthName = summaryItem.monthName;
            obj1.BalAmount = summaryItem.total;
            this.incomeModelList.splice(index, 0, obj1);
            flag = 0;
          }
        }
        //Calculating total for year
        var totalSummary = 0, index = 0;
        for (var expCode of uniqueIncomeCode) {
          totalSummary = 0; index = 0;
          for (var item of this.incomeModelList) {
            index++;
            if (item.AccountName == 'Summary' && expCode == item.AccountCode) {
              totalSummary += item.BalAmount;
            }
          }
          var obj1 = new IncomeExpenditureModel();
          obj1.AccountCode = expCode;
          obj1.AccountName = "Total Yearly Summary";
          obj1.BalAmount = totalSummary;
          this.incomeModelList.splice(index + 1, 0, obj1);

          //setting summary amount on main
          var objIndex = this.incomeAccountFromDbModels.findIndex((obj => obj.AccountCode == expCode));
          this.incomeAccountFromDbModels[objIndex].BalAmount = totalSummary;
        }
        //Calculating total income for year including all account head
        var totalSummary = 0, index = 0;
        for (var item of this.incomeModelList) {
          index++;
          if (item.AccountName == 'Total Yearly Summary') {
            totalSummary += item.BalAmount;
          }
        }
        var obj1 = new IncomeExpenditureModel();
        obj1.AccountCode = 'TTLSum';
        obj1.AccountName = "Total All Income Account Yearly Summary";
        obj1.BalAmount = totalSummary;
        this.incomeModelList.splice(index + 1, 0, obj1);

        var expModel = new IncomeExpenditureModel();
        expModel.AccountCode = 'TTLSum';
        expModel.AccountName = 'Total All Income Account Yearly Summary';
        expModel.BalAmount = totalSummary;
        this.incomeAccountFromDbModels.push(expModel);

        this.totalIncomeSummary = this.incomeAccountFromDbModels[this.incomeAccountFromDbModels.length - 1].BalAmount;
        this.totalExpenditureSummary = this.expenditureAccountFromDbModels[this.expenditureAccountFromDbModels.length - 1].BalAmount;

      }
    );
  }
  GetAssetsLiabilities(year) {

    this.assetsLiabilitesModelList = [];
    this.assetsLiabilitesUntouchedModelList = [];
    this.expenseManagerService.GetAllAccountCurrentBalByYearWithAccMaster(year).subscribe(
      models => {
        this.assetsLiabilitesUntouchedModelList = models;
        this.assetsLiabilitesModelList = models;

        /*For checkbox total */
        this.assetsLiabilitesModelList.forEach(
          list => {
            list.IsCheckedForTotal = false;

          }

        );
        var index = 0; this.totalAssetsSummary = 0; this.totalLiabilitesSummary = 0;
        this.totalExpenditureAcSummary = 0; this.totalIncomeAcSummary = 0;
        for (var item of this.assetsLiabilitesModelList) {
          index++;
          if (item.AccountMasterName == 'Assets') {
            this.totalAssetsSummary += item.BalAmount;
          }
          if (item.AccountMasterName == 'Liabilities') {
            this.totalLiabilitesSummary += item.BalAmount;
          }
          if (item.AccountMasterName == 'Expenditure') {
            this.totalExpenditureAcSummary += item.BalAmount;
          }
          if (item.AccountMasterName == 'Income') {
            /*For showing Positive values */
            item.BalAmount = item.BalAmount *-1;
            this.totalIncomeAcSummary += item.BalAmount;
          }
        }
      }

    );
  }
  GetLedgerByAccountCode(year, month, acccode) {
    this.expenseManagerService.GetLedgerByAccountCode(year, month, acccode).subscribe(
      models => {
        this.accountBalanceModelList = models;
        var debitTotal = 0, creditTotal = 0;
        for (var item of this.accountBalanceModelList) {
          debitTotal += (<any>item).Debit;
          creditTotal += (<any>item).Credit;
        }
        //Total summary
        var expModel = { "AccountName": "Summary", "Credit": creditTotal, "Debit": debitTotal, "Products": (debitTotal - creditTotal) };
        this.accountBalanceModelList.push(<any>expModel);
        //expModel.AccountName ="Total";
        //expModel
      }

    );
  }
  onYearChange() {
    this.GetIncomeExpenditure(Number(this.year));
    this.GetAssetsLiabilities(Number(this.year));
  }
  showHideFilter(choice) {
    if (choice === 'AssetsLiabilities') {
      if (this.isShowAssetsLiabilities) {
        this.isShowExpenditureIncomeAc = false;
      }
    }
    else if (choice === 'ExpenditureIncomeAc') {
      if (this.isShowExpenditureIncomeAc) {
        this.isShowAssetsLiabilities = false;
      }
    }
    // if (this.isShowAssetsLiabilities) {
    //   this.isShowExpenditureIncomeAc = false;
    // }
    // if (this.isShowExpenditureIncomeAc) {
    //   this.isShowAssetsLiabilities = false;
    // }
  }
  filterAssetsLiabilities(choice) {
    if (!this.isRetainFilter) {
      this.assetsLiabilitesModelList = this.assetsLiabilitesUntouchedModelList;
    }

    var models = this.assetsLiabilitesModelList;
    if (choice === 'mom' && this.isMomOnly) {
      if (this.isShowAssetsLiabilities) {
        models = this.assetsLiabilitesModelList.filter(
          function (e) {
            return e.AccountCode.toLowerCase().indexOf('mom') >= 0;
          }
        );
      }
      else if (this.isShowExpenditureIncomeAc) {
        models = this.assetsLiabilitesModelList.filter(
          function (e) {
            return (e.AccountCode.toLowerCase().indexOf('mom') >= 0
              || e.AccountCode.toLowerCase().indexOf('rent1') >= 0
              || e.AccountCode.toLowerCase().indexOf('pension') >= 0
            );
          }
        );
      }

    }
    else if (choice === 'me' && this.isPrashantOnly) {
      if (this.isShowAssetsLiabilities) {
        models = this.assetsLiabilitesModelList.filter(
          function (e) {
            return e.AccountCode.toLowerCase().indexOf('mom') < 0;
          }
        );
      }
      else if (this.isShowExpenditureIncomeAc) {
        models = this.assetsLiabilitesModelList.filter(
          function (e) {
            return (e.AccountCode.toLowerCase().indexOf('mom') < 0
              && e.AccountCode.toLowerCase().indexOf('rent1') < 0
              && e.AccountCode.toLowerCase().indexOf('pension') < 0
            );
          }
        );
      }
    }
    if (choice === 'fd' && this.isFdOnly) {
      models = this.assetsLiabilitesModelList.filter(
        function (e) {
          return e.AccountCode.toLowerCase().indexOf('fd1') >= 0 || e.AccountCode.toLowerCase().indexOf('fdac1') >= 0;
        }
      );
    }
    if (choice === 'rd' && this.isRdOnly) {
      models = this.assetsLiabilitesModelList.filter(
        function (e) {
          return e.AccountCode.toLowerCase().indexOf('rd1') >= 0;
        }
      );
    }
    this.assetsLiabilitesModelList = models;

    var index = 0; this.totalAssetsSummary = 0; this.totalLiabilitesSummary = 0;
    this.totalExpenditureAcSummary = 0; this.totalIncomeAcSummary = 0;
    for (var item of this.assetsLiabilitesModelList) {
      index++;
      if (item.AccountMasterName == 'Assets') {
        this.totalAssetsSummary += item.BalAmount;
      }
      if (item.AccountMasterName == 'Liabilities') {
        this.totalLiabilitesSummary += item.BalAmount;
      }
      if (item.AccountMasterName == 'Expenditure') {
        this.totalExpenditureAcSummary += item.BalAmount;
      }
      if (item.AccountMasterName == 'Income') {
        this.totalIncomeAcSummary += item.BalAmount;
      }
    }

  }
  checkedTotal(sectionName) {
    this.checkedSelectionTotal = 0;
    if (sectionName === 'expenditure') {
      var me = this.expenditureAccountFromDbModels;
      this.checkedSelectionTotal = this.expenditureAccountFromDbModels.filter(
        function (e) {
          return e.IsCheckedForTotal == true;
        }
      ).reduce((sum, current) => sum + current.BalAmount, 0);
    }
    else if (sectionName === 'income') {
      var me = this.incomeAccountFromDbModels;
      this.checkedSelectionTotal = this.incomeAccountFromDbModels.filter(
        function (e) {
          return e.IsCheckedForTotal == true;
        }
      ).reduce((sum, current) => sum + current.BalAmount, 0);
    }
    else if (sectionName === 'assetscommon') {
      var me1 = this.assetsLiabilitesModelList;
      this.checkedSelectionTotal = this.assetsLiabilitesModelList.filter(
        function (e) {
          return e.IsCheckedForTotal == true && e.AccountMasterName == 'Assets';
        }
      ).reduce((sum, current) => sum + current.BalAmount, 0);
    }
    else if (sectionName === 'expenditurecommon') {
      var me1 = this.assetsLiabilitesModelList;
      this.checkedSelectionTotal = this.assetsLiabilitesModelList.filter(
        function (e) {
          return e.IsCheckedForTotal == true && e.AccountMasterName == 'Expenditure';
        }
      ).reduce((sum, current) => sum + current.BalAmount, 0);
    }
    else if (sectionName === 'liabilitiescommon') {
      var me1 = this.assetsLiabilitesModelList;
      this.checkedSelectionTotal = this.assetsLiabilitesModelList.filter(
        function (e) {
          return e.IsCheckedForTotal == true && e.AccountMasterName == 'Liabilities';
        }
      ).reduce((sum, current) => sum + current.BalAmount, 0);
    }
    else if (sectionName === 'incomecommon') {
      var me1 = this.assetsLiabilitesModelList;
      this.checkedSelectionTotal = this.assetsLiabilitesModelList.filter(
        function (e) {
          return e.IsCheckedForTotal == true && e.AccountMasterName == 'Income';
        }
      ).reduce((sum, current) => sum + current.BalAmount, 0);
    }
  }
  async scroll(elementId) {
    this.isShowIncomeExpenditure=true;
    //el.scrollIntoView({behavior: 'smooth'});
    await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
    document.getElementById(elementId).scrollIntoView({behavior: 'smooth'});
}

}
