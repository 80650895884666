import { Component, OnInit } from '@angular/core';
import { OpeningBalanceModel } from 'src/app/models/ExpenseManager/Accounts/opening-balance-model';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { AccountApiModel } from 'src/app/models/ExpenseManager/Accounts/account-api-model';
import { AccountModel } from 'src/app/models/ExpenseManager/Accounts/account-model';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-opening-balance',
  templateUrl: './opening-balance.component.html',
  styleUrls: ['./opening-balance.component.css']
})
export class OpeningBalanceComponent implements OnInit {

  public openingBalanceModel: OpeningBalanceModel;

  model: any;
  searching = false;
  searchFailed = false;
  accountModel: AccountModel;
  constructor(private expenseManagerService: ExpenseManagerService,private titleService:Title) { this.titleService.setTitle("Opening Balance");}

  ngOnInit() {
    this.accountModel = new AccountModel();
    this.openingBalanceModel = new OpeningBalanceModel();
    this.openingBalanceModel.OpeningBalancelist = [];

    
    this.expenseManagerService.GetOpeningBalanceAllOrByID(0).subscribe(response =>{
      this.openingBalanceModel.OpeningBalancelist = response;
      this.openingBalanceModel.OpeningBalancelist .sort((a, b) => (a.Year > b.Year ? -1 : 1));

  }); 
  }
  searchOpBal = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetOpeningBalanceByAccountCode(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )



  formatterOpBal = (x: { AccountCode: string,Year:number }) => x.AccountCode + x.Year;

  selectedItemOpBal(item) {
    //this.clickedItem=item.item;
    console.log(item);
    //this.openingBalanceModel.OpeningBalanceAutoID = item.item.OpeningBalanceAutoID;
    this.openingBalanceModel.OpeningBalanceID = item.item.OpeningBalanceID;
    this.openingBalanceModel.AccountCode = item.item.AccountCode;
    this.openingBalanceModel.AccountID=item.item.AccountID;
    this.openingBalanceModel.Amount=item.item.Amount;
    this.openingBalanceModel.Comments=item.item.Comments;
    this.openingBalanceModel.Year=item.item.Year;
  }

/*Account */
search = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(300),
  distinctUntilChanged(),
  tap(() => this.searching = true),
  switchMap(term => term.length < 2 ? of([]) :
    (this.expenseManagerService.GetAccountByName(term).pipe(
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })))
  ),
  tap(() => this.searching = false)
)
formatter = (x: { AccountName: string }) => x.AccountName;

selectedItem(item) {
//this.clickedItem=item.item;
console.log(item);
var accountApiModel = new AccountApiModel();
this.openingBalanceModel.AccountID = item.item.AccountID;
this.openingBalanceModel.AccountCode = item.item.AccountCode;
this.accountModel.AccountName = item.item.AccountName;

this.accountModel.AccountMasterID = item.item.AccountMasterID;
this.accountModel.AccountMasterName = item.item.AccountMasterName;
this.accountModel.CashBankOther = item.item.CashBankOther;
this.accountModel.AccountStatus = item.item.AccountStatus;
this.accountModel.Receipt = item.item.Receipt;
this.accountModel.Active = item.item.Active;
this.accountModel.AccountAutoID = item.item.AccountAutoID;
}
  onSubmit() {
    var me = this.openingBalanceModel;
    var apimodel = new OpeningBalanceModel();
    apimodel.OpeningBalanceID = me.OpeningBalanceID;
    apimodel.AccountCode = me.AccountCode;
    apimodel.AccountID=me.AccountID;
    apimodel.Amount=me.Amount;
    apimodel.Year=me.Year;
    apimodel.Comments=me.Comments;
    //vendorApimodel.OpeningBalanceName = me.OpeningBalanceName;
    this.expenseManagerService.InsertOrUpdateOpeningBalance(apimodel).subscribe(
      response => {
        console.log(response);
        this.openingBalanceModel = new OpeningBalanceModel();
        this.expenseManagerService.GetOpeningBalanceAllOrByID(0).subscribe(response =>{
          this.openingBalanceModel.OpeningBalancelist = response;
      }); 

      }
    );
  }
  getPageName(): string {
    console.log("works");
    return 'OpeningBalance';
  }

}
