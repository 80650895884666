<nav class="navbar navbar-expand navbar-dark bg-dark static-top">

    <a class="navbar-brand mr-1" routerLink="/home">Admin Dashboard</a>

    <button class="btn btn-link btn-sm text-white order-1 order-sm-0" id="sidebarToggle" href="#">
    <i class="fas fa-bars"></i>
  </button>

    <!-- Navbar Search -->
    <form class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
        <div class="input-group">
            <input type="text" class="form-control" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2">
            <div class="input-group-append">
                <button class="btn btn-primary" type="button">
          <i class="fas fa-search"></i>
        </button>
            </div>
        </div>
    </form>

    <!-- Navbar -->
    <ul class="navbar-nav ml-auto ml-md-0">
        <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-bell fa-fw"></i>

                <span *ngIf="this.notificationModels.length > 0" class="badge badge-danger">{{notificationModels.length}}+</span>
            </a>
            <div *ngIf="this.notificationModels.length > 0" class="dropdown-menu dropdown-menu-right custom-notification-dropdown-menu" aria-labelledby="alertsDropdown">
                <!-- <a class="dropdown-item custom-notification-dropdown-item" *ngFor="let notfi of this.notificationModels" href="{{notfi.LinkText}}">
                   {{notfi.NotificationText}}
               </a> -->
                <div class="custom-notification-dropdown-item" *ngFor="let notfi of this.notificationModels">
                    <!--fas fa-fw fa-atom fa-user-ninja fab fa-phoenix-framework<a routerLink="{{notfi.LinkText}}">  {{notfi.NotificationText}}</a> -->
                                        <span class="fab fa-phoenix-squadron" style="
    color: #e0381d;
    font-size: 42px;
    display: floa;
    float: left;
    margin-right: 9px;
"> </span>
                    <a (click)='redirectToUrl(notfi.NotificationID)'>  {{notfi.NotificationText}}</a>
                </div>

                <!-- <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Something else here</a> -->
            </div>
        </li>
        <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-envelope fa-fw"></i>
                <span class="badge badge-danger">7</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="messagesDropdown">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Something else here</a>
            </div>
        </li>
        <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-user-circle fa-fw"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                <span class="dropdown-item" #loginName id="login-name"> Not logged in </span>
                <a class="dropdown-item" href="#">Settings</a>
                <a class="dropdown-item" href="#">Activity Log</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" (click)="logout($event)">Logout</a>
            </div>
        </li>
    </ul>
</nav>
<div id="wrapper">
    <ul class="sidebar navbar-nav">
        <!-- <li class="nav-item active">
      <a class="nav-link" href="index.html">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span>
      </a>
    </li> -->
        <li class="nav-item active">
            <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span>
            </a>
        </li>
        <li class="nav-item active">
            <a class="nav-link" routerLink="/testtable" routerLinkActive="active">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Test Component</span>
            </a>
        </li>
        <li class="nav-item dropdown">
            <!-- <a class="nav-link" routerLink="/expense" routerLinkActive="active">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Expense</span></a> -->
            <a class="nav-link dropdown-toggle" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-fw fa-thermometer-half"></i>
                <span>Sensor</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                <h6 class="dropdown-header">Admin Sensor:</h6>
                <a class="dropdown-item" routerLink="/sensordata/managesensor" routerLinkActive="active" (click)="navigation($event)">Sensor</a>
                <a class="dropdown-item" routerLink="/sensordata/managefeatureType" routerLinkActive="active" (click)="navigation($event)">Feature Type</a>
                <a class="dropdown-item" routerLink="/sensordata/manageLocation" routerLinkActive="active" (click)="navigation($event)">Location</a>
                <a class="dropdown-item" routerLink="/sensordata/managesublocation" routerLinkActive="active" (click)="navigation($event)">Sub Location</a>
                <a class="dropdown-item" routerLink="/sensordata/managesensormapping" routerLinkActive="active" (click)="navigation($event)">Sensor Mapping</a>
                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Temperature:</h6>
                <a class="dropdown-item" routerLink="/sensor/roomsensor" routerLinkActive="active" (click)="navigation($event)">Room Temp</a>
                <a class="dropdown-item" routerLink="/sensor/watertank" routerLinkActive="active" (click)="navigation($event)">Water Tank</a>
                <!-- <div class="dropdown-divider"></div>
          <h6 class="dropdown-header">Humidity:</h6>
          <a class="dropdown-item"  routerLink="/sensor/roomsensor" routerLinkActive="active" (click)="navigation($event)">Room Humidity</a> -->
            </div>
        </li>
        <li class="nav-item dropdown">
            <!-- <a class="nav-link" routerLink="/expense" routerLinkActive="active">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Expense</span></a> -->
            <a class="nav-link dropdown-toggle" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-fw fa-coins"></i>
                <span>Expenses</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                <h6 class="dropdown-header">Admin Expense:</h6>
                <a class="dropdown-item" routerLink="/expense/managevendor" routerLinkActive="active" (click)="navigation($event)">Vendor</a>
                <a class="dropdown-item" routerLink="/foodlogger/managebrand" routerLinkActive="active" (click)="navigation($event)">Brand</a>
                <a class="dropdown-item" routerLink="/expense/product" routerLinkActive="active" (click)="navigation($event)">Product</a>
                <a class="dropdown-item" routerLink="/expense/account" routerLinkActive="active" (click)="navigation($event)">Account</a>
                <a class="dropdown-item" routerLink="/expense/file" routerLinkActive="active" (click)="navigation($event)">File</a>
                <a class="dropdown-item" routerLink="/expense/openingbalance" routerLinkActive="active" (click)="navigation($event)">Opening Balance</a>
                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Transactions:</h6>
                <a class="dropdown-item" routerLink="/expense/transaction" routerLinkActive="active" (click)="navigation($event)">Add Transaction</a>
                <a class="dropdown-item" routerLink="/expense/productaccountdetails" routerLinkActive="active" (click)="navigation($event)">Product & A/c's Details</a>
            </div>
        </li>
        <li class="nav-item dropdown">
            <!-- <a class="nav-link" routerLink="/expense" routerLinkActive="active">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Expense</span></a> -->
            <a class="nav-link dropdown-toggle" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-fw fa-pencil-alt"></i>
                <span>HabitTracker</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                <h6 class="dropdown-header">Admin Habit:</h6>
                <a class="dropdown-item" routerLink="/habit/category" routerLinkActive="active" (click)="navigation($event)">Habit Category</a>
                <a class="dropdown-item" routerLink="/habit/type" routerLinkActive="active" (click)="navigation($event)">Type</a>
                <a class="dropdown-item" routerLink="/habit/habit" routerLinkActive="active" (click)="navigation($event)">Habit</a>
                <a class="dropdown-item" routerLink="/habit/mapping" routerLinkActive="active" (click)="navigation($event)">Mapping</a>
                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Log Habit:</h6>
                <a class="dropdown-item" routerLink="/habit/log" routerLinkActive="active" (click)="navigation($event)">Add Habit</a>
                <a class="dropdown-item" routerLink="/habit/report" routerLinkActive="active" (click)="navigation($event)">Report</a>
                <!-- <a class="dropdown-item" routerLink="/expense/productdetails"  routerLinkActive="active" (click)="navigation($event)">Product Details</a> -->
            </div>
        </li>
        <li class="nav-item dropdown">
            <!-- <a class="nav-link" routerLink="/expense" routerLinkActive="active">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Expense</span></a> -->
            <a class="nav-link dropdown-toggle" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-fw fa-coins"></i>
                <span>FoodLogger</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                <h6 class="dropdown-header">Admin FoodLogger:</h6>
                <a class="dropdown-item" routerLink="/foodlogger/managebrand" routerLinkActive="active" (click)="navigation($event)">Brand</a>
                <a class="dropdown-item" routerLink="/foodlogger/managemealtype" routerLinkActive="active" (click)="navigation($event)">Meal Type</a>
                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Create Food:</h6>
                <a class="dropdown-item" routerLink="/expense/product" routerLinkActive="active" (click)="navigation($event)">Create Product</a>
                <a class="dropdown-item" routerLink="/foodlogger/createfood" routerLinkActive="active" (click)="navigation($event)">Create Food</a>
               
                <a class="dropdown-item" routerLink="/foodlogger/addfoodimage" routerLinkActive="active" (click)="navigation($event)">Add Food Image</a>
                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Log Food:</h6>
                <a class="dropdown-item" routerLink="/foodlogger/logfood" routerLinkActive="active" (click)="navigation($event)">Log Food</a> 
                <a class="dropdown-item" routerLink="/foodlogger/foodbynutrition" routerLinkActive="active" (click)="navigation($event)">Food By Nutrition</a> 
               
                <!-- <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Transactions:</h6>
                <a class="dropdown-item" routerLink="/expense/transaction" routerLinkActive="active" (click)="navigation($event)">Add Transaction</a>
                <a class="dropdown-item" routerLink="/expense/productdetails" routerLinkActive="active" (click)="navigation($event)">Product Details</a> -->
            </div>
        </li>
        <li class="nav-item dropdown">
            <!-- <a class="nav-link" routerLink="/expense" routerLinkActive="active">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Expense</span></a> -->
            <a class="nav-link dropdown-toggle" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-fw fa-coins"></i>
                <span>Interview</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                <h6 class="dropdown-header">Admin Interview:</h6>
                <a class="dropdown-item" routerLink="/interview/managequestionanswer" routerLinkActive="active" (click)="navigation($event)">Questions</a>
                 <a class="dropdown-item" routerLink="/interview/managetopic" routerLinkActive="active" (click)="navigation($event)">Topic</a>
                <a class="dropdown-item" routerLink="/interview/managesubject" routerLinkActive="active" (click)="navigation($event)">Subject</a>
                <!--<a class="dropdown-item" routerLink="/foodlogger/logfood" routerLinkActive="active" (click)="navigation($event)">Log Food</a> 
                <a class="dropdown-item" routerLink="/foodlogger/addfoodimage" routerLinkActive="active" (click)="navigation($event)">Add Food Image</a> -->
                 <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Question Bank:</h6>
                <a class="dropdown-item" routerLink="/interview/questionbank" routerLinkActive="active" (click)="navigation($event)">Question Bank</a>
               <!-- <a class="dropdown-item" routerLink="/expense/productdetails" routerLinkActive="active" (click)="navigation($event)">Product Details</a> -->
            </div>
        </li>
        <li class="nav-item dropdown">
            <!-- <a class="nav-link" routerLink="/expense" routerLinkActive="active">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Expense</span></a> -->
            <a class="nav-link dropdown-toggle" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-fw fa-coins"></i>
                <span>Vital Info</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                <h6 class="dropdown-header">Admin Vital Info:</h6>
                <a class="dropdown-item" routerLink="/vitalinfo/measurement" routerLinkActive="active" (click)="navigation($event)">Measurement</a>
                <!-- <a class="dropdown-item" routerLink="/interview/managetopic" routerLinkActive="active" (click)="navigation($event)">Topic</a>
                <a class="dropdown-item" routerLink="/interview/managesubject" routerLinkActive="active" (click)="navigation($event)">Subject</a>
                <a class="dropdown-item" routerLink="/foodlogger/logfood" routerLinkActive="active" (click)="navigation($event)">Log Food</a> 
                <a class="dropdown-item" routerLink="/foodlogger/addfoodimage" routerLinkActive="active" (click)="navigation($event)">Add Food Image</a> -->
                <!-- <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Transactions:</h6>
                <a class="dropdown-item" routerLink="/expense/transaction" routerLinkActive="active" (click)="navigation($event)">Add Transaction</a>
                <a class="dropdown-item" routerLink="/expense/productdetails" routerLinkActive="active" (click)="navigation($event)">Product Details</a> -->
            </div>
        </li>
        <li class="nav-item dropdown">
            <!-- <a class="nav-link" routerLink="/expense" routerLinkActive="active">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Expense</span></a> -->
            <a class="nav-link dropdown-toggle" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-fw fa-coins"></i>
                <span>Item Tracker</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                <h6 class="dropdown-header">Admin Item Tracker:</h6>
                <a class="dropdown-item" routerLink="/itemtracker/managelocation" routerLinkActive="active" (click)="navigation($event)">Location</a>
                <a class="dropdown-item" routerLink="/itemtracker/managecurrentlocation" routerLinkActive="active" (click)="navigation($event)">Item Current Loc</a>
                <a class="dropdown-item" routerLink="/itemtracker/managecurrentlocationdrg" routerLinkActive="active" (click)="navigation($event)">Current Loc DragDrp</a>
                <a class="dropdown-item" routerLink="/itemtracker/stockmapping" routerLinkActive="active" (click)="navigation($event)">Item Stock Mapping</a>
                <a class="dropdown-item" routerLink="/itemtracker/itemlistbylocation" routerLinkActive="active" (click)="navigation($event)">Item list by location</a>
                <!-- <a class="dropdown-item" routerLink="/interview/managetopic" routerLinkActive="active" (click)="navigation($event)">Topic</a>
                <a class="dropdown-item" routerLink="/interview/managesubject" routerLinkActive="active" (click)="navigation($event)">Subject</a>
                <a class="dropdown-item" routerLink="/foodlogger/logfood" routerLinkActive="active" (click)="navigation($event)">Log Food</a> 
                <a class="dropdown-item" routerLink="/foodlogger/addfoodimage" routerLinkActive="active" (click)="navigation($event)">Add Food Image</a> -->
                <!-- <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Transactions:</h6>
                <a class="dropdown-item" routerLink="/expense/transaction" routerLinkActive="active" (click)="navigation($event)">Add Transaction</a>
                <a class="dropdown-item" routerLink="/expense/productdetails" routerLinkActive="active" (click)="navigation($event)">Product Details</a> -->
            </div>
        </li>
        <li class="nav-item dropdown">
            <!-- <a class="nav-link" routerLink="/expense" routerLinkActive="active">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Expense</span></a> -->
            <a class="nav-link dropdown-toggle" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-fw fa-coins"></i>
                <span>Fitbit</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                <h6 class="dropdown-header">FitBit Logs:</h6>
                <a class="dropdown-item" routerLink="/fitbit/sleep" routerLinkActive="active" (click)="navigation($event)">Sleep</a>
                <a class="dropdown-item" routerLink="/fitbit/activity" routerLinkActive="active" (click)="navigation($event)">Activity</a>
                <a class="dropdown-item" routerLink="/fitbit/body" routerLinkActive="active" (click)="navigation($event)">Body</a>
                <a class="dropdown-item" routerLink="/fitbit/water" routerLinkActive="active" (click)="navigation($event)">Water</a>
                <a class="dropdown-item" routerLink="/fitbit/fitbittoken" routerLinkActive="active" (click)="navigation($event)">Fitbit Token</a>
                <!-- <a class="dropdown-item" routerLink="/interview/managetopic" routerLinkActive="active" (click)="navigation($event)">Topic</a>
                <a class="dropdown-item" routerLink="/interview/managesubject" routerLinkActive="active" (click)="navigation($event)">Subject</a>
                <a class="dropdown-item" routerLink="/foodlogger/logfood" routerLinkActive="active" (click)="navigation($event)">Log Food</a> 
                <a class="dropdown-item" routerLink="/foodlogger/addfoodimage" routerLinkActive="active" (click)="navigation($event)">Add Food Image</a> -->
                <!-- <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Transactions:</h6>
                <a class="dropdown-item" routerLink="/expense/transaction" routerLinkActive="active" (click)="navigation($event)">Add Transaction</a>
                <a class="dropdown-item" routerLink="/expense/productdetails" routerLinkActive="active" (click)="navigation($event)">Product Details</a> -->
            </div>
        </li>
        <li class="nav-item dropdown">
            <!-- <a class="nav-link" routerLink="/expense" routerLinkActive="active">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Expense</span></a> -->
            <a class="nav-link dropdown-toggle" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-fw fa-coins"></i>
                <span>Smart Bag</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                <h6 class="dropdown-header">Manage Combo:</h6>
                <a class="dropdown-item" routerLink="/smartbag/mastercombo" routerLinkActive="active" (click)="navigation($event)">Master Combo</a>
                
                <!-- <a class="dropdown-item" routerLink="/interview/managetopic" routerLinkActive="active" (click)="navigation($event)">Topic</a>
                <a class="dropdown-item" routerLink="/interview/managesubject" routerLinkActive="active" (click)="navigation($event)">Subject</a>
                <a class="dropdown-item" routerLink="/foodlogger/logfood" routerLinkActive="active" (click)="navigation($event)">Log Food</a> 
                <a class="dropdown-item" routerLink="/foodlogger/addfoodimage" routerLinkActive="active" (click)="navigation($event)">Add Food Image</a> -->
                <!-- <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Transactions:</h6>
                <a class="dropdown-item" routerLink="/expense/transaction" routerLinkActive="active" (click)="navigation($event)">Add Transaction</a>
                <a class="dropdown-item" routerLink="/expense/productdetails" routerLinkActive="active" (click)="navigation($event)">Product Details</a> -->
            </div>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-fw fa-folder"></i>
                <span>Pages</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="pagesDropdown">
                <h6 class="dropdown-header">Login Screens:</h6>
                <a class="dropdown-item" routerLink="/login" routerLinkActive="active">Login</a>
                <a class="dropdown-item" routerLink="/register" routerLinkActive="active">Register</a>
                <a class="dropdown-item" routerLink="/forgetpassword" routerLinkActive="active">Forgot Password</a>
                <!-- <div class="dropdown-divider"></div>
        <h6 class="dropdown-header">Other Pages:</h6>
        <a class="dropdown-item"  routerLink="/errorme" routerLinkActive="active">404 Page</a>
        <a class="dropdown-item" href="blank.html">Blank Page</a>
        <a class="dropdown-item" routerLink="/animationex">Animation Ex</a> -->
            </div>
        </li>

        <!-- <li class="nav-item">
      <a class="nav-link" routerLink="/charts" routerLinkActive="active">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Charts</span></a>
    </li> -->

        <!-- <li class="nav-item">
        <a class="nav-link" routerLink="/modeldemo" routerLinkActive="active">
          <i class="fas fa-fw fa-table"></i>
          <span>modelDemo</span></a>
      </li> -->

    </ul>
    <div id="content-wrapper">
        <div class="container-fluid">
            <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">Dashboard </a>
                </li>
                <li class="breadcrumb-item active" #myDiv><span id="breadItem"></span></li>
            </ol>
            <!-- I am here before outlet -->
            <router-outlet (activate)="onActivate($event)"></router-outlet>
            <!-- <router-outlet></router-outlet> -->

            <div>
            </div>
            <!-- Sticky Footer -->
            <footer class="sticky-footer">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span>Copyright © myzingonline.com 2020</span>
                    </div>
                </div>
            </footer>
        </div>
        <!-- Scroll to Top Button-->
        <a class="scroll-to-top rounded" (click)="onEdit()">
            <i class="fas fa-angle-up"></i>
        </a>
        <a class="scroll-to-top-hidemenu rounded" >
            <i class="fas fa-angle-double-left"></i>
        </a>
    </div>
</div>
<ng-http-loader
[backdrop]="false"
[backgroundColor]="'#ff0000'"
[debounceDelay]="100"
[extraDuration]="300"
[minDuration]="300"
[opacity]=".6"
[spinner]="spinnerStyle.skWave"
[filteredUrlPatterns]="['\\d', '[a-zA-Z]', 'api/sensordata/getsensordata']"></ng-http-loader>

<!-- Different style are skChasingDots, skCubeGrid, skDoubleBounce, skRotatingPlane, skSpinnerPulse, skThreeBounce, skWanderingCubes and skWave -->