import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigLoaderService } from './services/config-loader.service';
import { HttpClientModule } from '@angular/common/http';
import * as $ from 'jquery';
import bootstrap from "bootstrap";
import {NgbModule,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { TestTableComponent } from './test-table/test-table.component';
import { LoginComponent } from './login/login.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { RegisterComponent } from './register/register.component';
import { PasswordPatternDirective } from './directives/password-pattern.directive';
import { MatchPasswordDirective } from './directives/match-password.directive';
import { ValidateUserNameDirective } from './directives/validate-user-name.directive';
import { ValidateEmailDirective } from './directives/validate-email.directive';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HighChartSampleComponent } from './high-chart-sample/high-chart-sample.component';
import { TemperatureModule } from './CustomModule/temperature/temperature.module';
import { AngularGridSampleComponent } from './angular-grid-sample/angular-grid-sample.component';
import { ExpenseManagerModule } from './CustomModule/expense-manager/expense-manager.module';
import { HabitTrackerModule } from './CustomModule/habit-tracker/habit-tracker.module';
import { FoodLoggerModule } from './CustomModule/food-logger/food-logger.module';
import { SensorWidgetComponent } from './widgets/sensor-widget/sensor-widget.component';
import { SensorDataModule } from './CustomModule/sensor-data/sensor-data.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InterviewModule } from './CustomModule/interview/interview.module';
import { VitalInformationModule } from './CustomModule/vital-information/vital-information.module';
import { ItemTrackerLocationModel } from './models/ItemTracker/item-tracker-location-model';
import { ItemTrackerModule } from './CustomModule/item-tracker/item-tracker.module';
import { QrcodeExComponent } from './qrcode-ex/qrcode-ex.component';
import { QRCodeModule } from 'angular2-qrcode';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AFitBitMasterComponent } from './CustomModule/fit-bit/afit-bit-master/afit-bit-master.component';
import { DatePipe } from '@angular/common';
import { FitBitModule } from './CustomModule/fit-bit/fit-bit.module';
import { ClipboardModule } from 'ngx-clipboard';
import { CanvasTracingComponent } from './canvas-tracing/canvas-tracing.component';
import { SmartBagModule } from './CustomModule/smart-bag/smart-bag.module';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        DashBoardComponent,
        TestTableComponent,
        LoginComponent,
        RegisterComponent,
        ForgetpasswordComponent,
        PasswordPatternDirective,
        MatchPasswordDirective,
        ValidateUserNameDirective,
        ValidateEmailDirective,
        ResetPasswordComponent,
        HighChartSampleComponent,
        AngularGridSampleComponent,
        SensorWidgetComponent,
        QrcodeExComponent,
        AFitBitMasterComponent,
        CanvasTracingComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        TemperatureModule,
        NgbModule,
        ExpenseManagerModule,
        HabitTrackerModule,
        FoodLoggerModule,
        SensorDataModule,
        InterviewModule,
        AngularEditorModule,
        VitalInformationModule,
        FitBitModule,
        ItemTrackerModule,
        QRCodeModule,
        ClipboardModule,
        SmartBagModule,
        NgHttpLoaderModule.forRoot(),
    ],
    providers: [{ provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [ConfigLoaderService],
            multi: true
        }, DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
export function appInitializerFactory(configsLoaderService: ConfigLoaderService) {
  return () => configsLoaderService.loadConfigs(); 
}
