import { Component, OnInit, ViewChild } from '@angular/core';
import { TransactionModel } from 'src/app/models/ExpenseManager/Transaction/transaction-model';
import { DropdownListItem } from 'src/app/models/ExpenseManager/dropdown-list-item';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { GridOptions } from 'ag-grid-community';
import { TransactionGridModel } from 'src/app/models/ExpenseManager/Transaction/transaction-grid-model';
import { TransactionApiModel } from 'src/app/models/ExpenseManager/Transaction/transaction-api-model';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { FoodLoggerService } from 'src/app/services/food-logger.service';
import { TransactionMasterModel } from 'src/app/models/ExpenseManager/Transaction/transaction-master-model';
import { AccountBalanceModel } from 'src/app/models/ExpenseManager/Accounts/account-balance-model';
import { AccountModel } from 'src/app/models/ExpenseManager/Accounts/account-model';
import { FileModel } from 'src/app/models/ExpenseManager/File/file-model';
import { ConfigLoaderService } from 'src/app/services/config-loader.service';
import {Title} from "@angular/platform-browser";
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {
  @ViewChild('agGrid', { static: true }) agGrid: GridOptions;
  public result = '';
  year = 2020;
  public gridApi;
  public defaultColDef;
  public columnDefs;
  public rowData;
  public context;
  public frameworkComponents;
  public getRowNodeId;
  public rowSelection;
  public paginationPageSize;
  public pagination;
  public pinnedBottomRowData;
  transactionModel: TransactionModel;
  transactionMasterModel: TransactionMasterModel;
  searching = false;
  searchFailed = false;
  ScratchPadAmount = 0;
  public openingBalAmountList: AccountBalanceModel[];
  public noOpeningBalAmountList: AccountBalanceModel[];
  public recentTransactions: TransactionModel[];
  public recentUnTouchedTransactions: TransactionModel[];
  public months: any[];
  public isRetainFilter: boolean;
  public CreditOrDebit = 'C';
  public isDebit = false;
  public currentCrDrDifference = 0;
  public isValue: number = 0;
  public isShowOpBalAccount = false;
  public isShowNoOpBalAccount = false;
  public isShowMonthFilter = false;
  public isShowEntriesEx = false;
  public recentAccounts: AccountModel[];
  //Files
  public isShowRelatedFiles = false;
  public fileModel: FileModel;
  public baseFileRef = "";
  public FileBasePathFold3 ="";
  public FileBasePathAsus6Z ="";
  public FileBasePathCommandCenter ="";
  public FileBasePathLenovoLaptop ="";
  isCopied=false;
  public isRequiredError = 'This field is required';
  public accCodeFilterAlphbets=["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p","q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
  public accOpenCodeFilterAlphbets=[];
  public accNonOpenCodeFilterAlphbets=[];
  @ViewChild('loginForm') tranForm: HTMLFormElement;
  constructor(private expenseManagerService: ExpenseManagerService, private foodLoggerService: FoodLoggerService, private configLoaderService: ConfigLoaderService
    ,private titleService:Title) {
    this.baseFileRef = this.configLoaderService.FileBasePath;
    this.FileBasePathFold3 = this.configLoaderService.FileBasePathFold3;
    this.FileBasePathAsus6Z = this.configLoaderService.FileBasePathAsus6Z;
    this.FileBasePathCommandCenter = this.configLoaderService.FileBasePathCommandCenter;
    this.FileBasePathLenovoLaptop = this.configLoaderService.FileBasePathLenovoLaptop;
    const date = moment();
    var dateMomemnt = moment({ y: 2017, M: 0, d: 4, h: 15, m: 10, s: 0, ms: 0 });
    var dateFromMomemnt = dateMomemnt.toDate();
    //Initializing header
    this.columnDefs = [
      {
        headerName: 'Id', field: 'Id', filter: true, width: 60,
        cellRenderer: function (params) {
          return '<span href="#">' + params.value + '</span>';
        }
      },
      { headerName: 'V ID', field: 'VoucherID', filter: true, width: 66 },

      { headerName: 'Prd ID', field: 'ProductID', filter: true, width: 78 },
      { headerName: 'Prd Name', field: 'ProductName', filter: true, width: 280 },
      {
        headerName: 'Dr', field: 'DrAmount', filter: true, width: 79,
        pinnedRowCellRenderer: function (params) {
          return '<span href="#">' + params.value + '</span>';
        },
        pinnedRowCellRendererParams: { style: { color: 'blue' } }
      },
      { headerName: 'Cr', field: 'CrAmount', filter: true, width: 79 },
      { headerName: 'A\c Code', field: 'AccCode', filter: true, width: 180 },
      { headerName: 'V Date', field: 'VoucherDate', filter: true, width: 140 },
      { headerName: 'PMode', field: 'PayMode', filter: true, width: 80 },
      { headerName: 'VType', field: 'VoucherType', filter: true, width: 75 },
      { headerName: 'Qty', field: 'Quantity', filter: true, width: 63 },
      { headerName: 'Measurement', field: 'Measurement', filter: true, width: 115 },
      { headerName: 'M.Id', field: 'MeasurementId', filter: true, width: 75 },

      { headerName: 'TranID', field: 'TranID', filter: true, width: 90 },
      { headerName: 'Narration', field: 'Narration', filter: true, width: 115 },
      ,
    ];
    this.rowSelection = 'single';
    // this.pagination = true;
    // this.paginationPageSize = 30;
    this.getRowNodeId = function (data) {
      return data.Id;
    };
    this.context = { componentParent: this };
    this.titleService.setTitle("Transaction");
this.accOpenCodeFilterAlphbets=[];
this.accNonOpenCodeFilterAlphbets=[];
  }

  ngOnInit() {
    this.setFormData();

    this.expenseManagerService.GetRecentTransactions(this.CreditOrDebit).subscribe(response => {
      this.recentUnTouchedTransactions = response;
      this.recentTransactions = response;
    });
    var currentDate = new Date();
    this.GetAllAccountCurrentBalanceByYear(currentDate);
    this.GetAllAccountCurrentBalanceByYearNoOpeningBalance(currentDate);
    this.expenseManagerService.GetRecentAccounts().subscribe(response => {
      this.recentAccounts = response;

    });
    this.fileModel = new FileModel();
    this.fileModel.Filelist = [];
  }
  GetAllAccountCurrentBalanceByYear(currentDate) {
    this.accOpenCodeFilterAlphbets=[];
    this.expenseManagerService.GetAllAccountCurrentBalanceByYear(currentDate.getFullYear(), true).subscribe(response => {
      this.openingBalAmountList = response;
      //Sorting in asc order by acc code
      this.openingBalAmountList = this.openingBalAmountList.sort((t1, t2) => {
        const name1 = t1.AccountCode.toLowerCase();
        const name2 = t2.AccountCode.toLowerCase();
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      });
      for(var item of this.accCodeFilterAlphbets){
        for(var opObject of this.openingBalAmountList){
          if(item.toLocaleLowerCase() === opObject.AccountCode.charAt(0).toLocaleLowerCase()){
            this.accOpenCodeFilterAlphbets.push(item);
            break;
          }
        }
      }
    });
    
  }
  GetAllAccountCurrentBalanceByYearNoOpeningBalance(currentDate) {
   this.accNonOpenCodeFilterAlphbets=[];
    this.expenseManagerService.GetAllAccountCurrentBalanceByYear(currentDate.getFullYear(), false).subscribe(response => {
      this.noOpeningBalAmountList = response;
        //Sorting in asc order by acc code
        this.noOpeningBalAmountList = this.noOpeningBalAmountList.sort((t1, t2) => {
          const name1 = t1.AccountCode.toLowerCase();
          const name2 = t2.AccountCode.toLowerCase();
          if (name1 > name2) { return 1; }
          if (name1 < name2) { return -1; }
          return 0;
        });
        for(var item of this.accCodeFilterAlphbets){
          for(var opObject of this.noOpeningBalAmountList){
            if(item.toLocaleLowerCase() === opObject.AccountCode.charAt(0).toLocaleLowerCase()){
              this.accNonOpenCodeFilterAlphbets.push(item);
              break;
            }
          }
        }
    });
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetProductByName1(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { ProductName: string }) => x.ProductName;

  selectedItemProduct(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.transactionModel.ProductID = item.item.ProductID;
    this.transactionModel.ProductName = item.item.ProductName;
    this.transactionModel.Amount = item.item.Amount;
    this.transactionModel.Quantity = 1;
    this.transactionModel.Measurement = 1;
    if (this.transactionModel.ProductName == 'No product' || this.transactionModel.ProductName == 'Pending product Fill it') {
      this.transactionModel.MeasurementId = 6;
    }

  }
  onRemoveSelected() {
    var selectedData = this.agGrid.api.getSelectedRows();
    //if(this.agGrid.api.getDisplayedRowCount() > 1)
    var res = this.agGrid.api.updateRowData({ remove: selectedData });
    this.transactionModel.isEditMode = false;
    //rintResult(res);
  }
  count = 0;
  //Adding item to grid
  onSubmit() {
    var isValid = true;
     
    if(!this.transactionMasterModel.VendorID || this.transactionMasterModel.VendorID<=0){
      this.tranForm.form.controls['transactionMasterModel.VendorID'].setErrors({ 'Required': this.isRequiredError });
      isValid = false;
    }
    if(!this.transactionModel.AccountCode || this.transactionModel.AccountCode.trim().length<=0){
      this.tranForm.form.controls['transactionModel.AccountCode'].setErrors({ 'Required': this.isRequiredError });
      isValid = false;
    }

    if (!isValid) {
      return 0;
    }
    var me = '';
    var newDate = new Date(this.transactionModel.VoucherDateDisplay.year, this.transactionModel.VoucherDateDisplay.month - 1
      , this.transactionModel.VoucherDateDisplay.day,
      this.transactionModel.VoucherTimeDisplay.hour, this.transactionModel.VoucherTimeDisplay.minute, 0, 0);
    this.transactionModel.VoucherDate = newDate;

    var expenseGrid = new TransactionGridModel();
    expenseGrid.AccCode = this.transactionModel.AccountCode;
    expenseGrid.CrAmount = this.transactionModel.TranType === 'C' ? this.transactionModel.Amount : 0;
    expenseGrid.DrAmount = this.transactionModel.TranType === 'D' ? this.transactionModel.Amount : 0;
    expenseGrid.PayMode = this.transactionModel.PayMode;
    expenseGrid.ProductID = this.transactionModel.ProductID;
    expenseGrid.ProductName = this.transactionModel.ProductName;

    expenseGrid.Quantity = this.transactionModel.Quantity;
    expenseGrid.Measurement = this.transactionModel.Measurement;
    expenseGrid.MeasurementId = this.transactionModel.MeasurementId;


    expenseGrid.TranID = this.transactionModel.TTransID;
    expenseGrid.VoucherDate = this.transactionModel.VoucherDate;
    expenseGrid.VoucherType = this.transactionModel.VoucherType;
    // expenseGrid.VoucherID = this.transactionModel.VoucherID;
    expenseGrid.VoucherID = this.transactionMasterModel.VoucherID;
    expenseGrid.TranType = this.transactionModel.TranType;
    expenseGrid.Narration = this.transactionModel.Narration;
    expenseGrid.RowID = Math.floor(Math.random() * 6) + 1;
    //expenseGrid.Id = ++this.count;
    //this.transactionModel.ExpenseGridModel.push()
    if (this.transactionModel.isEditMode === true) {
      expenseGrid.Id = this.transactionModel.Id;
      var rowNode = this.agGrid.api.getRowNode(String(this.transactionModel.Id));
      rowNode.setData(expenseGrid);
      //this.agGrid.api.setPinnedBottomRowData(rowNode);
      this.transactionModel.isEditMode = false;
    }
    else {
      expenseGrid.RowID = Math.floor(Math.random() * 6) + 1;
      expenseGrid.Id = ++this.count;
      var res = this.agGrid.api.updateRowData({ add: [expenseGrid] });
    }

    // Initializing gridAmount Pinned bottom row

    this.pinnedBottomRowData = this.createData(expenseGrid.DrAmount, 1, 'Bottom');

    this.transactionModel.TTransID = null;
    this.transactionModel.AccountCodeAutoComplete = null;
    this.transactionModel.Amount = 0;
    this.transactionModel.ProductIDAutoComplete = null;
  }
  createData(drAmount, count, prefix) {
    //Getting data for grid so that total can be done.
    var rowData = [];
    let drSum: number = 0;
    let crSum: number = 0;

    this.agGrid.api.forEachNode(function (node) {
      rowData.push(node.data);
      drSum += Number.isNaN(Number(node.data.DrAmount)) ? 0 : Number(node.data.DrAmount);
      crSum += Number.isNaN(Number(node.data.CrAmount)) ? 0 : Number(node.data.CrAmount);
    });
    this.currentCrDrDifference = drSum - crSum;
    var result = [];
    var expenseGrid = new TransactionGridModel();
    expenseGrid.AccCode = '' + this.currentCrDrDifference;
    expenseGrid.CrAmount = crSum;
    expenseGrid.DrAmount = drSum;
    expenseGrid.PayMode = 'A';
    expenseGrid.ProductID = 2;
    result.push(expenseGrid);
    return result;
  }
  onSelectionChanged(event: any) {
    var selectedRows = this.agGrid.api.getSelectedRows();

    this.transactionModel.TTransID = selectedRows[0].TranID;
    this.transactionModel.AccountCode = selectedRows[0].AccCode;
    this.transactionModel.TranType = selectedRows[0].TranType;
    this.transactionModel.Amount = selectedRows[0].TranType == "C" ? selectedRows[0].CrAmount : selectedRows[0].DrAmount;
    this.transactionModel.PayMode = selectedRows[0].PayMode;
    this.transactionModel.ProductID = selectedRows[0].ProductID;
    this.transactionModel.VoucherType = selectedRows[0].VoucherType;
    this.transactionModel.VoucherID = selectedRows[0].VoucherID;
    this.transactionModel.ProductName = selectedRows[0].ProductName;
    this.transactionModel.Id = selectedRows[0].Id;

    this.transactionModel.Quantity = selectedRows[0].Quantity;
    this.transactionModel.Measurement = selectedRows[0].Measurement;
    this.transactionModel.MeasurementId = selectedRows[0].MeasurementId;
    this.transactionModel.Narration = selectedRows[0].Narration;
    var selectedDate = new Date(selectedRows[0].VoucherDate);
    this.transactionModel.VoucherDateDisplay = { year: selectedDate.getFullYear(), month: selectedDate.getMonth() + 1, day: selectedDate.getDate() };
    this.transactionModel.VoucherTimeDisplay = { hour: selectedDate.getHours(), minute: selectedDate.getMinutes() };
    this.transactionModel.isEditMode = true;
    //Math.floor(Math.random() * 6) + 1 ;  =  selectedRows[0].RowID 		;
    //this.transactionModel.TTransID;  =  selectedRows[0].TranID 	;
    //   document.querySelector('#selectedRows').innerHTML =
    //     selectedRows.length === 1 ? selectedRows[0].athlete : '';

  }
  onRowSelected(event) {
    console.log("row " + event.node.data + " selected = " + event.node.selected);
  }

  searchAccount = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetAccountByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatterAccount = (x: { AccountName: string, AccountCode: string }) => x.AccountName + ' - ' + x.AccountCode;
  selectedItemAccount(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.transactionModel.AccountCode = item.item.AccountCode;
    this.transactionModel.AccountName = item.item.AccountName;
    this.tranForm.form.controls['transactionModel.AccountCode'].setErrors(null);
  }

  //Save all data
  onSaveData() {
    var localThis = this;
    let drAmount: number = 0;
    let crAmount: number = 0;
    var expenseApiModels = [];
    var voucherId;
    this.agGrid.api.forEachNode(function (node) {

      var apiModel = new TransactionApiModel();
      drAmount = Number.isNaN(Number(node.data.DrAmount)) ? 0 : Number(node.data.DrAmount);
      crAmount = Number.isNaN(Number(node.data.CrAmount)) ? 0 : Number(node.data.CrAmount);

      var amount = node.data.TranType == "C" ? crAmount : drAmount;
      apiModel.TTransID = Number.isNaN(Number(node.data.TranID)) ? 0 : Number(node.data.TranID);
      apiModel.VoucherDateDisplay = node.data.VoucherDate;
      apiModel.VoucherType = node.data.VoucherType;
      apiModel.VoucherID = node.data.VoucherID;
      voucherId = node.data.voucherID;
      apiModel.AccountCode = node.data.AccCode;
      apiModel.Amount = amount;
      apiModel.PayMode = node.data.PayMode;
      apiModel.TranType = node.data.TranType;

      apiModel.Quantity = node.data.Quantity;
      apiModel.Measurement = node.data.Measurement;
      apiModel.MeasurementId = node.data.MeasurementId;
      apiModel.ProductID = Number.isNaN(Number(node.data.ProductID)) ? 0 : Number(node.data.ProductID);

      apiModel.Narration = node.data.Narration;

      expenseApiModels.push(apiModel);

    });
    var newDate = new Date(this.transactionModel.VoucherDateDisplay.year, this.transactionModel.VoucherDateDisplay.month - 1
      , this.transactionModel.VoucherDateDisplay.day,
      this.transactionModel.VoucherTimeDisplay.hour, this.transactionModel.VoucherTimeDisplay.minute, 0, 0);
    this.transactionModel.VoucherDate = newDate;

    this.transactionMasterModel.ExpenseTTransModel = expenseApiModels;
    this.transactionMasterModel.VoucherDateDisplay = newDate;
    this.expenseManagerService.saveTransaction(this.transactionMasterModel).subscribe(response => {
      console.log(response);
      this.result = response.VoucherID +' : '+formatDate(Date.now(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');;
      this.setFormData();
      var currentDate = new Date();
      this.GetAllAccountCurrentBalanceByYear(currentDate);
      this.GetAllAccountCurrentBalanceByYearNoOpeningBalance(currentDate);
      this.expenseManagerService.GetRecentAccounts().subscribe(response => {
        this.recentAccounts = response;
      });
    });
  }

  //Autocomplete Voucher
  searchVoucher = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetVoucherIdsByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatterVoucher = (x: { TransactionDetail: string }) => x.TransactionDetail;

  selectedItemVoucher(item) {
    //this.clickedItem=item.item;
    console.log(item);

    this.transactionModel.VoucherID = item.item.VoucherID;
    this.getVoucherDetailsByVoucherID(this.transactionModel.VoucherID);

  }
  getVoucherDetailsByVoucherID(voucherID) {
    this.setFormData();
    console.log(voucherID);
    this.expenseManagerService.GetVoucherDetailsById(voucherID).subscribe(voucherDetailsResponse => {
      this.transactionMasterModel = voucherDetailsResponse;
      var voucherDetails = voucherDetailsResponse.ExpenseTTransModel;
      var created = new Date(voucherDetailsResponse.Created);
      this.transactionModel.VoucherDateDisplay = { year: created.getFullYear(), month: created.getMonth() + 1, day: created.getDate() };
      this.transactionModel.VoucherTimeDisplay = { hour: created.getHours(), minute: created.getMinutes() };
      var newDate = new Date(this.transactionModel.VoucherDateDisplay.year, this.transactionModel.VoucherDateDisplay.month - 1
        , this.transactionModel.VoucherDateDisplay.day,
        this.transactionModel.VoucherTimeDisplay.hour, this.transactionModel.VoucherTimeDisplay.minute, 0, 0);

      for (var i = 0; i < voucherDetails.length; i++) {

        // this.transactionModel.VoucherDate = newDate;

        var expenseGrid = new TransactionGridModel();
        expenseGrid.AccCode = voucherDetails[i].AccountCode;
        expenseGrid.CrAmount = voucherDetails[i].TranType === 'C' ? voucherDetails[i].Amount : 0;
        expenseGrid.DrAmount = voucherDetails[i].TranType === 'D' ? voucherDetails[i].Amount : 0;
        expenseGrid.PayMode = voucherDetails[i].PayMode;
        expenseGrid.ProductID = voucherDetails[i].ProductID;
        expenseGrid.ProductName = voucherDetails[i].ProductName;

        expenseGrid.TranID = voucherDetails[i].TTransID;
        expenseGrid.VoucherDate = new Date(voucherDetails[0].VoucherDate);
        expenseGrid.VoucherType = voucherDetails[i].VoucherType;
        expenseGrid.VoucherID = voucherDetails[i].VoucherID;
        expenseGrid.TranType = voucherDetails[i].TranType;

        expenseGrid.Quantity = voucherDetails[i].Quantity;
        expenseGrid.Measurement = voucherDetails[i].Measurement;
        expenseGrid.MeasurementId = voucherDetails[i].MeasurementId;
        expenseGrid.Narration = voucherDetails[i].Narration;
        //expenseGrid.RowID =Math.floor(Math.random() * 6) + 1 ;
        expenseGrid.Id = ++this.count;
        var res = this.agGrid.api.updateRowData({ add: [expenseGrid] });



      }

      // Initializing gridAmount Pinned bottom row

      this.pinnedBottomRowData = this.createData(expenseGrid.DrAmount, 1, 'Bottom');
      console.log(voucherDetails);
    });
  }
  setFormData() {
    var me = { monthName: "hello", selected: false, monthNo: 1 };
    this.months = [];
    this.months.push({ monthName: 'Jan', selected: false, monthNo: 1 });
    this.months.push({ monthName: 'Feb', selected: false, monthNo: 2 });
    this.months.push({ monthName: 'Mar', selected: false, monthNo: 3 });
    this.months.push({ monthName: 'Apr', selected: false, monthNo: 4 });
    this.months.push({ monthName: 'May', selected: false, monthNo: 5 });
    this.months.push({ monthName: 'Jun', selected: false, monthNo: 6 });
    this.months.push({ monthName: 'Jul', selected: false, monthNo: 7 });
    this.months.push({ monthName: 'Aug', selected: false, monthNo: 8 });
    this.months.push({ monthName: 'Sep', selected: false, monthNo: 9 });
    this.months.push({ monthName: 'Oct', selected: false, monthNo: 10 });
    this.months.push({ monthName: 'Nov', selected: false, monthNo: 11 });
    this.months.push({ monthName: 'Dec', selected: false, monthNo: 12 });

    this.rowData = [];
    this.transactionModel = new TransactionModel();
    this.transactionMasterModel = new TransactionMasterModel();
    this.transactionMasterModel.ExpenseTTransModel = [];
    //this.transactionModel.
    this.transactionModel.VoucherTypeList = [];
    this.transactionModel.VoucherTypeList.push(new DropdownListItem("Receipt", "R"));
    this.transactionModel.VoucherTypeList.push(new DropdownListItem("Payment", "P"));
    this.transactionModel.VoucherType = "P";
    //  this.transactionModel.VoucherDateDisplay = { year: 2020, month: 3, day: 25 };
    //  this.transactionModel.VoucherTimeDisplay = { hour: 13, minute: 30 };
    var currentDate = new Date();
    this.transactionModel.VoucherDateDisplay = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
    this.transactionModel.VoucherTimeDisplay = { hour: currentDate.getHours(), minute: currentDate.getMinutes() };

    this.transactionModel.TranTypeList = [];
    this.transactionModel.TranTypeList.push(new DropdownListItem("Dr", "D"));
    this.transactionModel.TranTypeList.push(new DropdownListItem("Cr", "C"));
    this.transactionModel.TranType = "D";

    //Paymode

    this.transactionModel.PaymentTypeList = [];
    this.transactionModel.PaymentTypeList.push(new DropdownListItem("Bank", "Q"));
    this.transactionModel.PaymentTypeList.push(new DropdownListItem("Cash", "C"));
    this.transactionModel.PayMode = "Q";

    this.transactionModel.TransactionGridModel = [];

    this.transactionModel.MeasurementModel = [];
    this.foodLoggerService.GetAllMeasurement().subscribe(response => {
      this.transactionModel.MeasurementModel = response;
    });


  }
  executeTemplate(choice) {
    var newDate = new Date(this.transactionModel.VoucherDateDisplay.year, this.transactionModel.VoucherDateDisplay.month - 1
      , this.transactionModel.VoucherDateDisplay.day,
      this.transactionModel.VoucherTimeDisplay.hour, this.transactionModel.VoucherTimeDisplay.minute, 0, 0);
    this.transactionModel.VoucherDate = newDate;
    this.transactionModel.VoucherDate = newDate;


    if (choice == 'purchase') {
      this.setPurchaseTemplate();
    }
    else if (choice == 'purchaseworthy') {
      this.setPurchaseWithDiscountTemplate();
    }
    else if (choice == 'rentworthy') {
      this.setRentTemplate();
    }
  }
  setPurchaseTemplate() {
    var expenseGrid = new TransactionGridModel();
    expenseGrid.AccCode = "PA1";
    expenseGrid.CrAmount = 0;
    expenseGrid.DrAmount = this.transactionModel.Amount;
    expenseGrid.PayMode = "B";
    expenseGrid.ProductID = 1;
    expenseGrid.ProductName = "";

    expenseGrid.TranID = 0;
    expenseGrid.VoucherDate = this.transactionModel.VoucherDate;
    expenseGrid.VoucherType = "P";
    expenseGrid.VoucherID = 0;
    expenseGrid.TranType = "D";
    //expenseGrid.RowID =Math.floor(Math.random() * 6) + 1 ;
    expenseGrid.Id = ++this.count;
    var res = this.agGrid.api.updateRowData({ add: [expenseGrid] });

    var expenseGrid = new TransactionGridModel();
    expenseGrid.AccCode = "HDFC";
    expenseGrid.CrAmount = this.transactionModel.Amount;
    expenseGrid.DrAmount = 0;
    expenseGrid.PayMode = "B";
    expenseGrid.ProductID = 1;
    expenseGrid.ProductName = "";

    expenseGrid.TranID = 0;
    expenseGrid.VoucherDate = this.transactionModel.VoucherDate;
    expenseGrid.VoucherType = "P";
    expenseGrid.VoucherID = 0;
    expenseGrid.TranType = "C";
    //expenseGrid.RowID =Math.floor(Math.random() * 6) + 1 ;
    expenseGrid.Id = ++this.count;
    var res = this.agGrid.api.updateRowData({ add: [expenseGrid] });

  }
  setPurchaseWithDiscountTemplate() {
    var expenseGrid = new TransactionGridModel();
    expenseGrid.AccCode = "PA1";
    expenseGrid.CrAmount = 0;
    expenseGrid.DrAmount = this.transactionModel.Amount;
    expenseGrid.PayMode = "B";
    expenseGrid.ProductID = 1;
    expenseGrid.ProductName = "";

    expenseGrid.TranID = 0;
    expenseGrid.VoucherDate = this.transactionModel.VoucherDate;
    expenseGrid.VoucherType = "P";
    expenseGrid.VoucherID = 0;
    expenseGrid.TranType = "D";
    //expenseGrid.RowID =Math.floor(Math.random() * 6) + 1 ;
    expenseGrid.Id = ++this.count;
    var res = this.agGrid.api.updateRowData({ add: [expenseGrid] });

    var expenseGrid = new TransactionGridModel();
    expenseGrid.AccCode = "HDFC";
    expenseGrid.CrAmount = this.transactionModel.Amount;
    expenseGrid.DrAmount = 0;
    expenseGrid.PayMode = "B";
    expenseGrid.ProductID = 1;
    expenseGrid.ProductName = "";

    expenseGrid.TranID = 0;
    expenseGrid.VoucherDate = this.transactionModel.VoucherDate;
    expenseGrid.VoucherType = "P";
    expenseGrid.VoucherID = 0;
    expenseGrid.TranType = "C";
    //expenseGrid.RowID =Math.floor(Math.random() * 6) + 1 ;
    expenseGrid.Id = ++this.count;
    var res = this.agGrid.api.updateRowData({ add: [expenseGrid] });

    var expenseGrid = new TransactionGridModel();
    expenseGrid.AccCode = "PW1";
    expenseGrid.CrAmount = this.transactionModel.Amount;
    expenseGrid.DrAmount = 0;
    expenseGrid.PayMode = "B";
    expenseGrid.ProductID = 2;
    expenseGrid.ProductName = "Prime";

    expenseGrid.TranID = 0;
    expenseGrid.VoucherDate = this.transactionModel.VoucherDate;
    expenseGrid.VoucherType = "P";
    expenseGrid.VoucherID = 0;
    expenseGrid.TranType = "C";
    //expenseGrid.RowID =Math.floor(Math.random() * 6) + 1 ;
    expenseGrid.Id = ++this.count;
    var res = this.agGrid.api.updateRowData({ add: [expenseGrid] });

  }
  setRentTemplate() {
    var expenseGrid = new TransactionGridModel();
    expenseGrid.AccCode = "EXP1";
    expenseGrid.CrAmount = 0;
    expenseGrid.DrAmount = this.transactionModel.Amount;
    expenseGrid.PayMode = "B";
    expenseGrid.ProductID = 1;
    expenseGrid.ProductName = "";

    expenseGrid.TranID = 0;
    expenseGrid.VoucherDate = this.transactionModel.VoucherDate;
    expenseGrid.VoucherType = "R";
    expenseGrid.VoucherID = 0;
    expenseGrid.TranType = "D";
    //expenseGrid.RowID =Math.floor(Math.random() * 6) + 1 ;
    expenseGrid.Id = ++this.count;
    var res = this.agGrid.api.updateRowData({ add: [expenseGrid] });

    var expenseGrid = new TransactionGridModel();
    expenseGrid.AccCode = "PW1";
    expenseGrid.CrAmount = this.transactionModel.Amount;
    expenseGrid.DrAmount = 0;
    expenseGrid.PayMode = "B";
    expenseGrid.ProductID = 1;
    expenseGrid.ProductName = "";

    expenseGrid.TranID = 0;
    expenseGrid.VoucherDate = this.transactionModel.VoucherDate;
    expenseGrid.VoucherType = "R";
    expenseGrid.VoucherID = 0;
    expenseGrid.TranType = "C";
    //expenseGrid.RowID =Math.floor(Math.random() * 6) + 1 ;
    expenseGrid.Id = ++this.count;
    var res = this.agGrid.api.updateRowData({ add: [expenseGrid] });

  }
  scratchPadClick(choice) {
    if (choice === 'plus') {
      this.transactionModel.Amount = Number(this.transactionModel.Amount) + Number(this.ScratchPadAmount);
    }
    else if (choice === 'minus') {
      this.transactionModel.Amount = Number(this.transactionModel.Amount) - Number(this.ScratchPadAmount);

    }
    else if (choice === 'multiply') {
      this.transactionModel.Amount = Number(this.transactionModel.Amount) * Number(this.ScratchPadAmount);
    }
    else if (choice === 'divide') {
      this.transactionModel.Amount = Number(this.transactionModel.Amount) / Number(this.ScratchPadAmount);
    }
    else if (choice === 'addDifferenceCrDr') {
      this.transactionModel.Amount = this.currentCrDrDifference;
      this.transactionModel.TranType=this.transactionModel.TranType ==='D'?'C':'D';
      this.scrollId('Transaction-Section');
    }
  }

  /* Vendor Auto Complete */
  searchVendor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetVendorByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )



  formatterVendor = (x: { VendorName: string }) => x.VendorName;

  selectedItemVendor(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.transactionMasterModel.VendorID = item.item.VendorID;
    this.transactionMasterModel.VendorName = item.item.VendorName;
    this.tranForm.form.controls['transactionMasterModel.VendorID'].setErrors(null);
  }

  /* Filter transaction by acccode*/
  filterTransaction(filterValue, choice) {
    if (!this.isRetainFilter) {
      this.recentTransactions = this.recentUnTouchedTransactions;
    }
    var models;
    if (choice === 'acccode') {
      models = this.recentTransactions.filter(
        function (e) {

          return e.AccountCode == filterValue;
        }
      );
      //Fetching related Files
      if (this.isShowRelatedFiles) {
        this.expenseManagerService.GetFilesByName(filterValue).subscribe(response => {
          this.fileModel = new FileModel();
          this.fileModel.Filelist = [];
          this.fileModel.Filelist = response;
        });
      }
    }
    else if (choice === 'month') {
      models = this.recentTransactions.filter(
        function (e) {
          return new Date(e.VoucherDate).getMonth() + 1 == filterValue;
        }
      );
      for (var item of this.months) {
        if (item.monthNo == filterValue) {
          item.selected = true;
        }
        else {
          item.selected = false;
        }
      }
    }

    this.recentTransactions = models;
  }
  changeDebit() {
    if (this.isDebit) {
      this.CreditOrDebit = 'D'
    }
    else {
      this.CreditOrDebit = 'C';
    }
    this.expenseManagerService.GetRecentTransactions(this.CreditOrDebit).subscribe(response => {
      this.recentUnTouchedTransactions = response;
      this.recentTransactions = response;
    });
  }
  showHideOpBalAccount() {
    this.isShowOpBalAccount = !this.isShowOpBalAccount;
  }
  showHidenoOpBalAccount() {
    this.isShowNoOpBalAccount = !this.isShowNoOpBalAccount;
  }
  showHideMonthFilter() {
    this.isShowMonthFilter = !this.isShowMonthFilter;
  }
  showHideEntriesEx() {
    this.isShowEntriesEx = !this.isShowEntriesEx;
  }

  toggle(num) {
    this.isValue = num;
  }
  selectedRecentAccounts(accCode, accName) {
    this.transactionModel.AccountCode = accCode;
    this.transactionModel.AccountName = accName;
    this.tranForm.form.controls['transactionModel.AccountCode'].setErrors(null);
  }
  onModelChange(event) {
    var newDate = new Date(this.transactionModel.VoucherDateDisplay.year, this.transactionModel.VoucherDateDisplay.month - 1
      , this.transactionModel.VoucherDateDisplay.day,
      1, 1, 0, 0);
    // var newDate = moment({ y: 2017, M: 0, d: 4, h: 15, m: 10, s: 0, ms: 0 });
    //this.transactionModel.VoucherDate = newDate;
    this.GetAllAccountCurrentBalanceByYear(newDate);
    this.GetAllAccountCurrentBalanceByYearNoOpeningBalance(newDate);
    this.expenseManagerService.GetRecentAccounts().subscribe(response => {
      this.recentAccounts = response;

    });
  }
  scroll(elementId) {
    //el.scrollIntoView({behavior: 'smooth'});
    document.getElementById(elementId).scrollIntoView({behavior: 'smooth'});
}
scrollId(elementId:string) {
 //lementId.scrollIntoView({behavior: 'smooth'});
  document.getElementById(elementId).scrollIntoView({behavior: 'smooth'});
}
scrollTohash(elementId) {
  elementId.scrollIntoView({behavior: 'smooth'});
   //document.getElementById(elementId).scrollIntoView({behavior: 'smooth'});
 }

}
